import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get, find, keyBy } from 'lodash';
import { Field } from 'formik';
import { mappedVoucherTypes, mappedOptionsFromArray, mappedBillingPartyBranches, } from '../../../utils/mapping_utils';
import { getMomentTime, DATE_FORMAT_TIMESTAMP } from '../../../../utils/date_utils';
import { mappedOnlyConsignorsConsignees, mappedTransporters } from '../../../../utils/form_utils';
import { FxSelectField, FxSelectSingleDate, FxTextAreaFormikStacked, FxTextFieldStackedLabel, } from '../../../../shared';
import BillNumberComponent from '../../bill_number/bill_number_component';
import { getCurrencyOptionsFromCurrencyCodes } from 'utils/internationalization_utils';
import { mappedOnAccountPaymentOptions } from './account_form_utils';
const BasicInformationComponent = (props) => {
    const { formikProps, editId, customers, divisions, loggedInUser, transporters, handleFilterChange, currencyCodes, isCurrencyConversionApplicable, currencyCode, fetchOnAccountPayments, onAccountListing, vehicleList, accountConfigData, } = props;
    const { values } = formikProps;
    const { paymentNature, creditAccountId } = values;
    const isHSBalancePayment = values.paymentNature == 'HIRE_SLIP';
    const isPaymentReceipt = values.paymentNature == 'PAYMENT_RECEIPT';
    const isCreditBranchLedger = find(accountConfigData, { propertyType: 'CLIENT_BRANCH_LEDGER' });
    const showCreditAccountBranch = isCreditBranchLedger && !isHSBalancePayment;
    const [branchesForCreditAccount, setBranchesForCreditAccount] = useState([]);
    const onAccountOptions = React.useMemo(() => {
        return mappedOnAccountPaymentOptions(onAccountListing, keyBy(vehicleList, 'id'));
    }, [onAccountListing, vehicleList]);
    useEffect(() => {
        if (showCreditAccountBranch) {
            const selectedCreditAccount = find(customers, { id: creditAccountId });
            const branches = get(selectedCreditAccount, 'branchDtos', []);
            setBranchesForCreditAccount(branches);
        }
    }, [creditAccountId, customers]);
    const branchOptions = React.useMemo(() => {
        return mappedBillingPartyBranches(branchesForCreditAccount);
    }, [branchesForCreditAccount]);
    return (_jsxs("div", { children: [_jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `paymentNature`, component: FxSelectField, options: mappedVoucherTypes(), label: "Voucher Type", onChange: (val) => { }, isRequired: true, disabled: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `voucherDate`, timePicker: true, component: FxSelectSingleDate, label: "Voucher Date", isRequired: true, disabled: !!editId, valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `creditAccountId`, component: FxSelectField, options: isHSBalancePayment
                                ? mappedTransporters(filter(transporters, (tp) => !!tp.ledgerId))
                                : mappedOnlyConsignorsConsignees(filter(customers, (el) => el.ledgerId)), label: isHSBalancePayment ? 'Payable To' : 'Credit Account', isRequired: true, onChange: (val) => {
                                if (!isHSBalancePayment) {
                                    handleFilterChange('billingPartyId', val);
                                }
                                else {
                                    handleFilterChange('hireSlipParty', val);
                                }
                                fetchOnAccountPayments({ creditAccountId: val });
                            } }) }), showCreditAccountBranch && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `creditAccountBranchId`, component: FxSelectField, options: branchOptions, label: 'Credit Account Branch' }) })), (isHSBalancePayment || isPaymentReceipt) && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `onAccountFormId`, component: FxSelectField, options: onAccountOptions, label: isHSBalancePayment ? 'On Account Payment' : 'On Account Receipt', onChange: (val) => {
                                const selectedOnAccount = find(onAccountListing, { id: val });
                                const vehicleId = get(selectedOnAccount, 'onAccountVehicleId', '');
                                if (vehicleId) {
                                    handleFilterChange('vehicleId', vehicleId);
                                }
                            } }) })), isHSBalancePayment && (_jsxs(_Fragment, { children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `divisionId`, component: FxSelectField, options: mappedOptionsFromArray(divisions), label: "Division", isRequired: true, onChange: (val) => { } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `serviceType`, component: FxSelectField, options: [
                                        {
                                            value: 'B2B',
                                            label: 'B2B',
                                        },
                                        {
                                            value: 'B2C',
                                            label: 'B2C',
                                        },
                                    ], label: "Service Type", isRequired: true, onChange: (val) => { } }) })] }))] }), _jsx(BillNumberComponent, { formikProps: formikProps, isEdit: !!editId, isReadyToFetchBillNumber: true, fieldDisabled: { 'branch': !!editId, 'bookName': !!editId, 'stationaryNumber': !!editId }, fieldLabel: { 'stationaryNumber': 'Voucher No.' }, onStationaryNumberPopulate: (stationaryNumber) => {
                    formikProps.setFieldValue('adviceNumber', stationaryNumber);
                }, onBranchSelect: (branchId, branch) => {
                    formikProps.setFieldValue('billInfo.branch', branchId);
                    formikProps.setFieldValue('billInfo.bookType', 'TRANSACTIONS');
                } }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { type: 'text', name: `adviceNumber`, component: FxTextFieldStackedLabel, label: "Advice No.", isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { type: 'text', name: `adviceAmount`, component: FxTextFieldStackedLabel, label: "Advice Amount", isRequired: true, disabled: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: "narration", type: "text", component: FxTextAreaFormikStacked, label: "Narration" }) }), isCurrencyConversionApplicable && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: "currencyCode", label: "Currency Code", placeholder: "Select Currency Code", options: getCurrencyOptionsFromCurrencyCodes(currencyCodes), component: FxSelectField, value: currencyCode, onChange: (val) => {
                                handleFilterChange('currencyCode', val);
                            }, disabled: !!editId }) }))] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(BasicInformationComponent);
