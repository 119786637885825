import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SaveToCloud = (props) => {
    return (_jsxs("svg", { width: "25", height: "24", viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_310_261272", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "25", height: "24", children: _jsx("rect", { x: "0.5", width: "24", height: "24", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_310_261272)", children: _jsx("path", { d: "M7 19.5C5.618 19.5 4.43917 19.0206 3.4635 18.0617C2.48783 17.1029 2 15.9311 2 14.5463C2 13.3039 2.39967 12.2113 3.199 11.2683C3.99833 10.3253 4.98967 9.76658 6.173 9.59225C6.4935 8.09742 7.24517 6.875 8.428 5.925C9.61067 4.975 10.968 4.5 12.5 4.5C14.3107 4.5 15.8467 5.13067 17.108 6.392C18.3693 7.65333 19 9.18933 19 11V11.5H19.3078C20.3616 11.582 21.2404 12.0058 21.9443 12.7713C22.6481 13.5366 23 14.4462 23 15.5C23 16.6153 22.6154 17.5608 21.8463 18.3365C21.0769 19.1122 20.1346 19.5 19.0193 19.5H13.5577C13.0526 19.5 12.625 19.325 12.275 18.975C11.925 18.625 11.75 18.1974 11.75 17.6923V12.2152L9.9 14.0345L8.84625 12.9905L12.5 9.3365L16.1538 12.9905L15.1 14.0345L13.25 12.2152V17.6923C13.25 17.7692 13.2821 17.8398 13.3462 17.9038C13.4102 17.9679 13.4808 18 13.5577 18H19C19.7 18 20.2917 17.7583 20.775 17.275C21.2583 16.7917 21.5 16.2 21.5 15.5C21.5 14.8 21.2583 14.2083 20.775 13.725C20.2917 13.2417 19.7 13 19 13H17.5V11C17.5 9.61667 17.0125 8.4375 16.0375 7.4625C15.0625 6.4875 13.8833 6 12.5 6C11.1167 6 9.9375 6.4875 8.9625 7.4625C7.9875 8.4375 7.5 9.61667 7.5 11H6.98075C6.03325 11 5.21625 11.3417 4.52975 12.025C3.84325 12.7083 3.5 13.5333 3.5 14.5C3.5 15.4667 3.84167 16.2917 4.525 16.975C5.20833 17.6583 6.03333 18 7 18H9.5V19.5H7Z", fill: "#0078D4" }) })] }));
};
const SaveToCloudIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SaveToCloud, ...props });
};
export default SaveToCloudIcon;
