import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getSubTotal } from 'components/dashboard/maintenance/serviceentries/service_line_items';
import { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { filter, find, forEach, get, includes, isEmpty, isNull, isUndefined, keyBy, round, set, uniqBy, unset, map, } from 'lodash';
import { getDocumentsForAPI, mappedUnsettledJobs } from 'utils/form_utils';
import * as Yup from 'yup';
import { getPageTitle } from '../../../../../white_label_constants';
import { Formik } from 'formik';
import { fetchVehicleListMini, fetchDataFromModulesForVehicle } from 'actions/dashboard/vehicles_action';
import { fetchVendors } from 'actions/dashboard/vendors_action';
import { fetchServiceTasks } from 'actions/dashboard/easy/service_tasks_action';
import { createServiceEntry, fetchServiceEntry, updateServiceEntry } from 'actions/dashboard/easy/service_entry_action';
import { fetchWorkOrderById } from 'actions/dashboard/parts_inventory/work_order_action';
import { DATE_FORMAT_TIMESTAMP, getEndDate, getMomentTime } from 'utils/date_utils';
import { isAccountingManagementEnabled, isCreateServiceEntryAllowedForUser, isUpdateServiceEntryAllowedForUser, isViewLedgersAllowedForUser, isViewStationaryAllowedForUser, } from 'components/dashboard/roles/permission_utils';
import { handleError } from 'constant';
import { fetchParts } from 'actions/dashboard/parts_inventory/parts_action';
import { fetchLedgers } from 'actions/dashboard/consignments/ledgersActions';
import { fetchJobNotMappedToTripsheetInServiceHistory, isMangalamCement, isOdometerNonMandatoryAndHiddenInServiceEntries, serviceEntryApprovalConfigEnabled, showRemoveVehiclesInSearchForAccount, enableUnitPartCostInServiceHistory, } from 'utils/account_utils';
import ServiceEntryForm from 'components/dashboard/maintenance/serviceentries/service_entry_form';
import { UNIT_TYPE_TO_UNITS_MAP } from 'utils/rateChartsUtils';
import { useNavigate, useParams } from 'react-router';
import FxModal from 'sharedV2/FxModal';
import AddEditPartComponent from 'components/dashboard/parts_inventory/parts/add_edit_part_component';
import { fetchJobListNewV2 } from 'actions/dashboard/route_dispatch/jobs_action';
import { fetchJobsNotMappedToTripSheet } from 'actions/dashboard/finances/transaction_action';
import { postApprovalMatrixData } from 'actions/dashboard/easy/fuel_entry_action';
import { useFxAlert } from 'components/dashboard/customHooks';
import { isAmountBasedTaxInServiceEntry } from './service_entry_account_utils';
export const emptyServicePart = {
    part: {
        id: undefined,
    },
    unitType: null,
    unit: null,
    count: '',
    cost: '',
    tax1Type: '',
    tax1Percent: '',
    tax2Type: '',
    tax2Percent: '',
    discountPercent: '',
};
export const emptyServiceLineItem = {
    vehicleDomain: '',
    itemId: '',
    description: '',
    itemType: '',
    workType: null,
    laborCost: '',
    subTotal: 0,
    serviceParts: [emptyServicePart],
};
const AddEditServiceEntry = (props) => {
    const { accesstoken } = props;
    const navigate = useNavigate();
    const params = useParams();
    const { id: editId } = params;
    const isEdit = !isUndefined(editId);
    const scope = get(props, 'scope');
    const showAlert = useFxAlert();
    const showUnitPartCostField = enableUnitPartCostInServiceHistory();
    const permissions = {
        isViewLedgerAndModifyServiceEntryAllowed: () => {
            return (isViewLedgersAllowedForUser(scope) &&
                (permissions.isCreateServiceEntryAllowed() || permissions.isUpdateServiceEntryAllowed()));
        },
        isCreateServiceEntryAllowed: () => {
            return isCreateServiceEntryAllowedForUser(scope);
        },
        isUpdateServiceEntryAllowed: () => {
            return isUpdateServiceEntryAllowedForUser(scope);
        },
        isViewStationaryAllowedForUser: () => {
            return isViewStationaryAllowedForUser(scope);
        },
        isOdometerNonMandatoryAndHiddenInServiceEntries: () => {
            return isOdometerNonMandatoryAndHiddenInServiceEntries();
        },
    };
    const [initialValues, setInitialValues] = useState({
        branchId: '',
        stationaryId: '',
        stationaryNumber: '',
        isStationaryNumberEditAllowed: false,
        vehicleId: '',
        meterEntry: {
            value: '',
            voided: false,
        },
        runningHours: '',
        completionDate: getMomentTime(),
        vendor: {
            id: '',
        },
        startDate: '',
        reference: '',
        serviceLineItems: [emptyServiceLineItem],
        discount: '',
        discountPercentage: '',
        discountType: 'PERCENT',
        laborTax1: '',
        laborTax1Type: '',
        laborTax1Percentage: '',
        laborTax2: '',
        laborTax2Type: '',
        laborTax2Percentage: '',
        bookType: 'SERVICE_ENTRY',
        notes: '',
        modeOfPayment: 'Cash',
        jobId: null,
        ledgerDetails: {
            directIncomeLedgerEntry: {
                ledgerId: null,
            },
            igstLedgerEntry: {
                ledgerId: null,
            },
            cgstLedgerEntry: {
                ledgerId: null,
            },
            sgstLedgerEntry: {
                ledgerId: null,
            },
            insuranceLedgerEntry: {
                ledgerId: null,
                amount: 0,
            },
        },
        images: [],
    });
    const [setStartDate, setSetStartDate] = useState(false);
    const [isSparePart, setIsSparePart] = useState(false);
    const [workOrderOptions, setWorkOrderOptions] = useState([]);
    const [labourSubTotal, setLabourSubTotal] = useState(0);
    const [partsSubTotal, setPartsSubTotal] = useState(0);
    const [laborTotalTax, setLaborTotalTax] = useState(0);
    const [partsTotalTax, setPartsTotalTax] = useState(0);
    const [cgstAmount, setCgstAmount] = useState(0);
    const [sgstAmount, setSgstAmount] = useState(0);
    const [igstAmount, setIgstAmount] = useState(0);
    const [isCreatePartModalOpen, setIsCreatePartModalOpen] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: getMomentTime().subtract(6, 'days').startOf('day'),
        endDate: getEndDate(),
    });
    const [jobListOptions, setJobList] = useState([]);
    const [vehicleId, setVehicleId] = useState([]);
    const [editModeJob, setEditModeJob] = useState(null);
    const [partOptions, setPartOptions] = useState([]);
    const isAmountBasedTax = isAmountBasedTaxInServiceEntry();
    const onDateSelect = (startDate, endDate) => {
        setDateRange({ startDate, endDate });
    };
    const fetchJobList = async () => {
        try {
            if (fetchJobNotMappedToTripsheetInServiceHistory()) {
                const result = await fetchJobsNotMappedToTripSheet(props.accesstoken, {
                    fromDate: getMomentTime(dateRange.startDate).format(DATE_FORMAT_TIMESTAMP),
                    toDate: getMomentTime(dateRange.endDate).format(DATE_FORMAT_TIMESTAMP),
                    vehicleId,
                    driverId: null,
                    includeTransShipmentChildJob: true,
                }).promise;
                const jobList = get(result, 'data', []);
                const finalJobList = JSON.parse(JSON.stringify(jobList));
                if (editModeJob) {
                    finalJobList.push(editModeJob);
                }
                setJobList(mappedUnsettledJobs(uniqBy(finalJobList, 'id')));
            }
            else {
                const result = await props.fetchJobListNewV2(props.accesstoken, {
                    vehicleId,
                    from: dateRange.startDate,
                    to: dateRange.endDate,
                    page: 1,
                    size: 10000,
                });
                const mapped = map(get(result, 'payload.data.content', []), (job) => {
                    return {
                        value: job.id,
                        label: job.name,
                    };
                });
                if (editModeJob) {
                    mapped.push(editModeJob);
                }
                setJobList(mapped);
            }
        }
        catch (e) {
            setJobList([]);
        }
    };
    useMemo(() => {
        fetchJobList();
    }, [dateRange.startDate, dateRange.endDate, vehicleId, editModeJob]);
    const validationSchema = Yup.object().shape({
        vehicleId: Yup.string().required('Required'),
        ...(!permissions.isOdometerNonMandatoryAndHiddenInServiceEntries() && {
            meterEntry: Yup.object().shape({
                value: Yup.number().required('Required').min(0, 'Must be greater than 0'),
            }),
        }),
        ...(permissions.isViewLedgerAndModifyServiceEntryAllowed() && {
            ledgerDetails: Yup.object().shape({
                directIncomeLedgerEntry: Yup.object().shape({
                    ledgerId: Yup.string().required('Required').nullable(),
                }),
                igstLedgerEntry: Yup.object()
                    .shape({
                    ledgerId: Yup.string()
                        .test('igstLedgerEntry', 'Required', (value) => {
                        return igstAmount > 0 ? !isUndefined(value) && !isNull(value) && value !== '' : true;
                    })
                        .nullable(),
                })
                    .nullable(),
                cgstLedgerEntry: Yup.object()
                    .shape({
                    ledgerId: Yup.string()
                        .test('cgstLedgerEntry', 'Required', (value) => {
                        return cgstAmount > 0 ? !isUndefined(value) && !isNull(value) && value !== '' : true;
                    })
                        .nullable(),
                })
                    .nullable(),
                sgstLedgerEntry: Yup.object()
                    .shape({
                    ledgerId: Yup.string()
                        // tslint:disable-next-line:only-arrow-functions
                        .test('sgstLedgerEntry', 'Required', function (value) {
                        return sgstAmount > 0 ? !isUndefined(value) && !isNull(value) && value !== '' : true;
                    })
                        .nullable(),
                })
                    .nullable(),
                insuranceLedgerEntry: Yup.object()
                    .shape({
                    ledgerId: Yup.string()
                        .test('insuranceLedgerEntry', 'Required', function (value) {
                        const insuranceAmount = this.parent.amount;
                        return insuranceAmount > 0 && isAccountingManagementEnabled()
                            ? !isUndefined(value) && !isNull(value) && value !== ''
                            : true;
                    })
                        .nullable(),
                })
                    .nullable(),
            }),
        }),
        completionDate: Yup.date().required('Required').nullable(),
        ...(permissions.isViewLedgerAndModifyServiceEntryAllowed() && {
            vendor: Yup.object().shape({
                id: Yup.string().required('Required').nullable(),
            }),
        }),
        // there should be atleast one service line item in which only service task is mandatory
        serviceLineItems: Yup.array()
            .of(Yup.object().shape({
            itemId: !isSparePart ? Yup.string().required('Required') : Yup.string(),
            serviceParts: Yup.array().of(Yup.object().shape({
                part: Yup.object().shape({
                    id: Yup.string().required('Required').nullable(),
                }),
                //cost must be greater than taxAmount
                cost: Yup.number()
                    .test('cost', 'Cost must be greater than tax amount', function (value) {
                    const taxAmount = this.parent.taxAmount;
                    let partCost = value;
                    if (showUnitPartCostField && partCost) {
                        partCost = partCost * this.parent.count;
                    }
                    if (partCost && taxAmount && isAmountBasedTax) {
                        return partCost >= taxAmount;
                    }
                    return true;
                })
                    .nullable(),
            })),
        }))
            .required('At least one line item is required')
            .min(1, 'At least one line item is required'),
    });
    const fetchAllVendors = () => {
        return props
            .fetchVendors(accesstoken)
            .then((result) => {
            if (result.error) {
                throw new Error(handleError(result.payload.response));
            }
        })
            .catch((err) => {
            alert(err);
        });
    };
    useEffect(() => {
        props
            .fetchVehicleListMini(accesstoken, showRemoveVehiclesInSearchForAccount(props.loggedInUser, props.isSwitchedUser))
            .then((result) => {
            if (result.error) {
                throw new Error(handleError(result.payload.response));
            }
        })
            .catch((err) => {
            alert(err);
        });
        fetchAllVendors();
        props
            .fetchServiceTasks(accesstoken)
            .then((result) => {
            if (result.error) {
                throw new Error(handleError(result.payload.response));
            }
        })
            .catch((err) => {
            alert(err);
        });
        if (permissions.isViewLedgerAndModifyServiceEntryAllowed()) {
            props
                .fetchLedgers(accesstoken)
                .then((result) => {
                if (result.error) {
                    throw new Error(handleError(result.payload.response));
                }
            })
                .catch((err) => {
                alert(err);
            });
        }
    }, []);
    const fetchWorkOrders = (vehicleId) => {
        if (vehicleId) {
            props.fetchDataFromModulesForVehicle(props.accesstoken, vehicleId).then((res) => {
                if (!res.error) {
                    const data = get(res, 'payload.data', {});
                    setWorkOrderOptions([
                        {
                            label: data.workOrderNumber,
                            value: data.workOrderId,
                            stationaryNumber: data.workOrderNumber,
                        },
                    ]);
                }
                else {
                    setWorkOrderOptions([]);
                }
            });
        }
        else {
            setWorkOrderOptions([]);
        }
    };
    const fetchWorkOrderData = (workOrderId) => new Promise((resolve, reject) => {
        if (workOrderId) {
            props.fetchWorkOrderById(props.accesstoken, workOrderId).then((res) => {
                if (!res.error) {
                    resolve(get(res, 'payload.data', {}));
                }
                else {
                    reject(res.error);
                }
            });
        }
        else {
            reject('No workOrderId provided');
        }
    });
    useEffect(() => {
        if (isEdit) {
            const { vehicleId } = params;
            setVehicleId(vehicleId);
            props.fetchServiceEntry(editId, accesstoken).then((result) => {
                const serviceEntry = get(result, 'payload.data', {});
                setEditModeJob({
                    value: get(serviceEntry, 'jobId'),
                    label: get(serviceEntry, 'jobName'),
                });
                set(serviceEntry, 'vehicleId', vehicleId);
                set(serviceEntry, 'branch', get(serviceEntry, 'branchId'));
                set(serviceEntry, 'bookType', 'SERVICE_ENTRY');
                set(serviceEntry, 'completionDate', getMomentTime(get(serviceEntry, 'date')));
                if (get(serviceEntry, 'discountPercentage')) {
                    set(serviceEntry, 'discountPercentage', get(serviceEntry, 'discountPercentage'));
                    set(serviceEntry, 'discountType', 'PERCENT');
                }
                else {
                    set(serviceEntry, 'discount', get(serviceEntry, 'discount'));
                    set(serviceEntry, 'discountType', 'AMOUNT');
                }
                const newPartOptions = [];
                forEach(get(serviceEntry, 'serviceLineItems'), (se, index) => {
                    newPartOptions[index] = [];
                    forEach(get(se, 'serviceParts'), (sp, innerIndex) => {
                        const wkIdName = get(sp, `part`);
                        if (wkIdName) {
                            newPartOptions[index][innerIndex] = [
                                {
                                    label: wkIdName.name,
                                    value: wkIdName.id,
                                },
                            ];
                        }
                        if (isAmountBasedTax) {
                            let partCost = get(sp, `cost`, 0);
                            const unit = get(sp, `unit`, 0);
                            const discountPercent = get(sp, `discountPercent`, 0);
                            if (showUnitPartCostField) {
                                partCost = partCost * unit;
                            }
                            const discountAmount = (discountPercent / 100) * partCost;
                            const taxPercent = get(sp, `tax1Percent`, 0) + get(sp, `tax2Percent`, 0);
                            if (partCost && taxPercent) {
                                set(sp, 'taxAmount', (taxPercent / 100) * (partCost - discountAmount));
                            }
                        }
                        if (showUnitPartCostField) {
                            set(sp, 'cost', sp.cost / sp.count);
                        }
                        const unitType = getUnitTypeFromUnit(get(sp, 'unit'));
                        set(sp, 'unitType', unitType);
                    });
                    setPartOptions(newPartOptions);
                });
                if (!get(serviceEntry, 'ledgerDetails.insuranceLedgerEntry')) {
                    set(serviceEntry, 'ledgerDetails.insuranceLedgerEntry', {
                        ledgerId: null,
                        amount: get(serviceEntry, 'insuranceAmount') || 0,
                    });
                }
                setIsSparePart(get(serviceEntry, 'sparePartEntry', false));
                setInitialValues(serviceEntry);
                setSetStartDate(!!get(serviceEntry, 'startDate'));
                if (get(serviceEntry, 'workOrderId')) {
                    setWorkOrderOptions([
                        {
                            label: get(serviceEntry, 'workOrderNumber'),
                            value: get(serviceEntry, 'workOrderId'),
                            stationaryNumber: get(serviceEntry, 'workOrderNumber'),
                        },
                    ]);
                }
                else {
                    fetchWorkOrders(vehicleId);
                }
            });
        }
    }, [editId]);
    const getUnitTypeFromUnit = (unit) => {
        let unitType = null;
        forEach(UNIT_TYPE_TO_UNITS_MAP, (units, type) => {
            if (includes(units, unit)) {
                unitType = type;
                return false; // exit the loop once a match is found
            }
        });
        return unitType;
    };
    const onSuccessfulVendorCreation = () => {
        fetchAllVendors();
    };
    const onSuccessfulPartCreation = () => {
        props.fetchParts(accesstoken);
        setIsCreatePartModalOpen(false);
    };
    const onSubmit = (values, { setSubmitting }) => {
        const data = JSON.parse(JSON.stringify(values));
        set(data, 'branchId', get(values, 'branch'));
        const insuranceAmount = get(values, 'ledgerDetails.insuranceLedgerEntry.amount');
        const discountType = get(values, 'discountType');
        if (discountType === 'PERCENT') {
            unset(data, 'discount');
        }
        else {
            unset(data, 'discountPercentage');
        }
        set(data, 'date', getMomentTime(get(values, 'completionDate')).valueOf());
        unset(data, 'completionDate');
        if (get(data, 'startDate')) {
            set(data, 'startDate', getMomentTime(get(values, 'startDate')).valueOf());
        }
        if (!get(data, 'vendor.id')) {
            set(data, 'vendor', null);
        }
        const serviceLineItems = get(data, 'serviceLineItems');
        const mappedServiceTasks = keyBy(props.serviceTasksList, 'id');
        if (!isSparePart) {
            forEach(serviceLineItems, (lineItem, index) => {
                const itemDetails = get(mappedServiceTasks, get(lineItem, 'itemId'));
                const subTotal = getSubTotal(data, index, showUnitPartCostField);
                set(lineItem, 'itemType', get(itemDetails, 'type'));
                set(lineItem, 'name', get(itemDetails, 'name'));
                set(lineItem, 'subTotal', subTotal);
                // if part.id inside serviceParts is null, then remove the whole part
                const serviceParts = filter(get(lineItem, 'serviceParts'), (sp) => {
                    if (showUnitPartCostField) {
                        set(sp, 'cost', sp.cost * sp.count);
                    }
                    return !!get(sp, 'part.id');
                });
                set(lineItem, 'serviceParts', serviceParts);
                if (isEmpty(serviceParts)) {
                    delete lineItem.serviceParts;
                }
            });
        }
        else {
            const item = find(props.serviceTasksList, { name: 'Spare Entry Task' });
            const itemId = get(item, 'id');
            const lineItem = get(data, 'serviceLineItems[0]');
            set(data, `serviceLineItems[0].itemId`, itemId);
            set(data, 'sparePartEntry', isSparePart);
            const serviceParts = filter(get(lineItem, 'serviceParts'), (sp) => {
                if (showUnitPartCostField) {
                    set(sp, 'cost', sp.cost * sp.count);
                }
                return !!get(sp, 'part.id');
            });
            set(lineItem, 'serviceParts', serviceParts);
        }
        const length = data.serviceLineItems ? data.serviceLineItems.length : 0;
        const totalAmount = labourSubTotal + partsSubTotal;
        set(data, 'laborSubtotal', labourSubTotal);
        set(data, 'partsSubtotal', partsSubTotal);
        if (insuranceAmount > totalAmount) {
            showAlert('Insurance amount cannot be greater than total tax amount');
            setSubmitting(false);
            return;
        }
        if (length === 0) {
            if (!isMangalamCement()) {
                setSubmitting(false);
                return;
            }
        }
        else {
            const totalTaxLedgerAmount = igstAmount + cgstAmount + sgstAmount + insuranceAmount;
            const totalDebitLedgerAmount = totalAmount - (totalTaxLedgerAmount || 0);
            if (get(data, 'ledgerDetails.igstLedgerEntry.ledgerId')) {
                set(data, 'ledgerDetails.igstLedgerEntry.amount', round(igstAmount, 2) || 0);
            }
            else {
                unset(data, 'ledgerDetails.igstLedgerEntry');
            }
            if (get(data, 'ledgerDetails.cgstLedgerEntry.ledgerId')) {
                set(data, 'ledgerDetails.cgstLedgerEntry.amount', round(cgstAmount, 2) || 0);
            }
            else {
                unset(data, 'ledgerDetails.cgstLedgerEntry');
            }
            if (get(data, 'ledgerDetails.sgstLedgerEntry.ledgerId')) {
                set(data, 'ledgerDetails.sgstLedgerEntry.amount', round(sgstAmount, 2) || 0);
            }
            else {
                unset(data, 'ledgerDetails.sgstLedgerEntry');
            }
            if (get(data, 'ledgerDetails.directIncomeLedgerEntry.ledgerId')) {
                set(data, 'ledgerDetails.directIncomeLedgerEntry.amount', totalDebitLedgerAmount);
            }
            else {
                unset(data, 'ledgerDetails.directIncomeLedgerEntry');
            }
            if (get(data, 'ledgerDetails.insuranceLedgerEntry.ledgerId')) {
                set(data, 'ledgerDetails.insuranceLedgerEntry.amount', insuranceAmount || 0);
            }
            else {
                unset(data, 'ledgerDetails.insuranceLedgerEntry');
            }
            set(data, 'insuranceAmount', insuranceAmount || 0);
        }
        set(data, 'totalAmount', totalAmount);
        const images = getDocumentsForAPI(values.images);
        if (images && images.length > 0) {
            set(data, 'images', images);
        }
        else {
            delete data.images;
        }
        if (!isEdit) {
            props
                .createServiceEntry(accesstoken, data)
                .then((result) => {
                if (result.error) {
                    setSubmitting(false);
                    throw new Error(handleError(result.payload.response));
                }
                else {
                    if (serviceEntryApprovalConfigEnabled()) {
                        const vehicleGroup = find(props.vehicleListMini, { id: get(data, 'vehicleId') });
                        const approvalBody = {
                            accountId: get(props, 'loggedInUser.accountId'),
                            approvalEntity: 'SERVICE_ENTRY',
                            approvalEntityId: get(result, 'payload.data.id'),
                            groupId: get(vehicleGroup, 'groupId'),
                        };
                        props.postApprovalMatrixData(accesstoken, approvalBody, {});
                    }
                    navigate(`/dashboard/maintenance/serviceentries`);
                }
            })
                .catch((err) => {
                alert(err);
            });
        }
        else {
            props
                .updateServiceEntry(accesstoken, data, editId)
                .then((result) => {
                if (result.error) {
                    setSubmitting(false);
                    throw new Error(handleError(result.payload.response));
                }
                else {
                    if (serviceEntryApprovalConfigEnabled()) {
                        const vehicleGroup = find(props.vehicleListMini, { id: get(data, 'vehicleId') });
                        const approvalBody = {
                            accountId: get(props, 'loggedInUser.accountId'),
                            approvalEntity: 'SERVICE_ENTRY',
                            approvalEntityId: editId,
                            groupId: get(vehicleGroup, 'groupId'),
                        };
                        props.postApprovalMatrixData(accesstoken, approvalBody, {});
                    }
                    navigate(`/dashboard/maintenance/serviceentries`);
                }
            })
                .catch((err) => {
                alert(err);
            });
        }
    };
    return (_jsxs("div", { className: "animated fadeIn", children: [_jsx(Helmet, { title: getPageTitle('Add Service Entry') }), _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: validationSchema, enableReinitialize: true, children: ({ values, errors, setFieldValue, handleSubmit }) => {
                    return (_jsxs(_Fragment, { children: [_jsx(ServiceEntryForm, { permissions: permissions, isEdit: isEdit, onSuccessfulVendorCreation: onSuccessfulVendorCreation, setStartDate: setStartDate, setSetStartDate: setSetStartDate, values: values, setIsSparePart: setIsSparePart, isSparePart: isSparePart, handleSubmit: handleSubmit, labourSubTotal: labourSubTotal, setLabourSubTotal: setLabourSubTotal, partsSubTotal: partsSubTotal, setPartsSubTotal: setPartsSubTotal, setFieldValue: setFieldValue, setPartsTotalTax: setPartsTotalTax, setLaborTotalTax: setLaborTotalTax, formikProps: { values, errors, setFieldValue, handleSubmit }, totalTax: partsTotalTax + laborTotalTax, workOrderOptions: workOrderOptions, fetchWorkOrderData: fetchWorkOrderData, fetchWorkOrders: fetchWorkOrders, setIsCreatePartModalOpen: setIsCreatePartModalOpen, onDateSelect: onDateSelect, dateRange: dateRange, jobListOptions: jobListOptions, setVehicleId: setVehicleId, igstAmount: igstAmount, setIgstAmount: setIgstAmount, cgstAmount: cgstAmount, setCgstAmount: setCgstAmount, sgstAmount: sgstAmount, setSgstAmount: setSgstAmount, showUnitPartCostField: showUnitPartCostField, partOptions: partOptions }), _jsx(FxModal, { footer: null, open: isCreatePartModalOpen, onCancel: () => setIsCreatePartModalOpen(false), style: { maxWidth: '95vw', minWidth: '90vw', maxHeight: '40rem', overflow: 'auto' }, children: _jsx(AddEditPartComponent, { onSuccessfulPartCreation: onSuccessfulPartCreation }) })] }));
                } })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchVehicleListMini,
        fetchVendors,
        fetchServiceTasks,
        createServiceEntry,
        updateServiceEntry,
        fetchServiceEntry,
        fetchParts,
        fetchLedgers,
        fetchDataFromModulesForVehicle,
        fetchWorkOrderById,
        fetchJobListNewV2,
        fetchJobsNotMappedToTripSheet,
        postApprovalMatrixData,
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        login: get(state, 'login.data'),
        scope: get(state, 'login.data.scope'),
        loggedInUser: get(state, 'settings.loggedInUser'),
        accesstoken: get(state, 'login.data.access_token'),
        issuesList: get(state, 'issues.issuesList'),
        serviceTasksList: get(state, 'servicetasks.serviceTasksList', []),
        isSwitchedUser: get(state, 'login.isSwitchedUser'),
        vehicleListMini: get(state, 'vehicles.vehicleListMini', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditServiceEntry);
