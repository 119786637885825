import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SapUnloadingNotFound = (props) => {
    return (_jsxs("svg", { id: "a", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", height: 24, width: 24, children: [_jsx("path", { fill: "red", d: "M12,21.5c-1.31,0-2.55-.25-3.71-.75-1.16-.5-2.16-1.18-3.02-2.03-.86-.85-1.53-1.86-2.03-3.02-.5-1.16-.75-2.39-.75-3.7s.25-2.55.75-3.71c.5-1.16,1.18-2.16,2.03-3.02s1.86-1.53,3.02-2.03c1.16-.5,2.39-.75,3.7-.75s2.55.25,3.71.75,2.16,1.18,3.02,2.03,1.53,1.86,2.03,3.02c.5,1.16.75,2.39.75,3.7s-.25,2.55-.75,3.71c-.5,1.16-1.18,2.16-2.03,3.02-.85.86-1.86,1.53-3.02,2.03-1.16.5-2.39.75-3.7.75ZM12,20c2.23,0,4.13-.78,5.68-2.33s2.33-3.44,2.33-5.68-.78-4.13-2.33-5.68-3.44-2.33-5.68-2.33-4.13.78-5.68,2.33-2.33,3.44-2.33,5.68.78,4.13,2.33,5.68,3.44,2.33,5.68,2.33Z" }), _jsxs("g", { children: [_jsxs("g", { children: [_jsx("polygon", { fill: "red", points: "11.08 6.07 8.4 8.08 7.68 10.75 8.21 14.09 11.59 17.75 13.07 17.49 14.79 14.66 16.41 12.61 16.08 8.68 14.05 6.92 11.08 6.07" }), _jsx("path", { fill: "red", d: "M12,17.12c1.35-1.24,2.35-2.36,3-3.37s.98-1.91.98-2.69c0-1.2-.38-2.19-1.15-2.96s-1.71-1.15-2.82-1.15-2.06.38-2.82,1.15-1.15,1.75-1.15,2.96c0,.78.33,1.68.98,2.69s1.65,2.13,3,3.37ZM12,18.88c-1.78-1.51-3.11-2.92-3.98-4.22s-1.32-2.5-1.32-3.6c0-1.66.53-2.98,1.6-3.96,1.07-.98,2.3-1.47,3.7-1.47s2.64.49,3.7,1.47,1.6,2.3,1.6,3.96c0,1.1-.44,2.31-1.32,3.6s-2.21,2.7-3.98,4.22Z" })] }), _jsx("path", { fill: "#fff", d: "M11.64,14.94v-5.81l-2.73,2.73-.51-.51,3.6-3.6,3.6,3.6-.51.51-2.73-2.73v5.81h-.72Z" })] }), _jsx("rect", { fill: "red", x: "2.94", y: "11.14", width: "17.75", height: "1", transform: "translate(-4.77 11.76) rotate(-45)" })] }));
};
const SapUnloadingNotFoundIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SapUnloadingNotFound, ...props });
};
export default SapUnloadingNotFoundIcon;
