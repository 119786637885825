import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get, groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REPORT_TEMPLATE_TYPES } from 'utils/reportTemplatesUtils';
import { fetchBranches } from '../../../actions/dashboard/branch/actions';
import { fetchAllTransporters } from '../../../actions/dashboard/finances/transporter_actions';
import { fetchAdvances, fetchPayments, fetchAdjustmentNotes, fetchCustomerOverview, } from '../../../actions/dashboard/payment_forms/actions';
import Loader from '../../utils/loader';
import GenerateReportForSelectedTemplateModal from '../reportTemplates/GenerateReportForSelectedTemplateModal';
import PaymentListingComponent from './PaymentListingComponent';
const PaymentComponent = (props) => {
    const { accesstoken, login, location, groupId, customers, loggedInUser, allBranches, transporters, activeTab, activeSubTab, } = props;
    const [openFilters, setOpenFilters] = useState(false);
    const [advances, setAdvances] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isGenerateReportForTemplateModalOpen, setIsGenerateReportForTemplateModalOpen] = useState(false);
    const [metaDataForTemplateGeneration, setMetaDataForTemplateGeneration] = useState({});
    const scope = login.scope;
    const { startDate, endDate, currentPage, sizePerPage, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, } = props;
    const fetchData = () => {
        if ((activeTab === 'PAYMENTS' && activeSubTab === 'UNSETTLED') ||
            (activeTab === 'PAYMENTS' && activeSubTab === 'SETTLED') ||
            (activeTab === 'PAYMENTS' && activeSubTab === 'ALL_PAYMENTS')) {
            const params = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature,
                adviceNumber,
                creditAccountId,
                voucherNumber,
            };
            if (activeSubTab === 'UNSETTLED') {
                params['settledPayment'] = false;
            }
            if (activeSubTab === 'SETTLED') {
                params['settledPayment'] = true;
            }
            props
                .fetchPayments(accesstoken, params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
        if ((activeTab === 'ADJUSTMENT_NOTES' && activeSubTab === 'CREDIT_NOTE') ||
            (activeTab === 'ADJUSTMENT_NOTES' && activeSubTab === 'DEBIT_NOTE')) {
            const params = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature,
                adviceNumber,
                creditAccountId,
                voucherNumber,
                isCreditNote: activeSubTab === 'CREDIT_NOTE' ? true : false,
            };
            props
                .fetchAdjustmentNotes(accesstoken, params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
        if (activeTab === 'CUSTOMER_OVERVIEW') {
            const params = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature,
                adviceNumber,
                creditAccountId,
                voucherNumber,
                isCreditNote: activeSubTab === 'CREDIT_NOTE' ? true : false,
            };
            props
                .fetchCustomerOverview(accesstoken, params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
    };
    useEffect(() => {
        fetchData();
    }, [
        startDate.valueOf(),
        endDate.valueOf(),
        branchId,
        paymentNature,
        adviceNumber,
        creditAccountId,
        voucherNumber,
        activeTab,
        activeSubTab,
    ]);
    const onOpenReportForTemplate = (row) => {
        setMetaDataForTemplateGeneration({
            id: row.id,
            formatType: REPORT_TEMPLATE_TYPES.ERP_MONEY_RECEIPT,
        });
        setIsGenerateReportForTemplateModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: isInitialLoad ? (_jsx(Loader, {})) : (_jsx(PaymentListingComponent, { onPageChange: props.onPageChange, currentPage: currentPage, sizePerPage: sizePerPage, data: advances, groupedBranches: groupBy(allBranches, 'id'), groupedCreditAccounts: groupBy(customers, 'id'), groupedTransporters: groupBy(transporters, 'id'), onOpenReportForTemplate: onOpenReportForTemplate, fetchData: fetchData, activeTab: activeTab, activeSubTab: activeSubTab })) }), isGenerateReportForTemplateModalOpen && (_jsx(GenerateReportForSelectedTemplateModal, { isOpen: isGenerateReportForTemplateModalOpen, onClose: () => {
                    setIsGenerateReportForTemplateModalOpen((v) => !v);
                }, formatType: metaDataForTemplateGeneration.formatType, itemId: metaDataForTemplateGeneration.id, onSuccess: () => {
                    setIsGenerateReportForTemplateModalOpen((v) => !v);
                } }))] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAdvances,
        fetchPayments,
        fetchBranches,
        fetchAllTransporters,
        fetchAdjustmentNotes,
        fetchCustomerOverview,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        loggedInUser: state.settings.loggedInUser,
        groupId: state.local.defaultGroupId,
        transporters: state.transporters.transporterList,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentComponent);
