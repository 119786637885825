import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { customProgressBarConfig, FILE_UPLOAD_STATUS, getImageKitUploadEndpoint, getShowUploadListConfig, getUrlToUploadFileObject, getValidUploadFileTypes, iconRender, setFieldValueOnFile, UPLOAD_LIST_TYPE, validateFileBeforeUpload, } from 'utils/fileUploadutils';
import LightBoxUtils from 'utils/light_box_utils';
import { FxButton, FxDropzone, FxUploadDragIconWrapper, FxUploadHintWrapper, FxUploadTextWrapper } from 'sharedV2';
import { filter, find, get, isObject, last, map, round, set } from 'lodash';
import axios from 'axios';
import { IMAGEKIT_PUBLIC_API_KEY } from 'constant';
import { getMomentTime, MINUTES_1 } from 'utils/date_utils';
import { connect } from 'react-redux';
import { fetchImagekitSHAV2, fetchImagekitSHAV2Public, getServerTime, setUploadLoading, } from 'actions/dashboard/utils_action';
import { v4 as uuidv4 } from 'uuid';
import { FxPlusIcon, FxUploadIcon } from 'sharedV2/FxIcons';
import Styled from 'styled-components';
import { flushSync } from 'react-dom';
const MultiFileDropzoneWrapper = Styled.div `
    .ant-upload-picture-card-wrapper {
       display: flex;
      .ant-upload{
        order:100;
        height: 102px;
        width: 102px;

        }
    }
    .ant-progress-line{
       width:98%
    }
    .ant-upload-list-picture-card{
        margin-right:8px
    }

`;
const FxMultiFileDropzoneComponent = (props) => {
    const { t } = useTranslation();
    const { name, accept, message = t('dropzoneTextMsg'), iconMessage, hintMessage, onlyView, accesstoken, loggedInUser, showOnlyButton, buttonText = t('clickToUpload'), buttonType = 'default', listType = UPLOAD_LIST_TYPE.PICTURE_CARD, currentImageTagToUse, keyForImageUrl = 'url', keyForImageTag = 'imageTag', } = props;
    const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    useEffect(() => {
        setFileList(map(props.fileList, (file) => {
            if (isObject(file)) {
                if (get(file, 'uid')) {
                    return file;
                }
                else {
                    return getUrlToUploadFileObject(get(file, keyForImageUrl), {
                        ...file,
                        [keyForImageTag]: get(file, keyForImageTag, ''),
                    });
                }
            }
            else {
                return getUrlToUploadFileObject(file, {});
            }
        }));
    }, [props.fileList]);
    const setFieldOnFile = (file, pairs, flushNeed = true) => {
        let updatedFileList = fileList.length ? fileList : [file];
        const updateHandler = () => {
            setFileList((PrevFileList) => {
                if (file) {
                    const foundFile = find(PrevFileList, { uid: file.uid });
                    if (foundFile) {
                        setFieldValueOnFile(foundFile, pairs);
                        updatedFileList = map(PrevFileList, (f) => {
                            if (f.uid === file.uid) {
                                return foundFile;
                            }
                            return f;
                        });
                        return updatedFileList;
                    }
                    else {
                        updatedFileList = PrevFileList;
                        return updatedFileList;
                    }
                }
                updatedFileList = PrevFileList;
                return updatedFileList;
            });
        };
        if (flushNeed) {
            flushSync(() => {
                updateHandler();
            });
        }
        else {
            updateHandler();
        }
        return updatedFileList;
    };
    const onDrop = async (acceptedFile) => {
        if (!acceptedFile) {
            return;
        }
        const fileValidationObj = validateFileBeforeUpload(acceptedFile);
        if (get(fileValidationObj, 'error')) {
            const errorMsg = get(fileValidationObj, 'errorMsg');
            const updatedFileList = setFieldOnFile(acceptedFile, [
                ['status', FILE_UPLOAD_STATUS.ERROR],
                ['error', errorMsg],
            ]);
            props.fileErrorCallback && props.fileErrorCallback(updatedFileList);
            return;
        }
        if (props.onDropCB) {
            props.onDropCB(acceptedFile);
            return;
        }
        const config = {
            onUploadProgress: (progressEvent) => {
                setFieldOnFile(acceptedFile, [['percent', round((progressEvent.loaded * 100) / progressEvent.total)]], false);
            },
        };
        let timeStamp = Math.floor(getMomentTime().valueOf());
        try {
            const result = await props.getServerTime(accesstoken);
            timeStamp = get(result, 'payload.data.timestamp', timeStamp);
        }
        catch (e) {
            console.error('Error getting server time', e);
        }
        const token = uuidv4();
        const expire = round((timeStamp + MINUTES_1 * 30) / 1000, 0);
        if (!props.publicEpod) {
            props.fetchImagekitSHAV2(accesstoken, acceptedFile.name, token, expire).then((result) => {
                if (!result.error) {
                    const signature = get(result, 'payload.data.signature');
                    const expireAPI = get(result, 'payload.data.expire');
                    const data = new FormData();
                    data.append('file', acceptedFile);
                    data.append('fileName', acceptedFile.name);
                    data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                    data.append('signature', signature);
                    data.append('token', token);
                    data.append('expire', expireAPI);
                    data.append('useUniqueFilename', 'true');
                    const accountId = get(loggedInUser, 'accountId', '') || props.localTransporterAccount || props.accountId;
                    data.append('folder', `clients/${accountId}/${props.folderPath}`);
                    props.setUploadLoading(true);
                    axios
                        .post(getImageKitUploadEndpoint(props.localTransporterAccount), data, config)
                        .then((res) => {
                        const updatedFileList = setFieldOnFile(acceptedFile, [
                            ['status', FILE_UPLOAD_STATUS.DONE],
                            [keyForImageUrl, res.data.url],
                            ['thumbUrl', res.data.thumbnailUrl],
                            ['filePath', res.data.filePath],
                        ]);
                        props.fileSuccessCallback(updatedFileList);
                        props.setUploadLoading(false);
                    })
                        .catch((err) => {
                        props.setUploadLoading(false);
                        const errorMsg = get(err, 'response.data.message', '');
                        const updatedFileList = setFieldOnFile(acceptedFile, [
                            ['status', FILE_UPLOAD_STATUS.ERROR],
                            ['error', errorMsg],
                        ]);
                        props.fileErrorCallback && props.fileErrorCallback(updatedFileList);
                    });
                }
            });
        }
        else {
            props.fetchImagekitSHAV2Public(acceptedFile.name, props.authToken || '', expire).then((result) => {
                if (!result.error) {
                    const signature = get(result, 'payload.data.signature');
                    const publicToken = get(result, 'payload.data.token');
                    const expireAPI = get(result, 'payload.data.expire');
                    const data = new FormData();
                    data.append('file', acceptedFile);
                    data.append('fileName', acceptedFile.name);
                    data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                    data.append('signature', signature);
                    data.append('token', publicToken);
                    data.append('expire', expireAPI);
                    data.append('useUniqueFilename', 'true');
                    data.append('folder', `clients${props.folderPath}`);
                    axios
                        .post('https://upload.imagekit.io/api/v1/files/upload', data, config)
                        .then((res) => {
                        const updatedFileList = setFieldOnFile(acceptedFile, [
                            ['status', FILE_UPLOAD_STATUS.DONE],
                            [keyForImageUrl, res.data.url],
                            ['filePath', res.data?.filePath],
                        ]);
                        props.fileSuccessCallback(updatedFileList);
                    })
                        .catch((err) => {
                        const errorMsg = get(err, 'response.data.message', '');
                        const updatedFileList = setFieldOnFile(acceptedFile, [
                            ['status', FILE_UPLOAD_STATUS.ERROR],
                            ['error', errorMsg],
                        ]);
                        props.fileErrorCallback && props.fileErrorCallback(updatedFileList);
                    });
                }
            });
        }
    };
    const onFileRemove = (file) => {
        const newFileList = filter(fileList, (f) => f.uid !== file.uid);
        setFileList(newFileList);
        props.fileDeleteCallback && props.fileDeleteCallback(newFileList, file);
    };
    const beforeUpload = (file) => {
        set(file, 'status', FILE_UPLOAD_STATUS.UPLOADING);
        set(file, [keyForImageTag], currentImageTagToUse);
        setFileList((PrevFileList) => {
            return [...PrevFileList, file];
        });
        onDrop(file);
        return false;
    };
    const onCloseRequest = () => {
        setIsDocViewerOpen(false);
    };
    const handleOnClick = () => {
        setIsDocViewerOpen(true);
    };
    const onPreview = (file) => {
        setPreviewFile(file);
        handleOnClick();
    };
    const uploadedImageLinks = [get(previewFile, keyForImageUrl, get(previewFile, 'url'))];
    const uploadProps = {
        onRemove: onFileRemove,
        name,
        beforeUpload,
        fileList,
        listType,
        multiple: true,
        disabled: onlyView,
        accept: getValidUploadFileTypes(accept),
        iconRender: iconRender(onPreview),
        isImageUrl: () => false,
        useDragger: !showOnlyButton,
        showUploadList: getShowUploadListConfig({ showOnlyButton, showRemoveIcon: !!props.fileDeleteCallback }),
        onPreview,
        progress: customProgressBarConfig(),
    };
    const isUploading = get(last(fileList), 'status') === FILE_UPLOAD_STATUS.UPLOADING;
    const isPictureCard = listType === UPLOAD_LIST_TYPE.PICTURE_CARD;
    return (_jsxs(MultiFileDropzoneWrapper, { style: props.style, className: props.className, children: [isDocViewerOpen && _jsx(LightBoxUtils, { images: uploadedImageLinks, onCloseRequest: onCloseRequest }), _jsxs(FxDropzone, { ...uploadProps, children: [showOnlyButton && !isPictureCard && (_jsx(FxButton, { type: buttonType, disabled: isUploading, loading: isUploading, icon: _jsx(FxUploadIcon, {}), children: buttonText })), !showOnlyButton && !isPictureCard && (_jsxs("div", { children: [!!iconMessage && _jsx(FxUploadDragIconWrapper, { children: iconMessage }), _jsx(FxUploadTextWrapper, { children: message }), hintMessage && _jsx(FxUploadHintWrapper, { children: hintMessage })] })), isPictureCard && (_jsxs("div", { children: [_jsx(FxPlusIcon, {}), _jsx("div", { style: { marginTop: 8 }, children: t('upload') })] }))] })] }));
};
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        loggedInUser: get(state, 'settings.loggedInUser'),
        localTransporterAccount: get(state, 'local.localTransporterAccount', null),
    };
}
export default connect(mapStateToProps, {
    fetchImagekitSHAV2,
    fetchImagekitSHAV2Public,
    getServerTime,
    setUploadLoading,
})(FxMultiFileDropzoneComponent);
