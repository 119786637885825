import { get, includes } from 'lodash';
export function isVehiclesHeaderVisible() {
    const roleId = get(window.FLEETX_LOGGED_IN_USER, 'role.id', '');
    return !includes([105397, 106662, 63812, 111689, 114792, 116203], roleId);
}
export function isReportsHeaderVisible() {
    const roleId = get(window.FLEETX_LOGGED_IN_USER, 'role.id', '');
    return !includes([105397], roleId);
}
export function isOverviewHeaderVisible() {
    const roleId = get(window.FLEETX_LOGGED_IN_USER, 'role.id', '');
    return !includes([105397, 91275, 106662], roleId);
}
export function isJobsHeaderVisible() {
    const roleId = get(window.FLEETX_LOGGED_IN_USER, 'role.id', '');
    return !includes([105397, 106662], roleId);
}
export function isFinanceHeaderVisible() {
    const roleId = get(window.FLEETX_LOGGED_IN_USER, 'role.id', '');
    return !includes([105397, 106662, 63812], roleId);
}
export function isFuelPageV3DefaultDirect() {
    return includes([12077, 13746], get(window.FLEETX_LOGGED_IN_USER, 'accountId', ''));
}
export function isAlarmsHeaderVisible() {
    const roleId = get(window.FLEETX_LOGGED_IN_USER, 'role.id', '');
    return !includes([91275], roleId);
}
