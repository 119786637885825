import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AssetsComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-assets" */ 'components/dashboard/assets/assets_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const PDAssetsComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-assets-pd" */ 'components/dashboard/assets/pd_assets_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ChallanComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-assets-challan" */ 'components/dashboard/assets/vehicle_challan_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const assetsRoutes = [
    {
        index: true,
        lazy: AssetsComponent,
    },
    {
        path: 'portable',
        lazy: PDAssetsComponent,
    },
    {
        path: 'challans',
        lazy: ChallanComponent,
    },
];
export default assetsRoutes;
