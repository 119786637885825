import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useReducer } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get, toSafeInteger, filter } from 'lodash';
import { isCreateAdvanceAllowedForUser, isViewBranchAllowedForUser } from '../roles/permission_utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CalendarComponent from '../../utils/calendar_component';
import { getMomentTime } from '../../../utils/date_utils';
import { filtersReducer, mappedOnlyConsignorsConsignees, mappedTransporters } from '../../../utils/form_utils';
import { stringifyUrlThroughState } from '../../../constant';
import { queryString } from '../../../utils/string_utils';
import { fetchBranches } from '../../../actions/dashboard/branch/actions';
import { fetchVehicleListMini } from '../../../actions/dashboard/vehicles_action';
import { FxButton, FxCustomSegment, FxDropdown, FxTab, FxTabsWrapper } from '../../../sharedV2';
import { FxDownloadIcon, FxPlusIcon } from 'sharedV2/FxIcons';
import { fetchRoutesMiniList } from '../../../actions/dashboard/route_dispatch/routes_action';
import { fetchAllTransporters } from '../../../actions/dashboard/finances/transporter_actions';
import MoreFiltersDrawerComponent from 'components/utils/more_filters/MoreFiltersDrawerComponent';
import MoreFiltersButtonComponent from 'components/utils/more_filters/MoreFiltersButtonComponent';
import PageLayoutComponent from 'components/utils/pageLayout';
import PaymentDashboardComponent from './PaymentDashboardComponent';
import PaymentFiltersComponent from './PaymentFiltersComponent';
import { fetchCustomers } from '../../../actions/dashboard/finances/customer_action';
import { reportDownloadMessages, reportDownloadStatus } from 'utils/ui_report_utils';
import ReportSuccessModalComponent from 'components/dashboard/reports/ReportSuccessModalComponent';
import { fetchPaymentReceiptReport } from 'actions/dashboard/payments/actions';
import { PAYMENTS_TABS, PAYMENTS_SUB_TABS, ADJUSTMENT_NOTES_SUB_TABS, tabOptions } from './paymentUtils';
import { COLOR_WHITE } from '../../../theme/colors';
const TABS = {
    collectionDashboard: 'collectionDashboard',
    paymentsDashboard: 'paymentsDashboard',
};
const initialFiltersState = {
    startDate: getMomentTime().startOf('month'),
    endDate: getMomentTime(),
    branchId: null,
    paymentNature: null,
    adviceNumber: '',
    voucherNumber: '',
    creditAccountId: null,
};
const PaymentsComponent = (props) => {
    const { accesstoken, login, groupId, miniVehicleList, loggedInUser, transporters, customers } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [activeTab, setActiveTab] = useState(PAYMENTS_TABS.PAYMENTS);
    const [activeSubTab, setActiveSubTab] = useState(PAYMENTS_SUB_TABS.UNSETTLED);
    const [filters, dispatchFilters] = useReducer(filtersReducer, initialFiltersState, (state) => {
        const parsed = queryString.parse(location?.hash);
        const parsedFilters = {};
        if (parsed.startDate) {
            parsedFilters.startDate = getMomentTime(parsed.startDate);
        }
        if (parsed.endDate) {
            parsedFilters.endDate = getMomentTime(parsed.endDate);
        }
        if (parsed.currentPage) {
            parsedFilters.currentPage = toSafeInteger(parsed.currentPage);
        }
        return { ...state, ...parsedFilters };
    });
    const [openFilters, setOpenFilters] = useState(false);
    const [allBranches, setAllBranches] = useState([]);
    const [modalData, setModalData] = React.useState({
        isReportModalOpen: false,
        reportMessage: {
            text: '',
            link: '',
            status: '',
        },
    });
    const scope = login.scope;
    const { startDate, endDate, currentPage, sizePerPage, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, } = filters;
    useEffect(() => {
        if (isViewBranchAllowedForUser(props.login.scope)) {
            props.fetchBranches(accesstoken).then((res) => {
                setAllBranches(get(res, 'payload.data'));
            });
        }
        props.fetchCustomers(accesstoken);
        props.fetchAllTransporters(accesstoken);
    }, []);
    const onPageChange = (page, sizePerPage) => {
        dispatchFilters({
            payload: {
                sizePerPage,
                currentPage: page,
            },
        });
    };
    useEffect(() => {
        stringifyUrlThroughState({
            activeTab,
            activeSubTab,
            startDate,
            endDate,
            currentPage,
        });
    }, [activeTab, activeSubTab, startDate.valueOf(), endDate.valueOf(), currentPage]);
    const handleFilterChange = (key, value) => {
        const newFilersState = {};
        if (key === 'timeFilter') {
            newFilersState.startDate = get(value, '[0]', null);
            newFilersState.endDate = get(value, '[1]', null);
        }
        else {
            newFilersState[key] = value;
        }
        dispatchFilters({ payload: newFilersState });
    };
    const redirectToReports = (result, filter) => {
        if (!get(result, 'error')) {
            setModalData({
                isReportModalOpen: true,
                reportMessage: {
                    text: reportDownloadMessages.SUCCESS,
                    link: `/dashboard/reports/download/#reportDownloadType=${filter}`,
                    status: reportDownloadStatus.SUCCESS,
                },
            });
        }
        else {
            setModalData({
                isReportModalOpen: true,
                reportMessage: {
                    text: reportDownloadMessages.FAILED,
                    link: '',
                    status: reportDownloadStatus.FAILED,
                },
            });
        }
    };
    const onDownloadPaymentReceiptReport = () => {
        fetchPaymentReceiptReport({
            from: startDate.valueOf(),
            to: endDate.valueOf(),
            customerId: creditAccountId,
        }).then((result) => redirectToReports(result, 'PAYMENT_RECEIPT_REPORT'));
    };
    const { t } = useTranslation();
    const onTabChange = (key) => {
        if (key === PAYMENTS_TABS.PAYMENTS) {
            setActiveTab(key);
            setActiveSubTab(PAYMENTS_SUB_TABS.UNSETTLED); // setting default sub-tab for Payments
        }
        else if (key === PAYMENTS_TABS.ADJUSTMENT_NOTES) {
            setActiveTab(key);
            setActiveSubTab(ADJUSTMENT_NOTES_SUB_TABS.CREDIT_NOTE); // setting default sub-tab for Adjustment Notes
        }
        else if (Object.values(PAYMENTS_SUB_TABS).includes(key) ||
            Object.values(ADJUSTMENT_NOTES_SUB_TABS).includes(key)) {
            setActiveSubTab(key); // Handle sub-tab selection
        }
        else {
            setActiveTab(key);
            setActiveSubTab(null); // Clear sub-tab if another tab is selected
        }
    };
    const items = [
        isCreateAdvanceAllowedForUser(scope) && {
            key: '1',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/driver/add');
                }, className: "whitespace-normal", children: "Driver Advance" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '2',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/cn-advance/add');
                }, className: "whitespace-normal", children: "CN Advance" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '3',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/payment-receipt/add');
                }, className: "whitespace-normal", children: "Payment Receipt" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '4',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/payment-receipt-job/add');
                }, className: "whitespace-normal", children: "Job Payment Receipt" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '5',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/balance-payment/add');
                }, className: "whitespace-normal", children: "Balance Payment" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '6',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/account/add');
                }, className: "whitespace-normal", children: "On Account Form" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '7',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/bulk-payment/add');
                }, className: "whitespace-normal", children: "Add Bulk Payment" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '8',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/bulk-adjustment/add');
                }, className: "whitespace-normal", children: "Add Bulk Adjustment" })),
        },
    ].filter(Boolean);
    return (_jsxs(PageLayoutComponent, { pageIcon: null, pageLabel: t('payments'), tabTitle: t('payments'), tabs: _jsxs(_Fragment, { children: [_jsx(FxTabsWrapper, { children: _jsx(FxTab, { activeKey: activeTab, onChange: onTabChange, items: tabOptions }) }), (activeTab === PAYMENTS_TABS.PAYMENTS || activeTab === PAYMENTS_TABS.ADJUSTMENT_NOTES) &&
                    activeSubTab && (_jsx(FxCustomSegment, { value: activeSubTab, options: tabOptions
                        .find((tab) => tab.key === activeTab)
                        ?.subTabs?.map((subTab) => ({
                        ...subTab,
                        value: subTab.key,
                        label: subTab.label,
                    })), size: 'middle', onSelect: (value) => {
                        onTabChange(value);
                    }, style: { backgroundColor: COLOR_WHITE, marginTop: '1rem' }, className: "mt-4" }))] }), children: [_jsxs("div", { children: [_jsx(FxButton, { type: "primary", icon: _jsx(FxDownloadIcon, {}), onClick: () => onDownloadPaymentReceiptReport(), children: "Payment Receipt" }), _jsx(MoreFiltersButtonComponent, { onClick: () => setOpenFilters(!openFilters) }), _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                            handleFilterChange('timeFilter', [startDate, endDate]);
                        }, startDate: startDate, endDate: endDate, onAntdPage: true, withFinancialYear: true }), _jsx(FxDropdown, { menu: { items }, trigger: ['click'], children: _jsx(FxButton, { onClick: (e) => e.stopPropagation(), icon: _jsx(FxPlusIcon, {}) }) })] }), _jsxs("div", { children: [_jsx(PaymentDashboardComponent, { startDate: startDate, endDate: endDate, branchId: branchId, paymentNature: paymentNature, adviceNumber: adviceNumber, creditAccountId: creditAccountId, currentPage: currentPage, sizePerPage: sizePerPage, handleFilterChange: handleFilterChange, onPageChange: onPageChange, customers: customers, voucherNumber: voucherNumber, allBranches: allBranches, transporters: transporters, activeTab: activeTab, activeSubTab: activeSubTab }), _jsx(MoreFiltersDrawerComponent, { onClose: () => setOpenFilters(!openFilters), open: openFilters, children: _jsx(PaymentFiltersComponent, { handleFilterChange: handleFilterChange, branches: allBranches, loggedInUser: loggedInUser, branchId: branchId, paymentNature: paymentNature, creditAccountId: creditAccountId, allCustomers: [
                                ...mappedTransporters(filter(props.transporters, (tp) => !!tp.ledgerId)),
                                ...mappedOnlyConsignorsConsignees(filter(customers, (el) => el.ledgerId)),
                            ] }) }), _jsx(ReportSuccessModalComponent, { isOpen: modalData.isReportModalOpen, message: modalData.reportMessage, toggle: () => setModalData({
                            isReportModalOpen: false,
                            reportMessage: {},
                        }) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchBranches,
        fetchVehicleListMini,
        fetchRoutesMiniList,
        fetchAllTransporters,
        fetchCustomers,
        fetchPaymentReceiptReport,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        loggedInUser: state.settings.loggedInUser,
        groupId: state.local.defaultGroupId,
        miniVehicleList: get(state, 'vehicles.vehicleListMini', []),
        routeList: state.routes.routeListMiniNew,
        transporters: state.transporters.transporterList,
        customers: get(state, 'customers.customers', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsComponent);
