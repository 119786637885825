import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SaveToCloudInProgress = (props) => {
    return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_597_33820", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "24", height: "24", children: _jsx("rect", { width: "24", height: "24", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_597_33820)", children: _jsx("path", { d: "M13.702 19.5H6.5C5.118 19.5 3.93917 19.0206 2.9635 18.0617C1.98783 17.1029 1.5 15.9311 1.5 14.5463C1.5 13.3039 1.89967 12.2113 2.699 11.2683C3.49833 10.3253 4.48967 9.76658 5.673 9.59225C5.9935 8.09742 6.74517 6.875 7.928 5.925C9.11067 4.975 10.468 4.5 12 4.5C13.6 4.5 14.9948 5.00517 16.1845 6.0155C17.3743 7.02567 18.1052 8.29292 18.377 9.81725C18.1167 9.82375 17.8657 9.85483 17.624 9.9105C17.3823 9.96633 17.1468 10.0417 16.9173 10.1365C16.7263 8.9635 16.167 7.98083 15.2395 7.1885C14.3118 6.39617 13.232 6 12 6C10.6167 6 9.4375 6.4875 8.4625 7.4625C7.4875 8.4375 7 9.61667 7 11H6.48075C5.53325 11 4.71625 11.3417 4.02975 12.025C3.34325 12.7083 3 13.5333 3 14.5C3 15.4667 3.34167 16.2917 4.025 16.975C4.70833 17.6583 5.53333 18 6.5 18H13.702V19.5ZM16.798 19.5C16.542 19.5 16.3273 19.4133 16.154 19.24C15.9808 19.0668 15.8943 18.8522 15.8943 18.596V15.7402C15.8943 15.4826 15.9868 15.2692 16.172 15.1C16.3573 14.9308 16.5821 14.8463 16.8462 14.8463V13.8463C16.8462 13.3359 17.0281 12.899 17.3917 12.5355C17.7554 12.1722 18.1926 11.9905 18.7033 11.9905C19.2139 11.9905 19.649 12.1722 20.0085 12.5355C20.3682 12.899 20.548 13.3359 20.548 13.8463V14.8463C20.8122 14.8463 21.0368 14.9308 21.222 15.1C21.4073 15.2692 21.5 15.4826 21.5 15.7402V18.596C21.5 18.8522 21.4133 19.0668 21.24 19.24C21.0668 19.4133 20.8522 19.5 20.596 19.5H16.798ZM17.7307 14.8463H19.6635V13.8463C19.6635 13.5629 19.5741 13.3302 19.3953 13.148C19.2164 12.966 18.9853 12.875 18.702 12.875C18.4187 12.875 18.1859 12.966 18.0038 13.148C17.8218 13.3302 17.7307 13.5629 17.7307 13.8463V14.8463Z", fill: "#605E5C" }) })] }));
};
const SaveToCloudInProgressIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SaveToCloudInProgress, ...props });
};
export default SaveToCloudInProgressIcon;
