import { getRadioNameForInternational, isMaerskAccount, FLEETX_ACCOUNTS } from './account_utils';
import { get, includes, map } from 'lodash';
import { getFormattedTimeStringForAPI, getMomentTime } from 'utils/date_utils';
export function getMovementTypesOptions(accountId, billingEntityToMovementTypeMapping) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.SRAVAN_SHIPPING:
            return [
                {
                    label: 'Domestic',
                    value: 'Domestic',
                },
                {
                    label: getRadioNameForInternational(),
                    value: 'International',
                },
                {
                    label: 'Import',
                    value: 'Import',
                },
                {
                    label: 'Export',
                    value: 'Export',
                },
                {
                    label: 'Empty',
                    value: 'Empty',
                },
                {
                    label: 'Direct Port Entry',
                    value: 'Direct Port Entry',
                },
            ];
        case FLEETX_ACCOUNTS.SATVA_HITECH:
            return [
                {
                    label: 'Domestic',
                    value: 'Domestic',
                },
                {
                    label: getRadioNameForInternational(),
                    value: 'International',
                },
                {
                    label: 'Import',
                    value: 'Import',
                },
                {
                    label: 'Export',
                    value: 'Export',
                },
                {
                    label: 'PNR',
                    value: 'PNR',
                },
                {
                    label: 'VESSEL EMPTY',
                    value: 'VESSEL EMPTY',
                },
                {
                    label: 'PLOT',
                    value: 'PLOT',
                },
                {
                    label: 'EMPTY TRAILER',
                    value: 'EMPTY TRAILER',
                },
                {
                    label: 'EXPORT EMPTY',
                    value: 'EXPORT EMPTY',
                },
                {
                    label: 'EXPORT LOAD',
                    value: 'EXPORT LOAD',
                },
                {
                    label: 'BANGALORE PNR',
                    value: 'BANGALORE PNR',
                },
                {
                    label: 'BANGALORE EXPORT EMPTY VIA CHITHOOR',
                    value: 'BANGALORE EXPORT EMPTY VIA CHITHOOR',
                },
                {
                    label: 'BANGALORE EXPORT EMPTY VIA HOSUR',
                    value: 'BANGALORE EXPORT EMPTY VIA HOSUR',
                },
                {
                    label: 'BANGALORE EXPORT LOAD VIA CHITHOOR',
                    value: 'BANGALORE EXPORT LOAD VIA CHITHOOR',
                },
                {
                    label: 'BANGALORE EXPORT LOAD VIA HOSUR',
                    value: 'BANGALORE EXPORT LOAD VIA HOSUR',
                },
                {
                    label: 'BONDING',
                    value: 'BONDING',
                },
                {
                    label: 'INTERGARD',
                    value: 'INTERGARD',
                },
                {
                    label: 'LCL -CARGO VIA CHITHOOR',
                    value: 'LCL -CARGO VIA CHITHOOR',
                },
                {
                    label: 'LCL -CARGO VIA HOSUR',
                    value: 'LCL -CARGO VIA HOSUR',
                },
                {
                    label: 'LUCAS',
                    value: 'LUCAS',
                },
            ];
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            let movementTypes = [
                {
                    label: 'Domestic',
                    value: 'Domestic',
                },
                {
                    label: getRadioNameForInternational(),
                    value: 'International',
                },
                {
                    label: 'Import',
                    value: 'Import',
                },
                {
                    label: 'Export',
                    value: 'Export',
                },
                {
                    label: 'Empty',
                    value: 'Empty',
                },
                {
                    label: 'Shunt',
                    value: 'shunt',
                },
                {
                    value: 'SD',
                    label: 'SD',
                },
                {
                    value: 'SD-4PL',
                    label: 'SD-4PL',
                },
                {
                    value: 'SD-SCM',
                    label: 'SD-SCM',
                },
                {
                    value: 'SD-Reefer',
                    label: 'SD-Reefer',
                },
                {
                    value: 'SCM FTL',
                    label: 'SCM FTL',
                },
                {
                    value: '4PL FTL',
                    label: '4PL FTL',
                },
                {
                    value: 'ODC',
                    label: 'ODC',
                },
                {
                    value: 'LCL FTL',
                    label: 'LCL FTL',
                },
                {
                    value: 'Air FTL',
                    label: 'Air FTL',
                },
                {
                    value: 'FTL Domestic',
                    label: 'FTL Domestic',
                },
                {
                    value: 'Domestic Reefer',
                    label: 'Domestic Reefer',
                },
                {
                    value: 'Domestic PTL',
                    label: 'Domestic PTL',
                },
                {
                    value: 'Domestic Milk run',
                    label: 'Domestic Milk run',
                },
                {
                    value: 'Domestic EV Milk run',
                    label: 'Domestic EV Milk run',
                },
                {
                    value: 'PTL Pickup',
                    label: 'PTL Pickup',
                },
                {
                    value: 'EV FTL',
                    label: 'EV FTL',
                },
                {
                    value: 'EV MILK RUN',
                    label: 'EV MILK RUN',
                },
                {
                    value: 'CFS',
                    label: 'CFS',
                },
                {
                    value: 'Dry Run',
                    label: 'Dry Run',
                },
            ];
            if (get(billingEntityToMovementTypeMapping, 'length')) {
                movementTypes = [
                    ...movementTypes,
                    ...map(billingEntityToMovementTypeMapping, (el) => {
                        return { value: el, label: el };
                    }),
                ];
            }
            return movementTypes;
        default:
            return [
                {
                    label: 'Domestic',
                    value: 'Domestic',
                },
                {
                    label: getRadioNameForInternational(),
                    value: 'International',
                },
                {
                    label: 'Import',
                    value: 'Import',
                },
                {
                    label: 'Export',
                    value: 'Export',
                },
                {
                    label: 'Empty',
                    value: 'Empty',
                },
            ];
    }
}
export const BILLING_ENTITIES = [
    {
        value: 'MAERSK',
        label: 'MAERSK',
    },
    {
        value: 'APMT',
        label: 'APMT',
    },
    {
        value: 'Hamburg_Sud',
        label: 'Hamburg Sud',
    },
    {
        value: 'LF_Logistics',
        label: 'LF Logistics',
    },
];
export const SHIPPING_LINES = [
    {
        label: 'MAERSK',
        value: 'MAERSK',
    },
    {
        label: 'MSC',
        value: 'MSC',
    },
    {
        label: 'HAPAG LLOYD',
        value: 'HAPAG LLOYD',
    },
    {
        label: 'CMA CGM',
        value: 'CMA CGM',
    },
    {
        label: 'HMM',
        value: 'HMM',
    },
    {
        label: 'WAN HAI',
        value: 'WAN HAI',
    },
    {
        label: 'EVERGREEN',
        value: 'EVERGREEN',
    },
    {
        label: 'COSCO',
        value: 'COSCO',
    },
    {
        label: 'ONE LINE',
        value: 'ONE LINE',
    },
    {
        label: 'YANG MING',
        value: 'YANG MING',
    },
    {
        label: 'OOCL',
        value: 'OOCL',
    },
    {
        label: 'ZIM LINE',
        value: 'ZIM LINE',
    },
    {
        label: 'KMTC',
        value: 'KMTC',
    },
    {
        label: 'RCL',
        value: 'RCL',
    },
    {
        label: 'GOOD RICH',
        value: 'GOOD RICH',
    },
    {
        label: 'INTERASIA',
        value: 'INTERASIA',
    },
    {
        label: 'ANL',
        value: 'ANL',
    },
    {
        label: 'APL',
        value: 'APL',
    },
    {
        label: 'HAMBURG SUD',
        value: 'HAMBURG SUD',
    },
    {
        label: 'PIL',
        value: 'PIL',
    },
    {
        label: 'AIYER',
        value: 'AIYER',
    },
    {
        label: 'JJ Liner',
        value: 'JJ Liner',
    },
    {
        label: 'T.S Liner',
        value: 'T.S Liner',
    },
    {
        label: 'RADIANT Liner',
        value: 'RADIANT Liner',
    },
    {
        label: 'CULINES',
        value: 'CULINES',
    },
    {
        label: 'HEUNG A CONTAINER LINES, KOREA',
        value: 'HEUNG A CONTAINER LINES, KOREA',
    },
    {
        label: 'BEN LINE',
        value: 'BEN LINE',
    },
    {
        label: 'SMART MARINE LINE',
        value: 'SMART MARINE LINE',
    },
    {
        label: 'HUB & LINKS LOGISTICS INDIA PVT LTD',
        value: 'HUB & LINKS LOGISTICS INDIA PVT LTD',
    },
    {
        label: 'ELS',
        value: 'ELS',
    },
    {
        label: 'OTHERS',
        value: 'OTHERS',
    },
];
export const CARGO_TYPES = [
    { value: 'Dry', label: 'Dry' },
    { value: 'Reefer', label: 'Reefer' },
    // { value: 'CCL', label: 'Reefer' },
    { value: 'Haz', label: 'Haz' },
    { value: 'Special', label: 'Special' },
    { value: 'CBT', label: 'CBT' },
    { value: 'Container', label: 'Container' },
];
export function getBillingEntityToProductTypeMapping(billingEntity) {
    if (isMaerskAccount()) {
        return get({
            MAERSK: ['MAERSK SD', 'MAERSK EMPTY', 'MAERSK SHUNT', 'MAERSK CFS'],
            APMT: [
                'APMT STANDALONE',
                'APMT DEPOT',
                'MPL',
                'APMT SD',
                'APMT SCM',
                'APMT DISTRIBUTION',
                'APMT LCL',
                'APMT AIR',
                'APMT ECOM',
                'APMT EMPTY',
            ],
            'Hamburg_Sud': ['HAMBUG SUD SD'],
            'LF_Logistics': ['LF Logistics'],
        }, billingEntity, []);
    }
    else {
        return null;
    }
}
export function getBillingEntityToMovementTypeMapping(billingEntity) {
    if (isMaerskAccount()) {
        return get({
            APMT: ['MPL Domestic'],
        }, billingEntity, []);
    }
    else {
        return null;
    }
}
export const CONTAINER_SIZE = {
    '20ft': '20ft',
    '40ft': '40ft',
    '20GP': '20GP',
    '20OT': '20OT',
    '20FR': '20FR',
    '20REF': '20REF',
    '40GP': '40GP',
    '40OT': '40OT',
    '40FR': '40FR',
    '40HQ': '40HQ',
    '40REF': '40REF',
    '45FT': '45FT',
    '20TK': '20TK',
    '40TK': '40TK',
};
export const JOB_BOOKING_TAB = {
    JOBS: 'Jobs',
    INDENTS: 'Indents',
    AUCTIONS: 'Auctions',
};
export const INDENT_TAB = {
    JOBS: 'Jobs',
    JOB_BOOKING: 'Job Booking',
    AUCTIONS: 'Auctions',
    DISPATCH: 'Dispatch',
    ORDER: 'Order',
};
export const reasonType = [
    { label: 'INDENT', value: 'INDENT' },
    { label: 'JOB', value: 'JOB' },
    { label: 'BOOKING', value: 'BOOKING' },
    { label: 'CONSIGNMENT', value: 'CONSIGNMENT' },
    { label: 'FREIGHT BILL', value: 'FREIGHTBILL' },
    { label: 'DISPATCH PLAN', value: 'DISPATCHPLAN' },
    { label: 'BOOKING TRANSPORTER', value: 'BOOKINGTRANSPORTER' },
];
export const CONTAINER_WEIGHT = [
    { label: '0-10(Tons)', value: '0-10(Tons)' },
    { label: '10.1-15(Tons)', value: '10.1-15(Tons)' },
    { label: 'upto 28 Tons', value: 'upto 28 Tons' },
    { label: 'more than 28 Tons', value: 'more than 28 Tons' },
];
export function getRouteLoadingUnloadingPointLabels() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    switch (accountId) {
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
        case FLEETX_ACCOUNTS.STRIKE:
            return {
                loadingText: 'Starting Point',
                unloadingText: 'End Point',
            };
        default:
            return {
                loadingText: 'Loading Point',
                unloadingText: 'Unloading Point',
            };
    }
}
export function addCustomerFieldsConfigInModal() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    return {
        gstNumber: {
            field: 'gstNumber',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        phoneNumber: {
            field: 'phoneNumber',
            show: true,
            mandatory: includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        panNumber: {
            field: 'panNumber',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        type: {
            field: 'type',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        code: {
            field: 'code',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        businessVertical: {
            field: 'businessVertical.id',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        customFields: {
            field: 'customFields',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        rates: {
            field: 'rates',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
    };
}
export function getInitialValuesForCustomerForm() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    switch (accountId) {
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
        case FLEETX_ACCOUNTS.STRIKE:
            return {
                state: 'Goa',
                pincode: '403001',
            };
        default:
            return {};
    }
}
export function getInitialValuesForJobBookingFormForm(isTemplate = false) {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    const initialValues = {};
    switch (accountId) {
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
        case FLEETX_ACCOUNTS.STRIKE:
            if (!isTemplate) {
                initialValues.bookingDate = getFormattedTimeStringForAPI(getMomentTime());
            }
            break;
        default:
    }
    return initialValues;
}
export function getJobBookingFormVehiclesSizesDefaultEntry() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    switch (accountId) {
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
        case FLEETX_ACCOUNTS.STRIKE:
            return {
                containerQuantity: 1,
            };
        default:
            return {};
    }
}
export const maskNameAndPhoneNumber = () => {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    return includes([114792, 116203], get(loggedInUser, 'role.id'));
};
