import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { filter, get, omit, set } from 'lodash';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import OnAccountFormFields from './OnAccountFormFields';
import { PAYMENT_NATURES, PAYMENT_NATURES_OPTIONS } from './account_form_utils';
import { DATE_FORMAT_TIMESTAMP, getFormattedTimeStringForAPI, getMomentTime } from 'utils/date_utils';
import { fetchAllTransporters } from 'actions/dashboard/finances/transporter_actions';
import { fetchCustomers } from 'actions/dashboard/finances/customer_action';
import { mappedCustomers, mappedTransporters } from 'utils/form_utils';
import { handleError } from 'constant';
import { onAccountCreatePayment, onAccountCreateReceipt, onAccountUpdate, fetchPaymentById, deletePayment, } from 'actions/dashboard/payment_forms/actions';
import { fetchVehicleListMini } from 'actions/dashboard/vehicles_action';
import { mappedVehicleListMiniOptions } from 'components/utils/mapping_utils';
import { showRemovedVehiclesForAccount } from 'utils/account_utils';
import { fetchAccountConfigList } from 'actions/dashboard/config_actions';
const AddEditOnAccountFormComponent = (props) => {
    const { accesstoken, loggedInUser, vehicleListMini } = props;
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const editId = params.id;
    const [transporters, setTransporters] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [accountConfigData, setAccountConfigData] = useState([]);
    const [initialValues, setInitialValues] = useState({
        paymentNature: null,
        voucherDate: getMomentTime(),
        adviceNumber: '',
        branch: null,
        stationaryId: null,
        stationaryNumber: '',
        bookType: null,
        adviceAmount: '',
        narration: '',
        creditAccountId: null,
        debitLedger: {
            ledgerId: null,
            amount: '',
        },
    });
    useEffect(() => {
        props.fetchAllTransporters(accesstoken).then((res) => {
            if (!res.error)
                setTransporters(res.payload.data);
        });
        props.fetchCustomers(accesstoken).then((res) => {
            if (!res.error)
                setCustomers(get(res, 'payload.data.content'));
        });
        props.fetchVehicleListMini(accesstoken, showRemovedVehiclesForAccount());
        getAccountConfigList();
    }, []);
    useEffect(() => {
        if (editId) {
            props.fetchPaymentById(accesstoken, editId).then((res) => {
                if (!res.error) {
                    let d = get(res, 'payload.data', {});
                    d.bookType = get(d, 'paymentNature', null);
                    if (d.voucherStationaryId) {
                        set(d, 'stationaryId', d.voucherStationaryId);
                        d = omit(d, 'voucherStationaryId');
                        set(d, 'stationaryNumber', d.voucherNumber);
                        set(d, 'adviceNumber', d.voucherNumber);
                        d = omit(d, 'voucherNumber');
                    }
                    setInitialValues(d);
                }
            });
        }
    }, [editId]);
    const options = {
        paymentNautreOptions: useMemo(() => PAYMENT_NATURES_OPTIONS, []),
        transporters: useMemo(() => mappedTransporters(transporters), [transporters]),
        customers: useMemo(() => mappedCustomers(customers), [customers]),
        vehicles: useMemo(() => mappedVehicleListMiniOptions(filter(vehicleListMini, (v) => get(v, 'vehicleOwnership') !== 'OWNED'), loggedInUser, true), [vehicleListMini]),
    };
    const addEditSchema = Yup.object().shape({
        paymentNature: Yup.string().required('Voucher Type Required').nullable(),
        branch: Yup.string().required('Office Required').nullable(),
        stationaryId: Yup.string().required('Stationary Required').nullable(),
        stationaryNumber: Yup.string().required('Voucher Number Required'),
        adviceNumber: Yup.string().required('Advice Number Required.').nullable(),
        adviceAmount: Yup.string().required('Advice Amount Required.').nullable(),
        voucherDate: Yup.string().required('Voucher Date Required').nullable(),
        creditAccountId: Yup.string().required('Required').nullable(),
        debitLedger: Yup.object().shape({
            ledgerId: Yup.string().required('Ledger Required').nullable(),
            amount: Yup.number().min(0, 'Amount should be greater than 0').required('Amount Required').nullable(),
        }),
    });
    const goBack = () => {
        navigate(-1);
    };
    const getAccountConfigList = async () => {
        const result = await props.fetchAccountConfigList(accesstoken);
        if (!result.error) {
            let data = get(result, 'payload.data');
            setAccountConfigData(data);
        }
    };
    const onDelete = (id) => {
        props
            .deletePayment(accesstoken, id)
            .then((result) => {
            if (result.error) {
                throw new Error(handleError(result.payload.response));
            }
            else {
                goBack();
            }
        })
            .catch((err) => {
            alert(handleError(err));
        });
    };
    const onSubmit = (values, { setSubmitting }) => {
        let data = { ...values };
        const voucherNumber = get(data, 'stationaryNumber');
        const voucherStationaryId = get(data, 'stationaryId');
        const branchId = get(data, 'branch');
        set(data, 'voucherNumber', voucherNumber);
        set(data, 'branchId', branchId);
        set(data, 'voucherStationaryId', voucherStationaryId);
        data = omit(data, 'stationaryNumber');
        data = omit(data, 'stationaryId');
        data = omit(data, 'branch');
        data = omit(data, 'bookType');
        if (data.voucherDate) {
            set(data, 'voucherDate', getFormattedTimeStringForAPI(getMomentTime(get(data, 'voucherDate')), DATE_FORMAT_TIMESTAMP));
        }
        if (editId) {
            props.onAccountUpdate(accesstoken, data).then((result) => {
                if (!result.error) {
                    setSubmitting(false);
                    alert('Updated successfully!');
                    goBack();
                }
                else {
                    setSubmitting(false);
                    throw new Error(handleError(result.payload.response));
                }
            });
        }
        else {
            if (data.paymentNature === PAYMENT_NATURES.ON_ACCOUNT_PAYMENT) {
                props.onAccountCreatePayment(accesstoken, data).then((result) => {
                    if (!result.error) {
                        setSubmitting(false);
                        alert('Created successfully!');
                        goBack();
                    }
                    else {
                        setSubmitting(false);
                        throw new Error(handleError(result.payload.response));
                    }
                });
            }
            else {
                props.onAccountCreateReceipt(accesstoken, data).then((result) => {
                    if (!result.error) {
                        setSubmitting(false);
                        alert('Created successfully!');
                        goBack();
                    }
                    else {
                        setSubmitting(false);
                        throw new Error(handleError(result.payload.response));
                    }
                });
            }
        }
    };
    return (_jsx("div", { className: "card-group mt-4 mb-0", children: _jsx("div", { className: "card p-1", children: _jsxs("div", { className: "card-block", children: [_jsx(Helmet, { title: `${editId ? 'Edit' : 'Add'} On Account - fleetx.io` }), _jsxs("h4", { children: [editId ? 'Edit' : 'Add', " On Account "] }), _jsx("hr", {}), _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validate: (value) => {
                            try {
                                validateYupSchema(value, addEditSchema, true, value);
                            }
                            catch (err) {
                                return yupToFormErrors(err); //for rendering validation errors
                            }
                            return {};
                        }, enableReinitialize: true, children: (formikProps) => {
                            const { isSubmitting, values, errors } = formikProps;
                            return (_jsx(OnAccountFormFields, { options: options, formikProps: formikProps, editId: editId, navigate: navigate, onDelete: onDelete, accountConfigData: accountConfigData, customers: customers }));
                        } })] }) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCustomers,
        fetchAllTransporters,
        onAccountCreatePayment,
        onAccountCreateReceipt,
        fetchPaymentById,
        onAccountUpdate,
        deletePayment,
        fetchVehicleListMini,
        fetchAccountConfigList,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        vehicleListMini: get(state, 'vehicles.vehicleListMini', []),
        loggedInUser: get(state.settings, 'loggedInUser'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditOnAccountFormComponent);
