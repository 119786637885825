import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { fetchCurrencyCodes } from 'actions/dashboard/accounts_actions';
import { fetchUsersByType } from 'actions/dashboard/users_action';
import { Field, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { concat, filter, find, forEach, forOwn, get, groupBy, includes, isEmpty, keyBy, map, omit, set, sortBy, sumBy, toSafeInteger, uniq, uniqBy, unset, values, } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { isDriverWiseLedgerEntry, isInternationalAccount, showEPODCompletedCNInPaymentReceipt, showRemovedVehiclesForAccount, } from 'utils/account_utils';
import { CONSIGNMENT_STATUS } from 'utils/consignmentsUtils';
import { JOB_STATUS_VALUES } from 'utils/job_route_utils';
import * as Yup from 'yup';
import { fetchBranches } from '../../../../actions/dashboard/branch/actions';
import { fetchConsignments } from '../../../../actions/dashboard/consignments/consignmentsActions';
import { fetchDivisions } from '../../../../actions/dashboard/consignments/divisionActions';
import { fetchFreightBills } from '../../../../actions/dashboard/consignments/freightbill/freightBillActions';
import { fetchLedgersGroups } from '../../../../actions/dashboard/consignments/ledgerGroupActions';
import { fetchLedgers } from '../../../../actions/dashboard/consignments/ledgersActions';
import { fetchCustomers } from '../../../../actions/dashboard/finances/customer_action';
import { fetchAllTransporters } from '../../../../actions/dashboard/finances/transporter_actions';
import { fetchTemplateByFormType } from '../../../../actions/dashboard/form_templates_action';
import { fetchHireSlips } from '../../../../actions/dashboard/hire_slip/hireSlipActions';
import { createAdvance, createPayment, deleteAdvance, deletePayment, fetchAdvance, 
// new endpoints
fetchPaymentById, fetchPayments, updateAdvance, updatePayment, } from '../../../../actions/dashboard/payment_forms/actions';
import { fetchRoutesMiniList } from '../../../../actions/dashboard/route_dispatch/routes_action';
import { fetchVehicleListMini } from '../../../../actions/dashboard/vehicles_action';
import { handleError } from '../../../../constant';
import { FxSelectField } from '../../../../shared';
import { DATE_FORMAT_TIMESTAMP, getEndDate, getFormattedTimeStringForAPI, getMomentTime, } from '../../../../utils/date_utils';
import { ERP_PROFILE_FORM_TYPES } from '../../../../utils/ERPProfileUtils';
import { filtersReducer } from '../../../../utils/form_utils';
import { queryString } from '../../../../utils/string_utils';
import Loader from '../../../utils/loader';
import { mappedOptionsFromArray } from '../../../utils/mapping_utils';
import BillNumberComponentDynamic from '../../bill_number/bill_number_component_dynamic';
import { isDeleteAdvanceAllowedForUser, isViewBranchAllowedForUser, isViewUsersAllowedForUser, } from '../../roles/permission_utils';
import ConsignmentsListingComponent from '../consignments/ConsignmentsListingComponent';
import ConsignmentsMappingFilterComponent from '../consignments/ConsignmentsMappingFilterComponent';
import FreightBillListingComponent from '../consignments/FreightBillListingComponent';
import DeductionComponent from '../DeductionComponant';
import DeductionFilterComponent from '../DeductionFilterComponent';
import HireSlipListingComponent from '../hire_slip/HireSlipListingComponent';
import HireSlipMappingFilterComponent from '../hire_slip/HireSlipMappingFilterComponent';
import GenericERPTableListingComponent from '../TableListingHOC/GenericERPTableListing';
import BasicInformationComponent from './BasicInformationComponent';
import RecoveryComponent from './RecoveryComponent';
import { fetchJobListNewV2 } from 'actions/dashboard/route_dispatch/jobs_action';
import FxModal from 'sharedV2/FxModal';
import AddEditEPODComponent from 'components/dashboard/epodForm/forms/AddEditEPODComponent';
import { PAYMENT_NATURES } from './account_form_utils';
import { useFxAlert } from 'components/dashboard/customHooks';
import JobsListingComponentV2 from '../jobs/JobsListingComponentV2';
import BalancesComponent from './BalancesComponent';
import { FxFlex } from 'sharedV2/index';
import { fetchExpenses } from 'actions/dashboard/expenses/expensesActions';
import { EXPENSE_TYPES, mappedExpenseNames } from 'components/dashboard/expenses/expenses_utils';
import { fetchAccountConfigList } from 'actions/dashboard/config_actions';
const initialCNMappingFiltersState = {
    startDate: getMomentTime().subtract(7, 'days'),
    endDate: getEndDate(),
    billingPartyId: null,
    consignmentOfficeId: null,
    consignmentNumber: '',
};
const initialFreightBillMappingFiltersState = {
    startDate: getMomentTime().subtract(7, 'days'),
    endDate: getEndDate(),
    billNumber: '',
};
const initialDeductionsFiltersState = {
    startDateDeductions: getMomentTime().subtract(7, 'days'),
    endDateDeductions: getEndDate(),
};
const initialHSMappingFiltersState = {
    startDate: getMomentTime().subtract(5, 'months'),
    endDate: getEndDate(),
    hireSlipOffice: null,
    hireSlipNumber: '',
    hireSlipParty: null,
    currenCode: null,
    vehicleId: null,
};
const AddEditPaymentFormComponent = (props) => {
    const { accesstoken, groupId, customers, divisions, consignments, loggedInUser, login, routeList, miniVehicleList, accountData, currencyCodes, } = props;
    const scope = login.scope;
    const navigate = useNavigate();
    const params = useParams();
    // On CN mapping filters change
    const [newConsignmentOptions, setNewConsignmentOptions] = useState([]);
    const [filters, dispatchFilters] = useReducer(filtersReducer, {
        ...initialCNMappingFiltersState,
        ...initialHSMappingFiltersState,
        ...initialFreightBillMappingFiltersState,
        ...initialDeductionsFiltersState,
    }, (state) => {
        const parsed = queryString.parse(location.hash);
        const parsedFilters = {};
        if (parsed.startDate) {
            parsedFilters.startDate = getMomentTime(parsed.startDate);
        }
        if (parsed.endDate) {
            parsedFilters.endDate = getMomentTime(parsed.endDate);
        }
        if (parsed.currentPage) {
            parsedFilters.currentPage = toSafeInteger(parsed.currentPage);
        }
        return { ...state, ...parsedFilters };
    });
    const getVoucherType = (advanceType = 'CN_ADVANCE') => {
        return advanceType;
    };
    const getBookType = (advanceType) => {
        switch (advanceType) {
            case 'DRIVER_ADVANCE':
                return 'CONSIGNMENT_DRIVER_ADVANCE';
            case 'CN_ADVANCE':
            case 'MONEY_RECEIPT':
                return 'CONSIGNMENT_MR_DETAILS';
            case 'PAYMENT_RECEIPT':
                return 'PAYMENT_RECEIPTS';
            case 'HIRE_SLIP':
                return 'HIRE_SLIP';
            case 'JOB_BILL_RECEIPT':
                return 'JOB_BILL_RECEIPT';
            default:
                return 'CONSIGNMENT_MR_DETAILS';
        }
    };
    const showRecovery = (advanceType) => {
        switch (advanceType) {
            case 'DRIVER_ADVANCE':
                return false;
            case 'CN_ADVANCE':
            case 'MONEY_RECEIPT':
            case 'HIRE_SLIP':
            case 'PAYMENT_RECEIPT':
                return true;
            case 'JOB_BILL_RECEIPT':
                return true;
            default:
                return false;
        }
    };
    const editId = params.id;
    const [enableTransactionDetails, setEnableTransactionDetails] = useState(!editId);
    const [initialValues, setInitialValues] = useState({
        paymentNature: getVoucherType(props.advanceType),
        voucherDate: getMomentTime(),
        voucherNumber: '',
        branch: null,
        stationaryId: null,
        stationaryNumber: '',
        bookType: getBookType(props.advanceType),
        adviceNumber: '',
        adviceAmount: '',
        narration: '',
        creditAccountId: null,
        debitLedger: {
            ledgerId: null,
            amount: '',
        },
        transactionDetails: {
            instrumentType: null,
            instrumentNumber: '',
            recoveryDate: '',
            bankName: '',
        },
        indirectIncome: {
            ledgerId: null,
            amount: '',
        },
        tdsLedger: {
            ledgerId: null,
            amount: '',
        },
        billInfo: {
            bookType: 'TRANSACTIONS',
        },
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [allBranches, setAllBranches] = useState([]);
    const [driverList, setDriverList] = useState([]);
    const [entityAdvances, setEntityAdvances] = useState([]);
    const [initialEntities, setInitialEntities] = useState([]);
    const [initialFreights, setInitialFreights] = useState([]);
    const [initialJobFreights, setInitialJobFreights] = useState([]);
    const [hireSlips, setHireSlips] = useState([]);
    const [erpProfileData, setERPProfileData] = useState(null);
    const [filteredConsignments, setFilteredConsignments] = useState([]);
    const [defaultDriverValues, setDefaultDrivervalues] = useState({});
    const [jobDetails, setJobDetails] = useState({});
    const [epodDetails, setEpodDetails] = useState({});
    const [openEpodModal, setOpenEpodModal] = useState(false);
    const [currentCNViewEPod, setCurrentCNViewEpod] = useState(null);
    const isDriverWiseLedger = isDriverWiseLedgerEntry();
    const showEPODCompletedCnInPayment = showEPODCompletedCNInPaymentReceipt();
    const isInternationalAcc = isInternationalAccount();
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [preSelectedRows, setPreSelectedRows] = useState([]);
    const [initialFreightsById, setInitialFreightsById] = useState([]);
    const [accountConfigData, setAccountConfigData] = useState({});
    const isCurrencyConversionApplicable = isInternationalAcc && get(accountData, 'multiCurrency', false);
    const didMountRef = React.useRef(false);
    const showAlert = useFxAlert();
    const [onAccountListing, setOnAccountListing] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const { startDate, endDate, startDateDeductions, endDateDeductions, billingPartyId, consignmentOfficeId, consignmentNumber, hireSlipOffice, hireSlipNumber, billNumber, hireSlipParty, currencyCode, vehicleId, } = filters;
    // Edit view
    useEffect(() => {
        if (editId && get(props, 'ledgers.length') > 0) {
            setIsLoading(true);
            props
                .fetchPaymentById(accesstoken, editId)
                .then((result) => {
                setIsLoading(false);
                if (!result.error) {
                    let d = get(result, 'payload.data', {});
                    d.bookType = getBookType(d.paymentNature);
                    let _newOptions = map(d.deductionMappings, (item) => {
                        if (item.entityDetails?.id && item.entityDetails?.consignmentNumber) {
                            return { value: item.entityDetails.id, label: item.entityDetails.consignmentNumber };
                        }
                        return false;
                    }).filter(Boolean);
                    setNewConsignmentOptions(_newOptions);
                    if (d.branchId) {
                        set(d, 'branch', d.branchId);
                        set(d, 'billInfo.branch', d.autoTransactionBranchId);
                        set(d, 'billInfo.stationaryId', d.autoTransactionStationaryId);
                        set(d, 'billInfo.stationaryNumber', d.autoTransactionStationaryNumber);
                        set(d, 'billInfo.bookType', 'TRANSACTIONS');
                        d = omit(d, 'branchId');
                        d = omit(d, 'autoTransactionBranchId');
                        d = omit(d, 'autoTransactionStationaryId');
                        d = omit(d, 'autoTransactionStationaryNumber');
                    }
                    if (d.voucherNumber) {
                        set(d, 'stationaryNumber', d.voucherNumber);
                        d = omit(d, 'voucherNumber');
                    }
                    // set(d, 'billInfo.branch', d.autoTransactionBranchId);
                    // set(d, 'billInfo.stationaryId', d.autoTransactionStationaryId);
                    // set(d, 'billInfo.bookType', 'TRANSACTIONS');
                    // d = omit(d, 'branchId');
                    // d = omit(d, 'autoTransactionBranchId');
                    // d = omit(d, 'autoTransactionStationaryId');
                    if (d.voucherStationaryId) {
                        set(d, 'stationaryId', d.voucherStationaryId);
                        d = omit(d, 'voucherStationaryId');
                    }
                    if (get(d, 'currencyCode'))
                        handleFilterChange('currencyCode', get(d, 'currencyCode'));
                    if (showRecovery(d.paymentNature)) {
                        const selectedLedger = filter(props.ledgers, (ledger) => get(ledger, 'id') == get(d, 'debitLedger.ledgerId'));
                        const isCashInHandLedger = get(selectedLedger, '0.group.baseGroupId') == 7;
                        setEnableTransactionDetails(!isCashInHandLedger);
                    }
                    const editEntities = prepareEntityDataFromAdvances(d.paymentMappings, d.paymentNature);
                    fetchOnAccountPayments({ creditAccountId: get(d, 'creditAccountId') });
                    setInitialEntities(editEntities);
                    setInitialValues(d);
                }
                else {
                    throw new Error(handleError(result.payload.response));
                }
            })
                .catch((err) => {
                setError(handleError(err));
                setIsLoading(false);
            });
        }
    }, [editId, props.ledgers]);
    useEffect(() => {
        const jobStatusesNotAllowed = [JOB_STATUS_VALUES.CANCELLED, JOB_STATUS_VALUES.UNASSIGNED];
        if (props.advanceType !== 'DRIVER_ADVANCE')
            return;
        const filteredConsignmentsTemp = filter(consignments, (consignment) => get(consignment, 'jobId') && !includes(jobStatusesNotAllowed, get(consignment, 'job.status')));
        const defaultDriverValuesTemp = {};
        forEach(filteredConsignmentsTemp, (consignment) => {
            set(defaultDriverValuesTemp, get(consignment, 'id'), get(consignment, 'basicDetails.vehicle.activeAssignment.userId'));
        });
        setFilteredConsignments(filteredConsignmentsTemp);
        setDefaultDrivervalues(defaultDriverValuesTemp);
    }, [consignments]);
    // On Mount
    useEffect(() => {
        props.fetchLedgersGroups(accesstoken);
        props.fetchLedgers(accesstoken);
        props.fetchCustomers(accesstoken);
        props.fetchRoutesMiniList(accesstoken, groupId);
        if (isViewBranchAllowedForUser(props.login.scope)) {
            props.fetchBranches(accesstoken).then((res) => {
                setAllBranches(get(res, 'payload.data'));
            });
        }
        props.fetchDivisions(accesstoken);
        props.fetchAllTransporters(accesstoken);
        props.fetchVehicleListMini(accesstoken, showRemovedVehiclesForAccount(), groupId);
        props.fetchTemplateByFormType(accesstoken, ERP_PROFILE_FORM_TYPES.DRIVER_ADVANCE).then((res) => {
            const data = get(res, 'payload.data');
            const profileData = get(data, '0.data');
            setERPProfileData(profileData);
        });
        if (isViewUsersAllowedForUser(props.login.scope) && props.advanceType == 'DRIVER_ADVANCE') {
            props.fetchUsersByType(accesstoken, 'driver').then((res) => {
                if (!res.error) {
                    setDriverList(get(res, 'payload.data.content'));
                }
            });
        }
        if (props.advanceType === 'DRIVER_ADVANCE') {
            props.fetchExpenses(accesstoken).then((res) => {
                setExpenses(get(res, 'payload.data'));
            });
        }
        getAccountConfigList();
    }, []);
    useEffect(() => {
        if (isCurrencyConversionApplicable) {
            props.fetchCurrencyCodes(accesstoken);
        }
    }, [isCurrencyConversionApplicable]);
    // On consignment filter change
    useEffect(() => {
        const params = {
            startDate,
            endDate,
            billingPartyId,
            consignmentOfficeId,
            consignmentNumber,
            billNumber,
            targetCurrency: currencyCode,
        };
        const consignmentFilters = {
            withAdvanceUnpaid: true,
        };
        if (props.advanceType === 'DRIVER_ADVANCE') {
            consignmentFilters.nature = 'FTL';
        }
        if (props.advanceType == 'DRIVER_ADVANCE' || props.advanceType == 'CN_ADVANCE') {
            props.fetchConsignments(accesstoken, { ...params, ...consignmentFilters });
        }
        if (props.advanceType === 'PAYMENT_RECEIPT' && (!editId || didMountRef.current)) {
            fetchFreightBills();
        }
        if (props.advanceType === 'JOB_BILL_RECEIPT') {
            fetchJobFreightBills();
        }
    }, [
        startDate.valueOf(),
        endDate.valueOf(),
        billingPartyId,
        consignmentOfficeId,
        consignmentNumber,
        billNumber,
        currencyCode,
    ]);
    // On deduction filter change
    useEffect(() => {
        const params = {
            startDate: startDateDeductions.valueOf(),
            endDate: endDateDeductions.valueOf(),
            billingPartyId,
            currencyCode,
        };
        if (props.advanceType == 'PAYMENT_RECEIPT') {
            props.fetchConsignments(accesstoken, params);
        }
    }, [startDateDeductions.valueOf(), endDateDeductions.valueOf(), billingPartyId, currencyCode]);
    // On hire slip filter change
    useEffect(() => {
        const params = {
            startDate: startDate.valueOf(),
            endDate: endDate.valueOf(),
            hireSlipOffice,
            hireSlipNumber,
            pendingAmountNull: false,
            hsDelivery: true,
            hireSlipParty,
            vehicleId,
            sizePerPage: 100,
        };
        if (props.advanceType === 'HIRE_SLIP') {
            props.fetchHireSlips(accesstoken, { ...params }).then((res) => {
                if (!res.error) {
                    setHireSlips(get(res, 'payload.data.content'));
                }
            });
        }
    }, [startDate.valueOf(), endDate.valueOf(), hireSlipOffice, hireSlipNumber, hireSlipParty, vehicleId]);
    const mapped = {
        cashAdvanceOptions: useMemo(() => mappedExpenseNames(filter(expenses, (e) => get(e, 'expenseType') === EXPENSE_TYPES.CASH_ADVANCE)), [expenses]),
        fuelAdvanceOptions: useMemo(() => mappedExpenseNames(filter(expenses, (e) => get(e, 'expenseType') === EXPENSE_TYPES.FUEL_ADVANCE)), [expenses]),
    };
    const goBack = () => {
        navigate(-1);
    };
    const fetchOnAccountPayments = ({ creditAccountId }) => {
        if (!creditAccountId)
            return;
        const voucherType = getVoucherType(props.advanceType);
        if (voucherType == 'HIRE_SLIP' || voucherType == 'PAYMENT_RECEIPT') {
            const params = {
                paymentNature: voucherType === 'HIRE_SLIP'
                    ? PAYMENT_NATURES.ON_ACCOUNT_PAYMENT
                    : PAYMENT_NATURES.ON_ACCOUNT_RECEIPT,
                creditAccountId,
                unsettledOnAccount: true,
            };
            props.fetchPayments(accesstoken, params).then((res) => {
                const data = get(res, 'payload.data.content');
                setOnAccountListing(data);
            });
        }
    };
    const setVehicleidsInEntitiesAndModifyData = async (entityAdvances, data) => {
        const autoFuelTransactionExpenseTypeId = get(data, 'autoFuelTransactionExpenseTypeId');
        let isError = false;
        let isErrorFuel = false;
        let finalConsignments = JSON.parse(JSON.stringify(filteredConsignments));
        const mappedIds = [];
        forOwn(entityAdvances, (value, key) => {
            const currentEntity = find(filteredConsignments, { id: +key });
            if (!autoFuelTransactionExpenseTypeId) {
                unset(value, 'fuelRate');
                unset(value, 'fuelQuantity');
            }
            else {
                const fuelRate = get(value, 'fuelRate', 0);
                const fuelQuantity = get(value, 'fuelQuantity', 0);
                const amount = fuelRate * fuelQuantity;
                const totalMappingAmount = get(value, 'totalMappingAmount', 0);
                if (amount > totalMappingAmount) {
                    isErrorFuel = true;
                }
            }
            if (!currentEntity) {
                mappedIds.push(+key);
            }
        });
        let mappedCN = [];
        if (editId && mappedIds.length > 0) {
            try {
                mappedCN = await props.fetchConsignments(accesstoken, {
                    ids: mappedIds.join(','),
                    includeCancelled: false,
                });
                mappedCN = get(mappedCN, 'payload.data');
            }
            catch (e) {
                handleError(e);
            }
        }
        finalConsignments = concat(finalConsignments, mappedCN);
        forOwn(entityAdvances, (value, key) => {
            const currentEntity = find(finalConsignments, { id: +key });
            if (!get(currentEntity, 'basicDetails.vehicleId') || !get(value, 'driverId')) {
                isError = true;
            }
            set(entityAdvances, `${key}.vehicleId`, get(currentEntity, 'basicDetails.vehicleId'));
        });
        return {
            isError,
            isErrorFuel,
        };
    };
    const fetchJobFreightBills = () => {
        const sortedIniitalEntities = sortBy(initialEntities, (entity) => getMomentTime(get(entity, 'basicDetails.billingDate')).valueOf());
        const startDateParam = editId
            ? getMomentTime(get(sortedIniitalEntities, '[0].basicDetails.billingDate'))
            : startDate;
        const endDateParam = editId
            ? getMomentTime(get(sortedIniitalEntities, `[${sortedIniitalEntities.length - 1}].basicDetails.billingDate`))
            : endDate;
        const config = {
            startDate: startDateParam.valueOf(),
            endDate: endDateParam.valueOf(),
            billNumber,
            unsettledVoucher: true,
            billMappingType: 'JOB_MAPPING',
            billingPartyId,
            billingNature: 'FREIGHT_INVOICE',
            size: 100,
        };
        props.fetchFreightBills(accesstoken, config).then((res) => {
            const freightBills = get(res, 'payload.data.content');
            setInitialJobFreights(freightBills);
        });
    };
    const fetchFreightBills = () => {
        const sortedIniitalEntities = sortBy(initialEntities, (entity) => getMomentTime(get(entity, 'basicDetails.billingDate')).valueOf());
        const startDateParam = editId
            ? getMomentTime(get(sortedIniitalEntities, '[0].basicDetails.billingDate'))
            : startDate;
        const endDateParam = editId
            ? getMomentTime(get(sortedIniitalEntities, `[${sortedIniitalEntities.length - 1}].basicDetails.billingDate`))
            : endDate;
        const config = {
            startDate: startDateParam.valueOf(),
            endDate: endDateParam.valueOf(),
            billNumber,
            advanceAmountPaid: true,
            unsettledVoucher: true,
            billMappingType: 'CN_MAPPING',
            billingPartyId,
            targetCurrency: currencyCode,
            billingNature: 'FREIGHT_INVOICE',
            size: 100,
        };
        props
            .fetchFreightBills(accesstoken, config)
            .then((res) => {
            const freightBills = get(res, 'payload.data.content', []);
            const preSelectedBills = filter(initialFreights, (bill) => {
                return find(selectedEntities, { freightBillId: get(bill, 'id') });
            });
            setInitialFreights(uniqBy([...preSelectedBills, ...freightBills], 'id'));
            return freightBills;
        })
            .then((freightBills) => {
            const cnIds = [];
            forEach(freightBills, (freightBill) => {
                forEach(get(freightBill, 'consignmentBillDetailsList'), (cn) => {
                    cnIds.push(get(cn, 'consignmentId'));
                });
            });
            handleFetchConsignments(cnIds);
        });
    };
    const onSubmit = async (val, action) => {
        const { setSubmitting } = action;
        let data = { ...val };
        const voucherNumber = get(data, 'stationaryNumber');
        const voucherStationaryId = get(data, 'stationaryId');
        const branchId = get(data, 'branch');
        set(data, 'voucherNumber', voucherNumber);
        set(data, 'branchId', branchId);
        set(data, 'voucherStationaryId', voucherStationaryId);
        data = omit(data, 'stationaryNumber');
        data = omit(data, 'stationaryId');
        data = omit(data, 'branch');
        data = omit(data, 'bookType');
        if (isEmpty(values(entityAdvances))) {
            showAlert('Please select atleast one entity and advance and enter its amount!');
            setSubmitting(false);
            return;
        }
        if (props.advanceType == 'DRIVER_ADVANCE') {
            const { isError, isErrorFuel } = await setVehicleidsInEntitiesAndModifyData(entityAdvances, data);
            if (isError) {
                alert('Please Select a Valid Driver');
                return;
            }
            if (isErrorFuel) {
                alert('Fuel Amount should be less than total mapping amount');
                return;
            }
        }
        set(data, 'paymentMappings', values(entityAdvances));
        if (get(data, 'paymentNature') == 'CN_ADVANCE' || get(data, 'paymentNature') == 'MONEY_RECEIPT') {
            const debitLedgerAmount = +get(data, 'debitLedger.amount', 0);
            const tdsLedgerAmount = +get(data, 'tdsLedger.amount', 0);
            const indirectIncomeAmount = +get(data, 'indirectIncome.amount', 0);
            if (debitLedgerAmount + tdsLedgerAmount + indirectIncomeAmount != get(data, 'adviceAmount')) {
                alert('Recovery amount should be equal to advice amount!');
                setSubmitting(false);
                return;
            }
        }
        if (get(data, 'paymentNature') == 'DRIVER_ADVANCE') {
            const autoTransactionStationaryNumber = get(data, 'billInfo.stationaryNumber');
            const autoTransactionStationaryId = get(data, 'billInfo.stationaryId');
            const autoTransactionBranchId = get(data, 'billInfo.branch');
            //set(data, 'autoTransactionStationaryNumber', autoTransactionStationaryNumber);
            set(data, 'autoTransactionBranchId', autoTransactionBranchId);
            set(data, 'autoTransactionStationaryId', autoTransactionStationaryId);
            data = omit(data, 'billInfo');
            if (isDriverWiseLedger) {
                set(data, 'driverWiseLedgerEntry', true);
            }
        }
        else {
            data = omit(data, 'autoTransactionDivisionId');
            data = omit(data, 'billInfo');
        }
        if (get(data, 'paymentNature') == 'DRIVER_ADVANCE' ||
            !get(data, 'debitLedger.ledgerId') ||
            !get(data, 'debitLedger.amount')) {
            data = omit(data, 'debitLedger');
        }
        if (get(data, 'paymentNature') == 'DRIVER_ADVANCE' ||
            !get(data, 'indirectIncome.ledgerId') ||
            !get(data, 'indirectIncome.amount')) {
            data = omit(data, 'indirectIncome');
        }
        if (get(data, 'paymentNature') == 'DRIVER_ADVANCE' ||
            !get(data, 'tdsLedger.ledgerId') ||
            !get(data, 'tdsLedger.amount')) {
            data = omit(data, 'tdsLedger');
        }
        if (get(data, 'paymentNature') == 'DRIVER_ADVANCE' ||
            !get(data, 'debitLedger.ledgerId') ||
            !enableTransactionDetails) {
            data = omit(data, 'transactionDetails');
        }
        if (!get(data, 'onAccountLedger.ledgerId') || !get(data, 'onAccountLedger.amount')) {
            data = omit(data, 'onAccountLedger');
        }
        if (erpProfileData && get(data, 'paymentNature') == 'DRIVER_ADVANCE' && !isDriverWiseLedger) {
            const debitLedger = {
                amount: get(data, 'adviceAmount'),
                ledgerId: get(erpProfileData, 'tripAdvanceLedgerId'),
            };
            set(data, 'debitLedger', debitLedger);
        }
        if (data.voucherDate) {
            set(data, 'voucherDate', getFormattedTimeStringForAPI(getMomentTime(get(data, 'voucherDate')), DATE_FORMAT_TIMESTAMP));
        }
        if (get(data, 'paymentNature') == 'PAYMENT_RECEIPT') {
            let freightBillIdsForRoundingAmountSettlement = [];
            const freights = editId ? initialFreightsById : initialFreights;
            const selectedFreights = [];
            forEach(values(entityAdvances), (advance) => {
                const entityId = get(advance, 'entityId');
                forEach(freights, (bill) => {
                    forEach(get(bill, 'consignmentBillDetailsList'), (cn) => {
                        if (entityId == get(cn, 'id')) {
                            selectedFreights.push(get(bill, 'id'));
                        }
                    });
                });
            });
            forEach(freights, (bill) => {
                const id = get(bill, 'id');
                if (includes(selectedFreights, id)) {
                    const consignmentBillDetailsList = get(bill, 'consignmentBillDetailsList');
                    let totalReceivedAmount = 0;
                    forEach(consignmentBillDetailsList, (cn) => {
                        const receivedPaymentAmount = get(cn, 'receivedPaymentAmount', 0);
                        const cnFromEntityAdvances = filter(values(entityAdvances), (advance) => {
                            return get(advance, 'entityId') == get(cn, 'id');
                        });
                        const paymentMappingSum = sumBy(cnFromEntityAdvances, 'paymentAmount');
                        totalReceivedAmount += paymentMappingSum;
                        if (!editId || !get(cnFromEntityAdvances, 'length')) {
                            totalReceivedAmount += receivedPaymentAmount;
                        }
                    });
                    const actualFreight = get(bill, 'actualTotalFreight');
                    if (totalReceivedAmount === actualFreight) {
                        freightBillIdsForRoundingAmountSettlement.push(id);
                    }
                }
            });
            set(data, 'freightBillIdsForRoundingAmountSettlement', freightBillIdsForRoundingAmountSettlement);
        }
        if (editId) {
            props
                .updatePayment(accesstoken, data)
                .then((result) => {
                if (!result.error) {
                    alert('Updated successfully!');
                    goBack();
                }
                else {
                    throw new Error(handleError(result.payload.response));
                }
            })
                .catch((err) => {
                alert(handleError(err));
                setSubmitting(false);
            });
        }
        else {
            props
                .createPayment(accesstoken, data)
                .then((result) => {
                if (!result.error) {
                    alert('Created successfully!');
                    goBack();
                }
                else {
                    throw new Error(handleError(result.payload.response));
                }
            })
                .catch((err) => {
                alert(handleError(err));
                setSubmitting(false);
            });
        }
    };
    const handleFilterChange = (key, value) => {
        const newFilersState = {};
        let startDateKey = 'startDate';
        let endDateKey = 'endDate';
        if (key === 'timeFilterDeductions') {
            startDateKey = 'startDateDeductions';
            endDateKey = 'endDateDeductions';
        }
        if (key === 'timeFilter' || key === 'timeFilterDeductions') {
            newFilersState[startDateKey] = get(value, '[0]', null);
            newFilersState[endDateKey] = get(value, '[1]', null);
        }
        else {
            newFilersState[key] = value;
        }
        dispatchFilters({ payload: newFilersState });
    };
    const onPageChange = (page, sizePerPage) => {
        dispatchFilters({
            payload: {
                sizePerPage,
                currentPage: page,
            },
        });
    };
    const prepareEntityDataFromAdvances = (paymentMappings, paymentNature) => {
        let entities = [];
        if (paymentNature == 'HIRE_SLIP') {
            entities = map(paymentMappings, (advance, index) => {
                const entityObj = {};
                entityObj.id = toSafeInteger(get(advance, 'entityId'));
                entityObj.basicDetails = {
                    refNo: get(advance, 'entityDetails.hireSlipRefNo'),
                    date: get(advance, 'entityDetails.billDate'),
                    branch: get(advance, 'entityDetails.billOffice'),
                };
                entityObj.freightDetails = {
                    totalHireCharge: get(advance, 'entityDetails.totalHireCharge.amount'),
                    balanceHireCharge: get(advance, 'entityDetails.balanceHireCharge.amount'),
                };
                entityObj.advanceDetails = {
                    receivedAdvanceAmount: get(advance, 'entityDetails.receivedAmount.amount'),
                    advanceAmountEntry: get(advance, 'paymentAmount'),
                    onAccountPaymentAmount: get(advance, 'onAccountPaymentAmount'),
                    totalMappingAmount: get(advance, 'totalMappingAmount'),
                };
                entityObj.remarks = get(advance, 'remark');
                return entityObj;
            });
        }
        else if (paymentNature == 'PAYMENT_RECEIPT') {
            const cnIds = [];
            const freightBillIds = [];
            entities = map(paymentMappings, (advance, index) => {
                cnIds.push(get(advance, 'entityDetails.consignmentId'));
                freightBillIds.push(get(advance, 'entityDetails.freightBillId'));
                const entityObj = {
                    id: get(advance, 'entityId'),
                    remarks: get(advance, 'remark'),
                    basicDetails: {
                        billNumber: get(advance, 'entityDetails.freightBillNumber'),
                        freightAmount: get(advance, 'entityDetails.freightBillAmount.amount'),
                        billingDate: get(advance, 'entityDetails.freightbillDate'),
                        freightBillId: get(advance, 'entityDetails.freightBillId'),
                    },
                    consignmentBillDetailsList: [
                        {
                            id: `${get(advance, 'entityId')}`,
                            consignmentId: get(advance, 'entityDetails.consignmentId'),
                            consignmentEntityDetailsDto: {
                                consignmentNumber: get(advance, 'entityDetails.consignmentNumber'),
                                chargedRoute: get(advance, 'entityDetails.chargedRoute'),
                                consignmentDate: get(advance, 'entityDetails.consignmentDate'),
                                licensePlate: get(advance, 'entityDetails.licensePlate'),
                                consignmentStatus: get(advance, 'entityDetails.consignmentStatus'),
                                consignmentId: get(advance, 'entityDetails.consignmentId'),
                                receivedDeductionAmount: {
                                    amount: get(advance, 'entityDetails.receivedDeductionAmount.amount'),
                                },
                            },
                            receivedAdvanceAmount: {
                                amount: get(advance, 'entityDetails.receivedAdvanceAmount.amount'),
                            },
                            receivedPaymentAmount: get(advance, 'entityDetails.receivedPaymentAmount.amount'),
                            balanceAdvanceAmount: get(advance, 'entityDetails.total.amount', 0) -
                                get(advance, 'entityDetails.receivedAdvanceAmount.amount', 0) -
                                get(advance, 'entityDetails.receivedPaymentAmount.amount', 0),
                            total: get(advance, 'entityDetails.consignmentBillAmount.amount', get(advance, 'entityDetails.freightBillAmount.amount')),
                            paymentAmount: get(advance, 'paymentAmount'),
                            onAccountPaymentAmount: get(advance, 'onAccountPaymentAmount'),
                            totalMappingAmount: get(advance, 'totalMappingAmount'),
                            remark: get(advance, 'remark'),
                        },
                    ],
                };
                return entityObj;
            });
            handleFetchConsignments(cnIds);
            handleFetchFreightBillByIds(freightBillIds);
        }
        else if (paymentNature === 'JOB_BILL_RECEIPT') {
            entities = map(paymentMappings, (advance, index) => {
                const entityObj = {
                    id: get(advance, 'entityId'),
                    remarks: get(advance, 'remark'),
                    paymentAmount: get(advance, 'paymentAmount'),
                    basicDetails: {
                        billNumber: get(advance, 'entityDetails.freightBillNumber'),
                        freightAmount: get(advance, 'entityDetails.jobBillTotalAmount'),
                        billingDate: get(advance, 'entityDetails.freightbillDate'),
                        freightBillId: get(advance, 'entityDetails.freightBillId'),
                    },
                    voucher: {
                        pendingAmount: get(advance, 'entityDetails.jobBillTotalAmount') -
                            get(advance, 'entityDetails.receivedJobBillPaymentAmount'),
                        settledAmount: get(advance, 'entityDetails.receivedJobBillPaymentAmount'),
                    },
                };
                return entityObj;
            });
        }
        else {
            entities = map(paymentMappings, (advance, index) => {
                const entityObj = {};
                entityObj.id = toSafeInteger(get(advance, 'entityId'));
                entityObj.basicDetails = {
                    consignmentNumber: get(advance, 'entityDetails.consignmentNumber'),
                    consignmentDate: get(advance, 'entityDetails.consignmentDate'),
                    consignmentOfficeId: get(advance, 'entityDetails.officeId'),
                    chargedRouteId: get(advance, 'entityDetails.routeId'),
                    vehicle: {
                        licensePlate: get(advance, 'entityDetails.licensePlate'),
                    },
                };
                entityObj.billingSummary = {
                    freightTotal: get(advance, 'entityDetails.freightTotalAmount.amount'),
                };
                entityObj.advanceDetails = {
                    advanceAmount: get(advance, 'entityDetails.advanceAmount.amount'),
                    receivedAdvanceAmount: get(advance, 'entityDetails.receivedAdvanceAmount.amount'),
                    balanceAdvanceAmount: get(advance, 'entityDetails.balanceAdvanceAmount.amount'),
                    advanceAmountEntry: get(advance, 'paymentAmount'),
                };
                entityObj.remarks = get(advance, 'remark');
                if (paymentNature == 'DRIVER_ADVANCE') {
                    entityObj.driverId = get(advance, 'driverId');
                    entityObj.vehicleId = get(advance, 'vehicleId');
                    entityObj.fuelRate = get(advance, 'fuelRate');
                    entityObj.fuelQuantity = get(advance, 'fuelQuantity');
                    set(entityObj, 'basicDetails.vehicle.licensePlate', get(advance, 'licensePlate'));
                }
                return entityObj;
            });
        }
        return entities;
    };
    const updateCNStatus = ({ cnDetails }) => {
        const initialEntitiesCopy = JSON.parse(JSON.stringify(initialEntities));
        forEach(initialEntitiesCopy, (entity) => {
            const consignmentBillDetailsList = get(entity, 'consignmentBillDetailsList', []);
            forEach(consignmentBillDetailsList, (cn) => {
                const consignmentEntityDetailsDto = get(cn, 'consignmentEntityDetailsDto', {});
                const consignmentId = get(consignmentEntityDetailsDto, 'consignmentId');
                set(consignmentEntityDetailsDto, 'consignmentStatus', get(cnDetails, `${consignmentId}.status`));
            });
        });
        setInitialEntities(initialEntitiesCopy);
    };
    const handleFetchConsignments = (cnIds) => {
        if (cnIds.length === 0)
            return;
        const params = {
            ids: uniq(cnIds).join(','),
        };
        props.fetchConsignments(accesstoken, params).then((res) => {
            const consignments = get(res, 'payload.data');
            const jobIds = [];
            forEach(consignments, (cn) => {
                if (cn.jobId &&
                    (cn.status === CONSIGNMENT_STATUS.POD_COMPLETED ||
                        cn.status === CONSIGNMENT_STATUS.MAPPED_TO_CHALLAN))
                    jobIds.push(cn.jobId);
            });
            if (editId && props.advanceType === 'PAYMENT_RECEIPT' && didMountRef.current) {
                updateCNStatus({ cnDetails: keyBy(consignments, 'id') });
            }
            const sortedCNS = sortBy(consignments, (cn) => {
                return getMomentTime(cn, 'basicDetails.consignmentDate').valueOf();
            });
            handleFetchJobs(jobIds, sortedCNS);
        });
    };
    const handleFetchFreightBillByIds = (ids) => {
        if (!get(ids, 'length'))
            return;
        const uniqIds = uniq(ids);
        const params = {
            ids: uniqIds.join(','),
            size: get(uniqIds, 'length'),
        };
        props.fetchFreightBills(accesstoken, params).then((res) => {
            const freightBills = get(res, 'payload.data.content');
            setInitialFreightsById(freightBills);
        });
    };
    const handleFetchJobs = async (ids, sortedCNS) => {
        if (!get(ids, 'length')) {
            didMountRef.current = true;
            return;
        }
        props
            .fetchJobListNewV2(accesstoken, {
            ids: uniq(ids).join(','),
            from: getMomentTime(get(sortedCNS, '0', {}), 'basicDetails.consignmentDate').valueOf(),
            epodUploaded: null,
        })
            .then((res) => {
            if (!res.error) {
                const jobDetails = keyBy(get(res, 'payload.data.content'), 'id');
                setJobDetails(jobDetails);
                const epodDetails = {};
                forEach(sortedCNS, (row) => {
                    const cnId = get(row, 'id');
                    const jobId = get(row, 'jobId');
                    const mappedJobDetails = get(jobDetails, jobId, {});
                    forEach(get(mappedJobDetails, 'jobAnalytics'), (value, key) => {
                        const epod = get(value, 'epods[0]', {});
                        const erpJobAnalytics = get(value, 'erpJobAnalytics', {});
                        const isCNPresent = find(erpJobAnalytics, { consignmentId: cnId });
                        if (isCNPresent) {
                            epodDetails[cnId] = { ...epod, jobId };
                        }
                    });
                });
                setEpodDetails(epodDetails);
                didMountRef.current = true;
            }
        });
    };
    const onOpenEpodModal = () => {
        setOpenEpodModal(true);
    };
    const onCloseEpodModal = () => {
        setOpenEpodModal(false);
        setCurrentCNViewEpod(null);
        fetchFreightBills();
    };
    const addEditSchema = useMemo(() => Yup.object().shape({
        paymentNature: Yup.string().required('Voucher Type Required').nullable(),
        deliveryType: Yup.string().when('type', {
            is: (type) => type == 'DISPATCH',
            then: Yup.string().required('Delivery Type Required.').nullable(),
            otherwise: Yup.string().nullable(),
        }),
        branch: Yup.string().required('Office Required').nullable(),
        stationaryId: Yup.string().required('Stationary Required').nullable(),
        stationaryNumber: Yup.string().required('Voucher Number Required'),
        adviceNumber: Yup.string().required('Advice Number Required.').nullable(),
        adviceAmount: Yup.string().required('Advice Amount Required.').nullable(),
        voucherDate: Yup.string().required('Voucher Date Required').nullable(),
        creditAccountId: Yup.string().required('Required').nullable(),
        debitLedger: Yup.object().shape({
            ledgerId: Yup.string()
                .test('Is required', 'Ledger Required', function (val) {
                const fields = get(this.options, 'context');
                if (((fields.paymentNature == 'CN_ADVANCE' ||
                    fields.paymentNature == 'MONEY_RECEIPT' ||
                    fields.paymentNature == 'HIRE_SLIP') &&
                    !val &&
                    !fields.indirectIncome.ledgerId &&
                    !fields.tdsLedger.ledgerId) ||
                    (get(fields, 'debitLedger.amount') && !val)) {
                    return false;
                }
                return true;
            })
                .nullable(),
            amount: Yup.string().test('Is required', 'Amount Required', function (val) {
                const fields = get(this.options, 'context');
                if ((fields.paymentNature == 'CN_ADVANCE' ||
                    fields.paymentNature == 'MONEY_RECEIPT' ||
                    fields.paymentNature == 'HIRE_SLIP') &&
                    !val &&
                    !fields.indirectIncome.amount &&
                    !fields.tdsLedger.amount) {
                    return false;
                }
                return true;
            }),
        }),
        transactionDetails: Yup.object().shape({
            instrumentType: Yup.string()
                .test('Is required', 'Instrument type Required', function (val) {
                const fields = get(this.options, 'context');
                if ((fields.paymentNature == 'CN_ADVANCE' ||
                    fields.paymentNature == 'MONEY_RECEIPT' ||
                    fields.paymentNature == 'HIRE_SLIP') &&
                    !val &&
                    fields.debitLedger.ledgerId &&
                    enableTransactionDetails) {
                    return false;
                }
                return true;
            })
                .nullable(),
            instrumentNumber: Yup.string().test('Is required', 'Instrument number Required', function (val) {
                const fields = get(this.options, 'context');
                if ((fields.paymentNature == 'CN_ADVANCE' ||
                    fields.paymentNature == 'MONEY_RECEIPT' ||
                    fields.paymentNature == 'HIRE_SLIP') &&
                    !val &&
                    fields.debitLedger.ledgerId &&
                    enableTransactionDetails) {
                    return false;
                }
                return true;
            }),
            recoveryDate: Yup.string().test('Is required', 'Recovery Date Required', function (val) {
                const fields = get(this.options, 'context');
                if ((fields.paymentNature == 'CN_ADVANCE' ||
                    fields.paymentNature == 'MONEY_RECEIPT' ||
                    fields.paymentNature == 'HIRE_SLIP') &&
                    !val &&
                    fields.debitLedger.ledgerId &&
                    enableTransactionDetails) {
                    return false;
                }
                return true;
            }),
            bankName: Yup.string().test('Is required', 'Bank Name Required', function (val) {
                const fields = get(this.options, 'context');
                if ((fields.paymentNature == 'CN_ADVANCE' ||
                    fields.paymentNature == 'MONEY_RECEIPT' ||
                    fields.paymentNature == 'HIRE_SLIP') &&
                    !val &&
                    fields.debitLedger.ledgerId &&
                    enableTransactionDetails) {
                    return false;
                }
                return true;
            }),
        }),
        indirectIncome: Yup.object().shape({
            ledgerId: Yup.string()
                .test('Is required', 'Indirect Required', function (val) {
                const fields = get(this.options, 'context');
                if (((fields.paymentNature == 'CN_ADVANCE' || fields.paymentNature == 'MONEY_RECEIPT') &&
                    !val &&
                    !fields.debitLedger.ledgerId &&
                    !fields.tdsLedger.ledgerId) ||
                    (get(fields, 'indirectIncome.amount') && !val)) {
                    return false;
                }
                return true;
            })
                .nullable(),
            amount: Yup.string().test('Is required', 'Amount Required', function (val) {
                const fields = get(this.options, 'context');
                if ((fields.paymentNature == 'CN_ADVANCE' || fields.paymentNature == 'MONEY_RECEIPT') &&
                    !val &&
                    !fields.debitLedger.amount &&
                    !fields.tdsLedger.amount) {
                    return false;
                }
                return true;
            }),
        }),
        tdsLedger: Yup.object().shape({
            ledgerId: Yup.string()
                .test('Is required', 'TDS ledger Required', function (val) {
                const fields = get(this.options, 'context');
                if (((fields.paymentNature == 'CN_ADVANCE' || fields.paymentNature == 'MONEY_RECEIPT') &&
                    !val &&
                    !fields.debitLedger.ledgerId &&
                    !fields.indirectIncome.ledgerId) ||
                    (get(fields, 'tdsLedger.amount') && !val)) {
                    return false;
                }
                return true;
            })
                .nullable(),
            amount: Yup.string().test('Is required', 'Amount Required', function (val) {
                const fields = get(this.options, 'context');
                if ((fields.paymentNature == 'CN_ADVANCE' || fields.paymentNature == 'MONEY_RECEIPT') &&
                    !val &&
                    !fields.debitLedger.amount &&
                    !fields.indirectIncome.amount) {
                    return false;
                }
                return true;
            }),
        }),
        onAccountLedger: Yup.object().shape({
            ledgerId: Yup.string()
                .test('Is required', 'On Account Ledger Required', function (val) {
                const fields = get(this.options, 'context');
                if (fields.onAccountFormId && !val) {
                    return false;
                }
                return true;
            })
                .nullable(),
        }),
    }), [enableTransactionDetails]);
    const deleteAdvance = () => {
        props
            .deletePayment(accesstoken, editId)
            .then((result) => {
            if (!result.error) {
                navigate('/dashboard/payments');
            }
            else {
                throw new Error(handleError(result.payload.response));
            }
        })
            .catch((err) => {
            setError(handleError(err));
        });
    };
    const getAccountConfigList = async () => {
        const result = await props.fetchAccountConfigList(accesstoken);
        if (!result.error) {
            let data = get(result, 'payload.data');
            setAccountConfigData(data);
        }
    };
    if (isLoading) {
        return _jsx(Loader, {});
    }
    return (_jsx("div", { className: "card-group mt-4 mb-0", children: _jsx("div", { className: "card p-1", children: _jsxs("div", { className: "card-block", children: [_jsx(Helmet, { title: `${editId ? 'Edit' : 'Add'} Payment - fleetx.io` }), _jsxs("h4", { children: [editId ? 'Edit' : 'Add', " Payment"] }), _jsx("hr", {}), _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validate: (value) => {
                            try {
                                validateYupSchema(value, addEditSchema, true, value);
                            }
                            catch (err) {
                                return yupToFormErrors(err); //for rendering validation errors
                            }
                            return {};
                        }, enableReinitialize: true, children: (formikProps) => {
                            const { isSubmitting, values, errors } = formikProps;
                            return (_jsxs(Form, { children: [_jsxs("div", { className: "form-group", children: [_jsx("h6", { className: "mt-4", children: "Basic Information" }), _jsx("hr", {}), _jsx(BasicInformationComponent, { formikProps: formikProps, loggedInUser: loggedInUser, editId: editId, customers: customers, transporters: props.transporters, handleFilterChange: handleFilterChange, divisions: divisions, currencyCodes: currencyCodes, isCurrencyConversionApplicable: isCurrencyConversionApplicable, currencyCode: currencyCode, fetchOnAccountPayments: fetchOnAccountPayments, onAccountListing: onAccountListing, vehicleList: miniVehicleList, accountConfigData: accountConfigData }), showRecovery(get(values, 'paymentNature')) && (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mt-4", children: values.paymentNature == 'HIRE_SLIP'
                                                            ? 'Payment Details'
                                                            : 'Recovery Section' }), _jsx("hr", {}), _jsx(RecoveryComponent, { formikProps: formikProps, loggedInUser: loggedInUser, editId: editId, enableTransactionDetails: enableTransactionDetails, setEnableTransactionDetails: setEnableTransactionDetails, ledgers: props.ledgers })] })), get(values, 'paymentNature') === 'DRIVER_ADVANCE' &&
                                                isViewBranchAllowedForUser(scope) && (_jsx("h6", { className: "mt-4", children: "Transaction Stationary" })), get(values, 'paymentNature') === 'DRIVER_ADVANCE' &&
                                                isViewBranchAllowedForUser(scope) && _jsx("hr", {}), get(values, 'paymentNature') === 'DRIVER_ADVANCE' &&
                                                isViewBranchAllowedForUser(scope) && (_jsx(BillNumberComponentDynamic, { formikProps: formikProps, isEdit: false, isReadyToFetchBillNumber: true, basicName: "billInfo.", stationaryNatureFilterKey: "AUTO", stationaryNumberNotRequired: true, fieldDisabled: {
                                                    'branch': !!editId,
                                                    'bookName': !!editId,
                                                    'stationaryNumber': !!editId,
                                                } })), get(values, 'paymentNature') === 'DRIVER_ADVANCE' &&
                                                isViewBranchAllowedForUser(scope) && (_jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `autoTransactionDivisionId`, component: FxSelectField, options: mappedOptionsFromArray(divisions), label: "Transaction Division", isRequired: true, onChange: (val) => { } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `autoCashTransactionExpenseTypeId`, component: FxSelectField, options: mapped.cashAdvanceOptions, label: "Cash Advances", onChange: (val) => { } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `autoFuelTransactionExpenseTypeId`, component: FxSelectField, options: mapped.fuelAdvanceOptions, label: "Fuel Advances", onChange: (val) => { } }) })] })), !editId && values.paymentNature != 'HIRE_SLIP' && (_jsxs(_Fragment, { children: [values.paymentNature != 'PAYMENT_RECEIPT' &&
                                                        values.paymentNature !== 'JOB_BILL_RECEIPT' && (_jsx("h6", { className: "mt-4", children: "CN Mapping Filter" })), values.paymentNature == 'PAYMENT_RECEIPT' ||
                                                        (values.paymentNature == 'JOB_BILL_RECEIPT' && (_jsx("h6", { className: "mt-4", children: "Freight Bill Filter" }))), _jsx("hr", {}), _jsx(ConsignmentsMappingFilterComponent, { formikProps: formikProps, handleFilterChange: handleFilterChange, startDate: startDate, endDate: endDate, billingPartyId: billingPartyId, consignmentOfficeId: consignmentOfficeId, consignmentNumber: consignmentNumber, customers: customers, branches: allBranches })] })), values.paymentNature == 'PAYMENT_RECEIPT' && (_jsxs(_Fragment, { children: [_jsxs(FxFlex, { justify: "space-between", children: [_jsx("h6", { className: "mt-4", children: "Freight Details" }), _jsx(BalancesComponent, {})] }), _jsx("hr", { className: "mt-0" }), _jsx(GenericERPTableListingComponent, { ListComponent: FreightBillListingComponent, data: editId ? initialEntities : initialFreights, isEdit: !!editId, formikProps: formikProps, listType: 'FREIGHT', setEntityAdvances: setEntityAdvances, epodDetails: epodDetails, jobDetails: jobDetails, onOpenEpodModal: onOpenEpodModal, setCurrentCNViewEpod: setCurrentCNViewEpod, selectedEntities: selectedEntities, setSelectedEntities: setSelectedEntities, onAccountListing: keyBy(onAccountListing, 'id'), preSelectedRows: preSelectedRows, setPreSelectedRows: setPreSelectedRows })] })), values.paymentNature != 'HIRE_SLIP' &&
                                                values.paymentNature != 'PAYMENT_RECEIPT' &&
                                                values.paymentNature !== 'JOB_BILL_RECEIPT' && (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mt-4", children: "CN Details" }), _jsx("hr", {}), _jsx(GenericERPTableListingComponent, { ListComponent: ConsignmentsListingComponent, data: editId
                                                            ? initialEntities
                                                            : props.advanceType == 'DRIVER_ADVANCE'
                                                                ? filteredConsignments
                                                                : consignments, isEdit: !!editId, 
                                                        // onPageChange={onPageChange}
                                                        // currentPage={1}
                                                        // sizePerPage={10}
                                                        setEntityAdvances: setEntityAdvances, formikProps: formikProps, routeList: groupBy(routeList, 'id'), branches: groupBy(allBranches, 'id'), listType: 'CONSIGNMENT', driverList: driverList, defaultDriverValues: defaultDriverValues, selectedEntities: selectedEntities, setSelectedEntities: setSelectedEntities, preSelectedRows: preSelectedRows, setPreSelectedRows: setPreSelectedRows })] })), values.paymentNature == 'JOB_BILL_RECEIPT' && (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mt-4", children: "Job Details" }), _jsx("hr", {}), _jsx(GenericERPTableListingComponent, { ListComponent: JobsListingComponentV2, data: editId ? initialEntities : initialJobFreights, isEdit: !!editId, 
                                                        // onPageChange={onPageChange}
                                                        // currentPage={1}
                                                        // sizePerPage={10}
                                                        setEntityAdvances: setEntityAdvances, formikProps: formikProps, routeList: groupBy(routeList, 'id'), branches: allBranches, groupedVehicles: groupBy(miniVehicleList, 'id'), listType: 'JOB_FREIGHT', selectedEntities: selectedEntities, setSelectedEntities: setSelectedEntities, onAccountListing: keyBy(onAccountListing, 'id'), preSelectedRows: preSelectedRows, setPreSelectedRows: setPreSelectedRows })] })), !editId && values.paymentNature == 'HIRE_SLIP' && (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mt-4", children: "HS Mapping Filter" }), _jsx("hr", {}), _jsx(HireSlipMappingFilterComponent, { handleFilterChange: handleFilterChange, startDate: startDate, endDate: endDate, branches: allBranches, hireSlipOffice: hireSlipOffice, hireSlipNumber: hireSlipNumber, vehicleId: vehicleId, vehicleList: miniVehicleList })] })), values.paymentNature == 'HIRE_SLIP' && (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mt-4", children: "HS Details" }), _jsx("hr", {}), _jsx(GenericERPTableListingComponent, { ListComponent: HireSlipListingComponent, data: editId ? initialEntities : hireSlips, isEdit: !!editId, 
                                                        // onPageChange={onPageChange}
                                                        // currentPage={1}
                                                        // sizePerPage={10}
                                                        setEntityAdvances: setEntityAdvances, formikProps: formikProps, routeList: groupBy(routeList, 'id'), branches: allBranches, groupedVehicles: groupBy(miniVehicleList, 'id'), listType: 'HIRE_SLIP', selectedEntities: selectedEntities, setSelectedEntities: setSelectedEntities, onAccountListing: keyBy(onAccountListing, 'id'), preSelectedRows: preSelectedRows, setPreSelectedRows: setPreSelectedRows })] }))] }), values.paymentNature == 'PAYMENT_RECEIPT' && (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mt-4", children: "Filter Consignments" }), _jsx("hr", {}), _jsx(DeductionFilterComponent, { formikProps: formikProps, handleFilterChange: handleFilterChange, startDate: startDateDeductions, endDate: endDateDeductions }), _jsx(DeductionComponent, { formikProps: formikProps, editId: editId, consignments: consignments, ledgers: props.ledgers, newConsignmentOptions: newConsignmentOptions, preSelectedRows: preSelectedRows })] })), _jsx("hr", {}), error && (_jsx("div", { className: "my-2 text-danger text-center", children: _jsx("strong", { children: error }) })), _jsxs("div", { className: "mb-2 float-right", children: [!get(values, 'autoTriggered') &&
                                                isDeleteAdvanceAllowedForUser(scope) &&
                                                editId &&
                                                isEmpty(entityAdvances) && (_jsx("button", { type: "button", className: "mr-2 btn btn-danger", onClick: deleteAdvance, children: "Delete" })), _jsx("button", { disabled: isSubmitting || get(values, 'autoTriggered'), type: "submit", className: "btn btn-primary mr-2", children: "Submit" }), _jsx("button", { type: "button", className: "btn btn-secondary", onClick: goBack, children: "Cancel" })] })] }));
                        } }), openEpodModal && (_jsx(FxModal, { open: true, footer: null, onCancel: () => onCloseEpodModal(), width: 1100, children: _jsx(AddEditEPODComponent, { jobId: get(get(epodDetails, get(currentCNViewEPod, 'consignmentId')), 'jobId'), onModalClose: onCloseEpodModal, openInModal: true }) }))] }) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createAdvance,
        updateAdvance,
        fetchAdvance,
        deleteAdvance,
        createPayment,
        updatePayment,
        fetchPaymentById,
        deletePayment,
        fetchCustomers,
        fetchConsignments,
        fetchFreightBills,
        fetchRoutesMiniList,
        fetchBranches,
        fetchHireSlips,
        fetchDivisions,
        fetchAllTransporters,
        fetchVehicleListMini,
        fetchTemplateByFormType,
        fetchLedgersGroups,
        fetchLedgers,
        fetchUsersByType,
        fetchCurrencyCodes,
        fetchJobListNewV2,
        fetchPayments,
        fetchExpenses,
        fetchAccountConfigList,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        groupId: state.local.defaultGroupId,
        loggedInUser: state.settings.loggedInUser,
        customers: get(state, 'customers.customers', []),
        consignments: state.consignments.consignments,
        routeList: state.routes.routeListMiniNew,
        divisions: state.consignments.divisions,
        transporters: state.transporters.transporterList,
        miniVehicleList: get(state, 'vehicles.vehicleListMini', []),
        ledgers: state.consignments.ledgers,
        ledgersGroups: get(state.consignments.accountGroups, 'content'),
        currencyCodes: state.settings.currencyCodes,
        accountData: get(state, 'settings.account'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditPaymentFormComponent);
