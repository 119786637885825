import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SapWaiting = (props) => {
    return (_jsxs("svg", { id: "a", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", height: 24, width: 24, children: [_jsx("path", { fill: "#1c3254", d: "M12,21.5c-1.31,0-2.55-.25-3.71-.75-1.16-.5-2.16-1.18-3.02-2.03-.86-.85-1.53-1.86-2.03-3.02-.5-1.16-.75-2.39-.75-3.7s.25-2.55.75-3.71c.5-1.16,1.18-2.16,2.03-3.02s1.86-1.53,3.02-2.03c1.16-.5,2.39-.75,3.7-.75s2.55.25,3.71.75,2.16,1.18,3.02,2.03,1.53,1.86,2.03,3.02c.5,1.16.75,2.39.75,3.7s-.25,2.55-.75,3.71c-.5,1.16-1.18,2.16-2.03,3.02-.85.86-1.86,1.53-3.02,2.03-1.16.5-2.39.75-3.7.75ZM12,20c2.23,0,4.13-.78,5.68-2.33s2.33-3.44,2.33-5.68-.78-4.13-2.33-5.68-3.44-2.33-5.68-2.33-4.13.78-5.68,2.33-2.33,3.44-2.33,5.68.78,4.13,2.33,5.68,3.44,2.33,5.68,2.33Z" }), _jsx("path", { fill: "#1c3254", d: "M9.6,16.68h4.79v-1.76c0-.67-.23-1.24-.7-1.71-.47-.47-1.03-.71-1.7-.71s-1.23.24-1.7.71c-.47.47-.7,1.04-.7,1.71v1.76ZM12,11.49c.67,0,1.23-.24,1.7-.71s.7-1.04.7-1.71v-1.76h-4.79v1.76c0,.67.23,1.24.7,1.71.47.47,1.03.71,1.7.71ZM7.61,17.56v-.88h1.11v-1.76c0-.66.18-1.26.54-1.8.36-.54.84-.92,1.43-1.13-.6-.21-1.07-.59-1.43-1.13s-.54-1.14-.54-1.8v-1.76h-1.11v-.88h8.78v.88h-1.11v1.76c0,.66-.18,1.26-.54,1.8-.36.54-.84.92-1.43,1.13.6.21,1.07.59,1.43,1.13.36.54.54,1.14.54,1.8v1.76h1.11v.88H7.61Z" })] }));
};
const SapWaitingIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SapWaiting, ...props });
};
export default SapWaitingIcon;
