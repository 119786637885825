import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { fetchCustomFieldsForAccount } from '../actions/dashboard/custom_field_actions';
import { get, filter, map, includes, set, isEmpty, forEach } from 'lodash';
import { find } from 'lodash';
import { isShreeCementRMCAccount } from './account_utils';
export const CUSTOM_ACCOUNT_FIELD_TYPE = {
    JOB_ANALYTICS: 'JOB_ANALYTICS',
    JOB_ANALYTICS_CONSIGNOR: 'JOB_ANALYTICS_CONSIGNOR',
    JOB: 'JOB',
    ADDRESS_BOOK: 'ADDRESS_BOOK',
    CUSTOMER: 'CUSTOMER',
    VEHICLE: 'VEHICLE',
    CUSTOMER_INVOICE_EXPENSES: 'CUSTOMER_INVOICE_EXPENSES',
    USER: 'USER',
    INDENT: 'INDENT',
    JOB_BOOKING: 'JOB_BOOKING',
    CONTRACTS: 'CONTRACTS',
    FREIGHT_SETTLEMENT: 'BILL_SUMMARY',
    ISSUE: 'ISSUE',
    TMS_CONSIGNMENT: 'TMS_CONSIGNMENT',
    FUEL: 'FUEL_LOG',
    TMS_CONSIGNMENT_MATERIAL: 'TMS_CONSIGNMENT_MATERIAL',
    ERP_CONSIGNMENT: 'ERP_CONSIGNMENT',
    ERP_CONSIGNMENT_MATERIAL: 'ERP_CONSIGNMENT_MATERIAL',
    FREIGHT_BILL: 'FREIGHT_BILL',
    ERP_CONSIGNMENT_DRAFT: 'ERP_CONSIGNMENT_DRAFT',
    SERVICE_ENTRY: 'SERVICE_ENTRY',
    BRANCH: 'BRANCH',
    VENDOR: 'VENDOR',
    EPOD: 'EPOD',
    TRIP_SHEET: 'TRIP_SHEET',
    SPOT_AUCTION: 'AUCTION',
    ORDER: 'TMS_ORDER',
};
export const CUSTOM_FIELD_TYPE_OPTION = [
    { value: 'JOB_ANALYTICS', label: 'job: Show in route point' },
    { value: 'JOB_ANALYTICS_CONSIGNOR', label: 'job: Show in consignment details ' },
    { value: 'JOB', label: 'job: Show in job details ' },
    { value: 'ADDRESS_BOOK', label: 'Show in address book' },
    { value: 'CUSTOMER', label: 'Show in customer form' },
    { value: 'VEHICLE', label: 'Show in vehicle form' },
    { value: 'CLIENT_RATE_CHART', label: 'Show in client rate chart form' },
    { value: 'CUSTOMER_INVOICE_EXPENSES', label: 'Show in invoice expense' },
    { value: 'USER', label: 'Show in people form' },
    { value: 'JOB_BOOKING', label: 'Show in job booking' },
    { value: 'INDENT', label: 'Show in indent' },
    { value: 'CONTRACTS', label: 'Show in contracts' },
    { value: 'BILL_SUMMARY', label: 'Show in freight settlement' },
    { value: 'ISSUE', label: 'Show in issue' },
    { value: 'TMS_CONSIGNMENT', label: 'Show in Consignment' },
    { value: 'FUEL_LOG', label: 'Show in Fuel' },
    { value: 'TMS_CONSIGNMENT_MATERIAL', label: 'Show in Tms consigment material' },
    { value: 'ERP_CONSIGNMENT', label: 'Show in ERP Consignment' },
    { value: 'ERP_CONSIGNMENT_MATERIAL', label: 'Show In ERP Consignment Material' },
    { value: 'FREIGHT_BILL', label: 'Show In ERP Consignment Bill Details' },
    { value: 'SERVICE_ENTRY', label: 'Show in ERP Service Entry' },
    { value: 'BRANCH', label: 'Show in Branch master' },
    { value: 'VENDOR', label: 'Show in Vendor master' },
    { value: 'EPOD', label: "Show in add EPOD's" },
    { value: 'TRIP_SHEET', label: 'Show in Tripsheet' },
    { value: 'HIRE_SLIP', label: 'Show in Hire Slip' },
    { value: 'WORK_ORDER', label: 'Show in Work Order' },
    { value: CUSTOM_ACCOUNT_FIELD_TYPE.SPOT_AUCTION, label: 'Show in Spot Auction' },
    { value: CUSTOM_ACCOUNT_FIELD_TYPE.ORDER, label: 'Show in Order Details' },
];
export const QUESTION_TYPE = [
    { value: 'MCQ_DROPDOWN', label: 'MCQ' },
    // { value: 'BOOLEAN', label: 'BOOLEAN' },
    { value: 'STRING', label: 'STRING' },
    { value: 'DATE', label: 'DATE' },
    { value: 'USER_TYPE', label: 'USER TYPE' },
];
export function mappedCustomFields(customFields, type) {
    const customFieldsOptions = [];
    map(customFields, (o) => {
        if (o.type && o.type === type) {
            customFieldsOptions.push({
                value: o.id,
                label: o.keyName,
                type: o.type,
                mandatory: o.mandatory,
            });
        }
    });
    return customFieldsOptions;
}
export const getCustomFieldObjectFromIds = async (allCustomFieldsPresent, accesstoken) => {
    const resp = await fetchCustomFieldsForAccount(accesstoken).promise;
    const customFieldsInAcnt = get(resp, 'data.customFields', []);
    const customFieldObjects = filter(customFieldsInAcnt, (customField) => {
        return includes(allCustomFieldsPresent, customField.id);
    });
    return customFieldObjects;
};
export const getCustomFieldObjectFromKeyName = (customFieldList, key) => {
    let customField = {};
    map(customFieldList, (cf) => {
        if (find(cf.field, () => {
            return cf.field.keyName === key;
        })) {
            customField = cf;
        }
    });
    return customField;
};
export const getFormattedField = (field, value) => {
    const formattedField = {};
    set(formattedField, 'value', field.id);
    set(formattedField, 'label', field.keyName);
    set(formattedField, 'mandatory', field.mandatory);
    set(formattedField, 'type', field.type);
    set(formattedField, 'data', value);
    return formattedField;
};
export const prepareCustomFieldsForSubmit = (customFields) => {
    const returnCustomFields = [];
    if (customFields) {
        map(customFields, (cf) => {
            if (!isEmpty(cf)) {
                const id = Object.keys(cf)[0];
                const value = cf[id];
                const field = {
                    field: {
                        id: +id,
                    },
                    value: value,
                };
                if (+id > 0) {
                    returnCustomFields.push(field);
                }
            }
        });
    }
    return returnCustomFields;
};
export function createCustomFields(customFields) {
    let newCustomFields = [];
    let field = {};
    map(customFields, (cf) => {
        if (cf.field && cf.field.id) {
            field = {
                [cf.field.id]: cf.value,
            };
            newCustomFields.push(field);
        }
    });
    return { customFields: newCustomFields };
}
export const getInitialCustomFieldArray = (fields) => {
    return map(fields, (field) => {
        let cfObj = {
            'field': {
                id: get(field, 'field.id'),
                keyName: get(field, 'field.keyName'),
                type: get(field, 'field.type'),
                fieldType: get(field, 'field.fieldType'),
                answers: get(field, 'field.answers'),
                mandatory: get(field, 'field.mandatory'),
            },
            value: field.value ? field.value : '',
        };
        return cfObj;
    });
};
export const getCustomFieldFilterPath = (fieldKey) => {
    return `customFieldFilter.${fieldKey}`;
};
export const CustomFieldLabel = ({ label }) => {
    return (_jsxs("div", { className: "text-left", children: [label, _jsx("sup", { className: "text-info", style: { fontSize: 10 }, children: "Custom" }), ' '] }));
};
export const arrangeCustomFields = (fields) => {
    return map(fields, (field) => {
        return { field: { ...field }, value: field.value || '' };
    });
};
export const getMandatoryFieldsType = (fields, type) => {
    return filter(fields, (customField) => {
        return customField.field.mandatory && customField.field.type === type;
    });
};
export const getFieldName = (fieldPrefix, index, name) => {
    return `${fieldPrefix}.${index}.${name}`;
};
export const getNameDropDownObject = (v) => {
    const finalArray = [];
    for (let i = 0; i < v.length; i++) {
        finalArray.push({
            value: v[i].answer,
            label: v[i].answer,
        });
    }
    return finalArray;
};
export const handleCustomFieldsNull = (customFields) => {
    const filteredCustomFields = filter(customFields, (cf) => get(cf, 'field.id'));
    forEach(filteredCustomFields, (cf) => {
        if (get(cf, 'value') == null) {
            set(cf, 'value', '');
        }
    });
    return filteredCustomFields;
};
export const arrangeCustomFieldsFromResponse = ({ customFieldsMerged, customFieldsResp, type }) => {
    const allCustomFields = arrangeCustomFields(customFieldsResp);
    forEach(allCustomFields, (cf) => {
        const isCFPresentInsideInitialVal = find(customFieldsMerged, (cfInIntialValue) => {
            return get(cfInIntialValue, 'field.id') == get(cf, 'field.id');
        });
        if (!isCFPresentInsideInitialVal && type == get(cf, 'field.type')) {
            customFieldsMerged.push(cf);
        }
    });
    return customFieldsMerged;
};
export const cfFieldsOrder = [
    'TM Reg number',
    'VTS KM',
    'Google KM',
    'Base KM',
    'Ticket Number',
    'Delivery Number',
    'Invoice Number',
    'PH Recommended KM',
    'Remarks by PH',
    'Approved By PH',
    'RHO Recommended KM',
    'Remarks by RHO',
    'Approved By RHO',
    'Final Odometer Reading',
    'Odometer Reading From (KMs)',
    'Odometer Reading To (KMs)',
    'Travel Time As Per VTS',
    'Avg. No. Of Satellites',
    'AvgSignalStrength',
    'AvgNoOfSatellites',
    'Avg. Signal Strength',
    'Is Indicative KM',
    'Indicative Reason',
    'Force Close Changes',
    'Plant Out Date Time',
    'Site In Date Time',
    'Site Out Date Time',
    'Plant In Date Time',
    'Plant Out',
    'Plant In',
    'Plant Code',
    'Plant Name',
    'Project Code',
    'Project Name',
    'Invoice type',
    'Customer Category',
    'Ticket Issue Date and Time',
    'Sales Order Qty',
    'Sales Order Qty UOM',
    'FG Code',
    'FG Code Description',
    'Parent Ticket Code',
    'Sales office Code',
    'Sales office Name',
    'RMC Region Code',
    'RMC Region Name',
    'RMC Zone Code',
    'RMC Zone Name',
    'Vendor Code',
    'Vendor Name',
    'Remark',
    'Sales Order Number',
    'Ticket Qty M3',
    'Site Gate 1 Latitude',
    'Site Gate 1 Longitude',
    'Site Gate 1 Radius',
    'Site Gate 2 Latitude',
    'Site Gate 2 Longitude',
    'Site Gate 2 Radius',
    'Site Gate 3 Latitude',
    'Site Gate 3 Longitude',
    'Site Gate 3 Radius',
    'Site Gate 4 Latitude',
    'Site Gate 4 Longitude',
    'Site Gate 4 Radius',
    'Remarks by RHO',
    'RHO Remarks',
    'Remarks by PH',
    'PH Remarks',
    'Shiptype',
    'TM Driver name',
    'TM Driver Mobile Number',
    'Pump Code 1',
    'Pump 1 Site Structure',
    'Pump 1 Height',
    'Pump 1 Height UOM',
    'Pump 1 Pumping start Date and Time ',
    'Pump 1 Pumping end Date and Time',
    'Pump 1 Qty. (M3)',
    'Pump 1 Remarks',
    'Pump Code 2',
    'Pump 2 Site Structure',
    'Pump 2 Height',
    'Pump 2 Height UOM',
    'Pump 2 Start date and time',
    'Pump 2 end Date and Time',
    'Pump 2 Qty. (M3)',
    'Pump 2 Remarks',
    'Pump Code 3',
    'Pump 3 Site Structure',
    'Pump 3 Height',
    'Pump 3 Height UOM',
    'Pump 3 Start date and time',
    'Pump 3 End Date and Time',
    'Pump 3 Qty. (M3)',
    'Pump 3 Remarks',
    'Customer PUMP Qty',
    'Dump Qty',
    'Wastage Qty. (M3)',
    'Wastage Remark',
    'Return Qty. (M3)',
    'Return Qty. Remark',
];
export const alsoIncludeNonMandatoryHidden = [2816, 2817]; // IsSubmitted, IsApprovedbyRHO
const customFiltersVisibleForAccount = [
    2904,
    2908,
    2909,
    2910,
    2911,
    2912,
    2913,
    2914,
    2915,
    2916,
    2917,
    2918,
    2919,
    2920,
    2921,
    2922,
    2923,
    2924,
    2925,
    2926,
];
export const getCustomFieldOptionsForAccount = (allCustomFields) => {
    const options = allCustomFields
        .filter((field) => {
        if (isShreeCementRMCAccount()) {
            return customFiltersVisibleForAccount.includes(field.id);
        }
        return true;
    })
        .map((field) => ({
        label: field.keyName,
        value: field.id,
    }));
    return options;
};
