import { mappedOptionsFromArray } from 'components/utils/mapping_utils';
export const REPORT_TEMPLATE_TYPES = {
    LR: 'LR',
    AUTO_INVOICE: 'AUTO_INVOICE',
    SINGLE_VEHICLE_MANUAL_INVOICE: 'SINGLE_VEHICLE_MANUAL_INVOICE',
    MULTI_VEHICLE_MANUAL_INVOICE: 'MULTI_VEHICLE_MANUAL_INVOICE',
    WORK_ORDER: 'WORK_ORDER',
    SERVICE_HISTORY: 'SERVICE_HISTORY',
    PERSON_DETAILS: 'PERSON_DETAILS',
    SUPPLEMENTARY_NON_LOGISTICS_INVOICE: 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE',
    SUPPLEMENTARY_LOGISTICS_INVOICE: 'SUPPLEMENTARY_LOGISTICS_INVOICE',
    FREIGHT_INVOICE: 'FREIGHT_INVOICE',
    CONSIGNMENT: 'CONSIGNMENT',
    CONSIGNMENT_DRAFT: 'CONSIGNMENT_DRAFT',
    JOB_DETAILS: 'JOB_DETAILS',
    FUEL_LOG: 'FUEL_LOG',
    FREIGHT_BILL_TMS: 'FREIGHT_BILL_TMS',
    LEDGERS: 'LEDGERS',
    CHALLAN: 'CHALLAN',
    ERP_ARRIVAL: 'ERP_ARRIVAL',
    ERP_MONEY_RECEIPT: 'ERP_MONEY_RECEIPT',
    TYRES_BILL: 'TYRES_BILL',
    PARTS_BILL: 'PARTS_BILL',
    HIRE_SLIP: 'HIRE_SLIP',
    OUTWARD_TYRE: 'OUTWARD_TYRE',
    TRIPSHEET: 'TRIPSHEET',
    TRANSACTIONS: 'TRANSACTIONS',
    VOUCHER: 'VOUCHER',
    SALARY: 'SALARY',
    PART_ISSUANCE: 'PART_ISSUANCE',
    TYRE_INWARD: 'TYRE_INWARD',
    BATTERY_INWARD: 'BATTERY_INWARD',
    ISSUES: 'ISSUES',
    PART_ENTRIES: 'PART_ENTRIES',
    FTL_CONTRACTS: 'FTL_CONTRACTS',
    PTL_CONTRACTS: 'PTL_CONTRACTS',
    INTERNAL_INVOICE: 'INTERNAL_INVOICE',
    VEHICLE_PNL: 'VEHICLE_PNL',
    MULTIPLE_HIRE_SLIP: 'MULTIPLE_HIRE_SLIP',
    PURCHASE_ORDER: 'PURCHASE_ORDER',
    JOB_BOOKING: 'JOB_BOOKING',
};
export const REPORT_TEMPLATE_TYPES_LABELS = {
    LR: 'LR',
    AUTO_INVOICE: 'Auto Invoice',
    SINGLE_VEHICLE_MANUAL_INVOICE: 'Single vehicle manual invoice',
    MULTI_VEHICLE_MANUAL_INVOICE: 'Multi vehicle manual invoice',
    WORK_ORDER: 'Work Order',
    SERVICE_HISTORY: 'Service History',
    PERSON_DETAILS: 'Person Details',
    SUPPLEMENTARY_NON_LOGISTICS_INVOICE: 'Non Supplementary Bill',
    SUPPLEMENTARY_LOGISTICS_INVOICE: 'Supplementary Bill',
    FREIGHT_INVOICE: 'Freight Bill',
    CONSIGNMENT: 'Consignment',
    CONSIGNMENT_DRAFT: 'Consignment Draft',
    JOB_DETAILS: 'Job Details',
    FUEL_LOG: 'Fuel Log',
    FREIGHT_BILL_TMS: 'Freight Bill TMS',
    LEDGERS: 'Ledgers',
    CHALLAN: 'Challan',
    ERP_ARRIVAL: 'ERP Arrival',
    ERP_MONEY_RECEIPT: 'ERP Money Receipt',
    TYRES_BILL: 'Tyres Bill',
    PARTS_BILL: 'Parts Bill',
    HIRE_SLIP: 'Hire Slip',
    OUTWARD_TYRE: 'Outward Tyre',
    TRIPSHEET: 'Trip Sheet',
    TRANSACTIONS: 'Transactions',
    VOUCHER: 'Voucher',
    SALARY: 'Salary',
    PART_ISSUANCE: 'Part Issuance',
    TYRE_INWARD: 'Tyre Inward',
    BATTERY_INWARD: 'Battery Inward',
    ISSUES: 'Issues',
    PART_ENTRIES: 'Part Entries',
    FTL_CONTRACTS: 'FTL Contracts',
    PTL_CONTRACTS: 'PTL Contracts',
    INTERNAL_INVOICE: 'Internal Invoice',
    VEHICLE_PNL: 'Vehicle Profit and Loss',
    MULTIPLE_HIRE_SLIP: 'Multiple Hire Slip',
    PURCHASE_ORDER: 'Purchase Order',
    JOB_BOOKING: 'Job Booking',
};
export function mappedReportTemplates(list) {
    return mappedOptionsFromArray(list);
}
export function getTemplateSampleFile(formatType) {
    return `/public/docx/reportTemplates/${formatType}.docx`;
}
export const REPORT_TEMPLATE_IDS_NOT_BE_ACTIONED = [13, 14, 15, 16];
export function reportTemplateHelpfulLinks() {
    return [
        {
            url: 'https://carbone.io/templates-examples.html',
            label: window.t('viewTemplateExamples'),
            mode: 'read',
        },
        {
            url: 'https://carbone.io/documentation.html#building-a-template',
            label: window.t('buildingTemplates'),
            mode: 'read',
        },
        {
            url: 'https://carbone.io/documentation.html#basic',
            label: window.t('usingVariables'),
            mode: 'read',
        },
        {
            url: 'https://carbone.io/documentation.html#simple-array',
            label: window.t('renderingLists'),
            mode: 'read',
        },
        {
            url: 'https://carbone.io/documentation.html#dynamic-parameters',
            label: window.t('formattingData'),
            mode: 'read',
        },
        {
            url: 'https://youtu.be/xlthDth2S6Q',
            label: window.t('watchThisTutorial'),
            mode: 'watch',
        },
    ];
}
