import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_ADVANCE = 'CREATE_ADVANCE';
export const FETCH_ADVANCES = 'FETCH_ADVANCES';
export const FETCH_ADVANCE = 'FETCH_ADVANCE';
export const UPDATE_ADVANCE = 'UPDATE_ADVANCE';
export const DELETE_ADVANCE = 'DELETE_ADVANCE';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_PAYMENT = 'FETCH_PAYMENT';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const FETCH_COLLECTION_SUMMARY = 'FETCH_COLLECTION_SUMMARY';
export const FETCH_CLIENT_COLLECTION_LISTING = 'FETCH_CLIENT_COLLECTION_LISTING';
export const FETCH_VENDOR_COLLECTION_LISTING = 'FETCH_VENDOR_COLLECTION_LISTING';
export const FETCH_TRANSPORTER_COLLECTION_LISTING = 'FETCH_TRANSPORTER_COLLECTION_LISTING';
export const DOWNLOAD_OUTSTANDING_REPORT = 'DOWNLOAD_OUTSTANDING_REPORT';
export const ON_ACCOUNT_CREATE_PAYMENT = 'ON_ACCOUNT_CREATE_PAYMENT';
export const ON_ACCOUNT_CREATE_RECEIPT = 'ON_ACCOUNT_CREATE_RECEIPT';
export const ON_ACCOUNT_UPDATE = 'ON_ACCOUNT_UPDATE';
export const FETCH_ALL_BILL_NUMBERS = 'FETCH_ALL_BILL_NUMBERS';
export const CREATE_ADJUSTMENT_NOTES = 'CREATE_ADJUSTMENT_NOTES';
export const CREATE_BULK_PAYMENTS = 'CREATE_BULK_PAYMENTS';
export const FETCH_ADJUSTMENT_NOTES = 'FETCH_ADJUSTMENT_NOTES';
export const FETCH_CUSTOMER_OVERVIEW = 'FETCH_CUSTOMER_OVERVIEW';
export const FETCH_RECEIVABLES_SUMMARY = 'FETCH_RECEIVABLES_SUMMARY';
export const FETCH_RECEIVABLES_CLIENT_COLLECTION_LISTING = 'FETCH_RECEIVABLES_CLIENT_COLLECTION_LISTING';
export const FETCH_RECEIVABLES_LEDGER_ENTRIES = 'FETCH_RECEIVABLES_LEDGER_ENTRIES';
export function createAdvance(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}consignment/advance`, data, config);
    return {
        type: CREATE_ADVANCE,
        promise: request,
    };
}
export function updateAdvance(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}consignment/advance`, data, config);
    return {
        type: UPDATE_ADVANCE,
        promise: request,
    };
}
export function deleteAdvance(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}consignment/advance/${id}`, config);
    return {
        type: DELETE_ADVANCE,
        promise: request,
    };
}
export function fetchAdvances(accesstoken, params = {}) {
    const { startDate, endDate, branchId, voucherType, adviceNumber, creditAccountId, voucherNumber } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            size: 7000,
            voucherNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/advance`, config);
    return {
        type: FETCH_ADVANCES,
        promise: request,
    };
}
export function fetchAdvance(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/advance/${id}`, config);
    return {
        type: FETCH_ADVANCE,
        promise: request,
    };
}
// New payment form actions
export function fetchPayments(accesstoken, params = {}) {
    const { startDate, endDate, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, unsettledOnAccount, settledPayment, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            branchId,
            paymentNature,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            unsettledOnAccount,
            size: 7000,
            settledPayment,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}payment`, config);
    return {
        type: FETCH_PAYMENTS,
        promise: request,
    };
}
export function fetchPaymentById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}payment/${id}`, config);
    return {
        type: FETCH_PAYMENT,
        promise: request,
    };
}
export function updatePayment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}payment`, data, config);
    return {
        type: UPDATE_PAYMENT,
        promise: request,
    };
}
export function createPayment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}payment`, data, config);
    return {
        type: CREATE_PAYMENT,
        promise: request,
    };
}
export function onAccountCreatePayment(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}on-account/payment`, data, config);
    return {
        type: ON_ACCOUNT_CREATE_PAYMENT,
        promise: request,
    };
}
export function onAccountUpdate(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}on-account`, data, config);
    return {
        type: ON_ACCOUNT_UPDATE,
        promise: request,
    };
}
export function onAccountCreateReceipt(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}on-account/receipt`, data, config);
    return {
        type: ON_ACCOUNT_CREATE_RECEIPT,
        promise: request,
    };
}
export function deletePayment(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}payment/${id}`, config);
    return {
        type: DELETE_PAYMENT,
        promise: request,
    };
}
export function fetchCollectionSummary(accesstoken, params = {}) {
    const { startDate, endDate, branchId, voucherType, adviceNumber, creditAccountId, voucherNumber, overdueDays, hireSlipDelivered, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromVoucherDate: startDate,
            toVoucherDate: endDate,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            overdueDays,
            hireSlipDelivered,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/unsettled/summary`, config);
    return {
        type: FETCH_COLLECTION_SUMMARY,
        promise: request,
    };
}
export function fetchClientCollectionListing(accesstoken, params = {}) {
    const { startDate, endDate, branchId, voucherType, adviceNumber, creditAccountId, page = 0, name, overdueDays = 0, ids, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromVoucherDate: startDate,
            toVoucherDate: endDate,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            nameLike: name,
            page,
            overdueDays,
            ids,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/unsettled-customers`, config);
    return {
        type: FETCH_CLIENT_COLLECTION_LISTING,
        promise: request,
    };
}
export function fetchVendorCollectionListing(accesstoken, params = {}) {
    const { startDate, endDate, branchId, voucherType, adviceNumber, creditAccountId, voucherNumber, page = 0, name, overdueDays = 0, ids, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromVoucherDate: startDate,
            toVoucherDate: endDate,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            page,
            nameLike: name,
            overdueDays,
            ids,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/unsettled-vendors`, config);
    return {
        type: FETCH_VENDOR_COLLECTION_LISTING,
        promise: request,
    };
}
export function fetchTransporterCollectionListing(accesstoken, params = {}) {
    const { startDate, endDate, branchId, voucherType, adviceNumber, creditAccountId, voucherNumber, page = 0, name, overdueDays = 0, ids, hireSlipDelivered, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromVoucherDate: startDate,
            toVoucherDate: endDate,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            page,
            nameLike: name,
            overdueDays,
            ids,
            hireSlipDelivered,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/unsettled-transporters`, config);
    return {
        type: FETCH_TRANSPORTER_COLLECTION_LISTING,
        promise: request,
    };
}
export function fetchLedgerEntries(accesstoken, params = {}) {
    const { startDate, endDate, vendorId, page = 0, branchId, voucherType, adviceNumber, creditAccountId, voucherNumber, size = 20, name, overdueDays = 0, clientId, transporterId, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            vendorId,
            page,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            size,
            name,
            overdueDays,
            clientId,
            transporterId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/unsettled-ledger-entries`, config);
    return {
        type: FETCH_TRANSPORTER_COLLECTION_LISTING,
        promise: request,
    };
}
export function downloadOutstandingReport(accesstoken, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/outstanding`, config);
    return {
        type: DOWNLOAD_OUTSTANDING_REPORT,
        promise: request,
    };
}
export function fetchReceivablesSummary(accesstoken, params = {}) {
    const { startDate, endDate } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromVoucherDate: startDate,
            toVoucherDate: endDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/receivable/summary`, config);
    return {
        type: FETCH_RECEIVABLES_SUMMARY,
        promise: request,
    };
}
export function fetchReceivablesClientCollectionListing(accesstoken, params = {}) {
    const { startDate, endDate, branchId, voucherType, adviceNumber, creditAccountId, page = 0, name, overdueDays = 0, ids, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromVoucherDate: startDate,
            toVoucherDate: endDate,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            nameLike: name,
            page,
            overdueDays,
            ids,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/receivable/customers`, config);
    return {
        type: FETCH_RECEIVABLES_CLIENT_COLLECTION_LISTING,
        promise: request,
    };
}
export function fetchReceivablesLedgerEntries(accesstoken, params = {}) {
    const { startDate, endDate, vendorId, page = 0, branchId, voucherType, adviceNumber, creditAccountId, voucherNumber, size = 20, name, overdueDays = 0, clientId, transporterId, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            vendorId,
            page,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            size,
            name,
            overdueDays,
            clientId,
            transporterId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/receivable/listing`, config);
    return {
        type: FETCH_RECEIVABLES_LEDGER_ENTRIES,
        promise: request,
    };
}
export function fetchBillNumbers(accesstoken, customerName, startDate, endDate) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            customerName,
            startDate,
            endDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}billnumbers`, config);
    return {
        type: FETCH_ALL_BILL_NUMBERS,
        promise: request,
    };
}
export function createAdjustmentNotes(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}adjustment-note`, data, config);
    return {
        type: CREATE_ADJUSTMENT_NOTES,
        promise: request,
    };
}
export function createBulkPayments(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}payment/bulk`, data, config);
    return {
        type: CREATE_BULK_PAYMENTS,
        promise: request,
    };
}
export function fetchAdjustmentNotes(accesstoken, params = {}) {
    const { startDate, endDate, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, unsettledOnAccount, isCreditNote, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            branchId,
            paymentNature,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            unsettledOnAccount,
            size: 7000,
            isCreditNote,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}adjustment-note`, config);
    return {
        type: FETCH_ADJUSTMENT_NOTES,
        promise: request,
    };
}
export function fetchCustomerOverview(accesstoken, params) {
    const { startDate, endDate, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, unsettledOnAccount, isCreditNote, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            branchId,
            paymentNature,
            adviceNumber,
            creditAccountId,
            voucherNumber,
            unsettledOnAccount,
            size: 7000,
            isCreditNote,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}customer-overview`, config);
    return {
        type: FETCH_CUSTOMER_OVERVIEW,
        promise: request,
    };
}
