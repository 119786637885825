import { jsx as _jsx } from "react/jsx-runtime";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FxCard from 'sharedV2/FxCard';
import { Select } from 'sharedV2/FxFormItems';
import { FxCol, FxRow } from 'sharedV2/FxGrid';
const SettlementFormModalComponent = (props) => {
    const { rowData } = props;
    console.log('rowData-->', rowData);
    const defaultFormData = {
        emiNumber: '',
        amount: 0,
        transactionId: null,
        stationary: null,
        tdsAmount: null,
        debitLedgerId: null,
        creditLedgerId: null,
        tdsLedgerEntry: {
            amount: 0,
            ledgerId: null,
        },
        narration: null,
        dateOfPayment: null,
        installmentNumber: null,
    };
    const [initialValues, setInitialValues] = useState(defaultFormData);
    const billTypeOptions = [
        { value: 'FREIGHT_BILL', label: 'Freight Bill' },
        { value: 'SUPPLEMENTARY_BILL', label: 'Supplementary Bill' },
        { value: 'NON_SUPPLEMENTARY_BILL', label: 'Non Supplementary Bill' },
    ];
    const addEditSchema = (values) => {
        return Yup.object().shape({
            billType: Yup.string()
                .nullable()
                .required('Bill Type is required')
                .transform((value, originalValue) => {
                return originalValue === '' || originalValue === undefined || originalValue === null ? null : value;
            }),
        });
    };
    const onSubmit = () => { };
    return (_jsx(FxCard, { children: _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: () => Yup.lazy((values) => {
                return addEditSchema(values);
            }), validateOnBlur: true, enableReinitialize: true, children: (formikProps) => {
                const { isSubmitting, values, errors, setFieldValue } = formikProps;
                console.log('errors-->', errors);
                return (_jsx(Form, { children: _jsx(FxRow, { gutter: 16, children: _jsx(FxCol, { xs: 24, sm: 24, md: 6, children: _jsx(Select, { name: "billType", label: "Bill Type", options: billTypeOptions }) }) }) }));
            } }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(SettlementFormModalComponent);
