import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Dispatch = (props) => {
    return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M7.33333 1.51668C7.54444 1.39445 7.76667 1.33334 8 1.33334C8.23333 1.33334 8.45556 1.39445 8.66667 1.51668L13.3333 4.20001C13.5444 4.32223 13.7083 4.48334 13.825 4.68334C13.9417 4.88334 14 5.10557 14 5.35001V8.18334C13.8 8.0389 13.5889 7.9139 13.3667 7.80834C13.1444 7.70279 12.9111 7.61112 12.6667 7.53334V6.06668L10.2667 7.45001C9.64444 7.60557 9.08056 7.86945 8.575 8.24168C8.06944 8.6139 7.65556 9.06668 7.33333 9.60001V8.38334L3.33333 6.06668V10.6333L6.7 12.5833C6.74444 12.9389 6.82778 13.2806 6.95 13.6083C7.07222 13.9361 7.22778 14.2445 7.41667 14.5333C7.39444 14.5222 7.38056 14.5139 7.375 14.5083C7.36944 14.5028 7.35556 14.4945 7.33333 14.4833L2.66667 11.8C2.45556 11.6778 2.29167 11.5167 2.175 11.3167C2.05833 11.1167 2 10.8945 2 10.65V5.35001C2 5.10557 2.05833 4.88334 2.175 4.68334C2.29167 4.48334 2.45556 4.32223 2.66667 4.20001L7.33333 1.51668ZM8 2.66668L4.05 4.95001L8 7.23334L11.95 4.95001L8 2.66668Z", fill: "#333333" }), _jsx("path", { fillRule: "evenodd", "clip-rule": "evenodd", d: "M11 15C12.6569 15 14 13.6569 14 12C14 10.3431 12.6569 9 11 9C9.34315 9 8 10.3431 8 12C8 13.6569 9.34315 15 11 15ZM12.7313 12.2625L13 12L11 10L9 12L9.26875 12.2625L10.8125 10.7188V14H11.1875V10.7188L12.7313 12.2625Z", fill: "#333333" })] }));
};
const DispatchIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Dispatch, ...props });
};
export default DispatchIcon;
