import { get, map } from 'lodash';
import { DATE_FORMAT_TIMESTAMP, getFormattedTimeStringForAPI } from 'utils/date_utils';
export const PAYMENTS_TABS = {
    PAYMENTS: 'PAYMENTS',
    ADJUSTMENT_NOTES: 'ADJUSTMENT_NOTES',
    CUSTOMER_OVERVIEW: 'CUSTOMER_OVERVIEW',
};
export const PAYMENTS_TABS_LABELS = {
    PAYMENTS: 'Payments',
    ADJUSTMENT_NOTES: 'Adjustment Notes',
    CUSTOMER_OVERVIEW: 'Customer Overview',
};
export const PAYMENTS_SUB_TABS = {
    UNSETTLED: 'UNSETTLED',
    SETTLED: 'SETTLED',
    ALL_PAYMENTS: 'ALL_PAYMENTS',
};
const PAYMENTS_SUB_TABS_LABELS = {
    UNSETTLED: 'Unsettled',
    SETTLED: 'Settled',
    ALL_PAYMENTS: 'All Payments',
};
export const ADJUSTMENT_NOTES_SUB_TABS = {
    CREDIT_NOTE: 'CREDIT_NOTE',
    DEBIT_NOTE: 'DEBIT_NOTE',
};
const ADJUSTMENT_NOTES_SUB_TABS_LABELS = {
    CREDIT_NOTE: 'Credit Note',
    DEBIT_NOTE: 'Debit Note',
};
export const tabOptions = map(PAYMENTS_TABS, (value, key) => {
    if (value === PAYMENTS_TABS.PAYMENTS) {
        return {
            key: value,
            label: PAYMENTS_TABS_LABELS[key],
            subTabs: map(PAYMENTS_SUB_TABS, (subValue, subKey) => ({
                key: subValue,
                label: PAYMENTS_SUB_TABS_LABELS[subKey],
            })),
        };
    }
    else if (value === PAYMENTS_TABS.ADJUSTMENT_NOTES) {
        return {
            key: value,
            label: PAYMENTS_TABS_LABELS[key],
            subTabs: map(ADJUSTMENT_NOTES_SUB_TABS, (subValue, subKey) => ({
                key: subValue,
                label: ADJUSTMENT_NOTES_SUB_TABS_LABELS[subKey],
            })),
        };
    }
    return {
        key: value,
        label: PAYMENTS_TABS_LABELS[key],
    };
});
export const mappedBillNumbers = (bills) => {
    return map(bills, (bill) => ({
        label: get(bill, 'basicDetails.billNumber', ''),
        value: get(bill, 'id', null),
    }));
};
export const paymentTypeOptions = [
    { label: 'Credit Note', value: 'Credit Note' },
    { label: 'Debit Note', value: 'Debit Note' },
];
export const prepareAdjustmentPayloadData = (values) => {
    return {
        branchId: values.branch,
        creditStationaryId: values.creditStationaryId,
        debitStationaryId: values.debitStationaryId,
        customerAdjustmentNotes: values.sections.map((section) => ({
            customerId: section.customerId,
            adjustmentNotes: section.entries.map((entry) => ({
                appliedDate: getFormattedTimeStringForAPI(entry.date, DATE_FORMAT_TIMESTAMP),
                isCreditNote: entry.paymentType === 'Credit Note' ? true : false,
                adviceNumber: entry.adviceNo,
                paymentAmount: Number(entry.amount),
                referenceId: entry.billId,
                remarks: entry.remarks,
            })),
        })),
    };
};
