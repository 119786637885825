import { includes } from 'lodash';
import { getFlagValueFromAccountConfig } from 'utils/account_utils';
export function disableOSMapsOnSharedPageByDefault(accountId) {
    return includes([10049, 4304, 11719, 14375, 14329, 14482], accountId);
}
export function isValidMapPosition(position) {
    const { lat, lng, latitude, longitude } = position || {};
    if ((lat && lng) || (latitude && longitude)) {
        return true;
    }
    return false;
}
export function removeCountryRestrictionOnGooglePlaceAPISearchForAccountFlag() {
    return getFlagValueFromAccountConfig('removeCountryRestrictionOnGooglePlaceAPISearchForAccount');
}
export function useVehicleSizeInRouteBudget() {
    return getFlagValueFromAccountConfig('useVehicleSizeInRouteBudget');
}
