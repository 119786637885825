import { map, get } from 'lodash';
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Component, ReactPropTypes } from 'react';
export const prepareResultForUI = (remoteData) => {
    const { headers, data, metaData } = remoteData;
    const columns = getColumnsFromHeaders(headers);
    const totalSize = +get(data, 'length', 0);
    return {
        remoteData: remoteData,
        totalSize,
        columns,
        metaData,
        data,
    };
};

export const getColumnsFromHeaders = (headers, utilFuncs) => {
    const mappedHeaders = map(headers, (header) => {
        const style = header.style ? header.style : { width: '10rem' };
        const filterDetails = header.filterDetails;
        if (header.dataForamt) {
        }
        return {
            dataField: header.value,
            text: header.label,
            filter: getFilterType(header.filter) ? getFilterType(header.filter)({ ...filterDetails }) : null,
            headerStyle: style,
            hidden: header.hidden ? header.hidden : false,
            headerFormatter: headerFormatter,
            formatter: header.formatter,
            dataForamt: header.dataForamt,
            sort: header.sort,
            sortFunc: header.sortFunc,
            sortCaret: header.sortCaret,
        };
    });
    return mappedHeaders;
};

export const getFilterType = (filter) => {
    switch (filter) {
        case 'text':
            return textFilter;
        case 'select':
            return selectFilter;
        default:
            return null;
    }
};

export const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {filterElement}
            {column.text}
        </div>
    );
};
export const reportDownloadStatus = {
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
};

export const reportDownloadMessages = {
    FAILED: 'Something Went wrong. Please try again later!!',
    SUCCESS: 'Your request for report download has been successfully submitted.',
};
