import { ROLE_MAP } from 'constant';
import { isWithinScope } from '../utils';
import { get } from 'lodash';
import { getScopesArr } from 'components/dashboard/roles/permission_utils';
export function isManageJobBookingAllowedForUser(scopes) {
    return isWithinScope('MANAGE_JOB_BOOKING', scopes);
}
export function isTranporter() {
    const loggedInUser = window.FLEETX_LOGGED_IN_DATA;
    const roleName = get(loggedInUser, 'role', {});
    return roleName === ROLE_MAP.ROLE_TRANSPORTER.name;
}
export const isEWAYBillGenerateAllowed = (scopes) => {
    return (isWithinScope('CREATE_EWAY_BILL', getScopesArr(scopes)) &&
        isWithinScope('VIEW_TMS_CONSIGNMENT', getScopesArr(scopes)));
};
