import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { filter, get, includes, map } from 'lodash';
import { FxDeleteOutlined, FxPlusIcon } from 'sharedV2/FxIcons';
import { FxCol, FxRow, FxButton, FxCard, FxTable, FxSelectField, FxSelectSingleDateV2, FxTextFieldStackedLabelV2, FxToolTip, FxTypography, } from 'sharedV2';
import { Input } from 'sharedV2/FxFormItems';
import { fetchLedgers } from '../../../../actions/dashboard/consignments/ledgersActions';
import { createBulkPayments } from '../../../../actions/dashboard/payment_forms/actions';
import { fetchCustomers } from '../../../../actions/dashboard/finances/customer_action';
import { mappedCustomers } from 'utils/form_utils';
import { INSTRUMENT_TYPES } from 'utils/consignmentsUtils';
import { DATE_FORMAT_TIMESTAMP, getFormattedTimeStringForAPI } from 'utils/date_utils';
import { mappedObjectToOptions, mappedOptionsFromArray } from 'components/utils/mapping_utils';
import Bill_number_component_v2 from 'components/dashboard/bill_number/bill_number_component_v2';
import { handleError } from 'constant';
const initialValues = {
    branch: '',
    bookType: 'PAYMENT_RECEIPTS',
    recoveryLedgerId: '',
    payments: [
        {
            stationaryNumber: '',
            paymentAccountId: '',
            amount: '',
            paymentMode: '',
            instrumentDate: '',
            adviceNumber: '',
            instrumentNumber: '',
        },
    ],
};
const AddBulkPaymentFormComponent = (props) => {
    const { accesstoken, customers, ledgers } = props;
    const navigate = useNavigate();
    useEffect(() => {
        props.fetchCustomers(accesstoken);
        props.fetchLedgers(accesstoken);
    }, []);
    const goBack = () => {
        navigate(-1);
    };
    const addEditSchema = (values) => {
        return Yup.object().shape({
            branch: Yup.number()
                .nullable()
                .required('Branch is required')
                .transform((value, originalValue) => {
                return originalValue === '' || originalValue === undefined ? null : value;
            }),
            recoveryLedgerId: Yup.number()
                .nullable()
                .required('Recovery Ledger is required')
                .transform((value, originalValue) => {
                return originalValue === '' || originalValue === undefined ? null : value;
            }),
            payments: Yup.array().of(Yup.object().shape({
                paymentAccountId: Yup.string()
                    .nullable()
                    .required('Customer is required')
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                amount: Yup.number()
                    .nullable()
                    .required('Amount is required')
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                paymentMode: Yup.string()
                    .nullable()
                    .required('Payment Mode is required')
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                instrumentNumber: Yup.string()
                    .nullable()
                    .required('Instrument Number is required')
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                instrumentDate: Yup.date()
                    .required('Instrument Date is required')
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            })),
        });
    };
    const onSubmit = (values, action) => {
        const { setSubmitting } = action;
        let payload = {
            branchId: values.branch,
            stationaryId: values.stationaryId,
            paymentNature: 'PAYMENT_RECEIPT',
            recoveryLedgerId: values.recoveryLedgerId,
            payments: map(values.payments, (payment) => {
                return {
                    paymentAccountId: payment.paymentAccountId,
                    adviceNumber: payment.adviceNumber,
                    paymentAmount: payment.amount,
                    instrumentType: payment.paymentMode,
                    instrumentNumber: payment.instrumentNumber,
                    bankName: payment.bankName,
                    instrumentDate: getFormattedTimeStringForAPI(payment.instrumentDate, DATE_FORMAT_TIMESTAMP),
                };
            }),
        };
        console.log('Form payload:', payload);
        props
            .createBulkPayments(accesstoken, payload)
            .then((result) => {
            setSubmitting(false);
            if (!result.error) {
                navigate('/dashboard/payments');
            }
            else {
                throw new Error(handleError(result.payload.response));
            }
        })
            .catch((err) => {
            setSubmitting(false);
            alert(handleError(err));
        });
    };
    return (_jsx(FxCard, { className: "mt-2 mb-5", size: 'small', style: { height: 'calc(100% - 3rem)' }, styles: {
            body: {
                height: '100%',
            },
        }, children: _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: () => Yup.lazy((values) => {
                return addEditSchema(values);
            }), validateOnBlur: true, enableReinitialize: true, children: (formikProps) => {
                const { isSubmitting, values, errors, setFieldValue } = formikProps;
                console.log('errors-->', errors);
                return (_jsxs(Form, { style: { height: '100%' }, children: [_jsx(FxTypography.Title, { level: 4, children: 'Add Bulk Payment' }), _jsxs(FxRow, { gutter: 16, children: [_jsx(FxCol, { xs: 24, sm: 24, md: 18, children: _jsx(Bill_number_component_v2, { formikProps: formikProps, isEdit: false, isReadyToFetchBillNumber: true, colClass: 6, branchRequired: true, fieldDisabled: {
                                            'branch': false,
                                            'bookName': false,
                                            'stationaryNumber': false,
                                        }, onStationaryNumberPopulate: (stationaryNumber) => {
                                            setFieldValue('payments[0].stationaryNumber', stationaryNumber);
                                        }, hideStationaryActionButton: true }) }), _jsx(FxCol, { md: 6, children: _jsx(Field, { name: `recoveryLedgerId`, component: FxSelectField, label: "Debit Ledger", options: mappedOptionsFromArray(filter(ledgers, (ledger) => includes([6, 7, 41], get(ledger, 'group.baseGroupId'))), 'ledgerName'), isRequired: true }) })] }), _jsx(FxRow, { style: { marginTop: '10px' }, children: _jsx(FxCol, { xs: 24, sm: 24, md: 24, children: _jsx(FieldArray, { name: "payments", children: ({ remove, push }) => (_jsxs(_Fragment, { children: [_jsx(FxTable, { dataSource: values.payments, style: { marginTop: '20px' }, pagination: false, columns: [
                                                    // {
                                                    //     title: 'Stationary No.',
                                                    //     dataIndex: 'stationaryNumber',
                                                    //     key: 'stationaryNumber',
                                                    //     render: (text, record, index) => (
                                                    //         <Field
                                                    //             name={`payments[${index}].stationaryNumber`}
                                                    //             type="text"
                                                    //             component={FxTextFieldStackedLabelV2}
                                                    //             disabled
                                                    //         />
                                                    //     ),
                                                    //     width: 150,
                                                    // },
                                                    {
                                                        title: 'Customer Name',
                                                        dataIndex: 'paymentAccountId',
                                                        key: 'paymentAccountId',
                                                        render: (text, record, index) => (_jsx(Field, { name: `payments[${index}].paymentAccountId`, component: FxSelectField, placeholder: "Select Customer", options: mappedCustomers(customers) })),
                                                        width: 250,
                                                    },
                                                    {
                                                        title: 'Amount',
                                                        dataIndex: 'amount',
                                                        key: 'amount',
                                                        render: (text, record, index) => (_jsx(Field, { name: `payments[${index}].amount`, component: FxTextFieldStackedLabelV2, type: "text", placeholder: "Enter Amount" })),
                                                        width: 150,
                                                    },
                                                    {
                                                        title: 'Payment Mode',
                                                        dataIndex: 'paymentMode',
                                                        key: 'paymentMode',
                                                        render: (text, record, index) => (_jsx(Field, { name: `payments[${index}].paymentMode`, component: FxSelectField, placeholder: "Select Payment Mode", options: mappedObjectToOptions(INSTRUMENT_TYPES) })),
                                                        width: 150,
                                                    },
                                                    {
                                                        title: 'Instrument Date',
                                                        dataIndex: 'instrumentDate',
                                                        key: 'instrumentDate',
                                                        render: (text, record, index) => (_jsx(Field, { name: `payments[${index}].instrumentDate`, type: "text", component: FxSelectSingleDateV2, placeholder: "Select Date" })),
                                                        width: 200,
                                                    },
                                                    {
                                                        title: 'Instrument No.',
                                                        dataIndex: 'instrumentNumber',
                                                        key: 'instrumentNumber',
                                                        render: (text, record, index) => (_jsx(Field, { type: 'text', name: `payments[${index}].instrumentNumber`, component: FxTextFieldStackedLabelV2, placeholder: "Enter Instrument No." })),
                                                        width: 150,
                                                    },
                                                    {
                                                        title: 'Bank Name',
                                                        dataIndex: 'bankName',
                                                        key: 'bankName',
                                                        render: (text, record, index) => (_jsx(Field, { type: 'text', name: `payments[${index}].bankName`, component: FxTextFieldStackedLabelV2, placeholder: "Enter Bank Name" })),
                                                        width: 150,
                                                    },
                                                    {
                                                        title: 'Advice No.',
                                                        dataIndex: 'adviceNumber',
                                                        key: 'adviceNumber',
                                                        render: (text, record, index) => (_jsx(Input, { name: `payments[${index}].adviceNumber`, placeholder: "Enter Advice No." })),
                                                        width: 200,
                                                    },
                                                    {
                                                        title: 'Action',
                                                        key: 'action',
                                                        render: (text, record, index) => (_jsx(FxToolTip, { title: "Delete Entry", children: _jsx(FxButton, { type: "link", onClick: () => remove(index), icon: _jsx(FxDeleteOutlined, {}), style: { paddingBottom: '30px' } }) })),
                                                        width: 50,
                                                    },
                                                ] }), _jsx(FxToolTip, { title: "Create Entry", children: _jsx(FxButton, { type: "default", onClick: () => push({
                                                        paymentAccountId: '',
                                                        amount: '',
                                                        paymentMode: '',
                                                        instrumentDate: '',
                                                        adviceNumber: '',
                                                    }), icon: _jsx(FxPlusIcon, {}), style: { width: '6%', marginTop: '1rem', float: 'right' } }) })] })) }) }) }), _jsxs(FxRow, { style: {
                                width: '96%',
                                marginLeft: '-25px',
                                background: 'rgb(255 255 255)',
                                borderTop: '1px solid #ffffff',
                                boxShadow: 'rgb(242 242 242) 0px -4px 3px',
                                position: 'fixed',
                                bottom: 0,
                                alignItems: 'end',
                                zIndex: '100',
                            }, children: [_jsx(FxCol, { xs: 24, sm: 12, md: 12 }), _jsx(FxCol, { xs: 24, sm: 12, md: 12, className: "mt-2", children: _jsxs("div", { className: "mb-2 float-right", children: [_jsx("button", { type: "submit", className: "btn btn-primary mr-2", "data-testid": "payment-submit-button", disabled: isSubmitting, children: "Create Payments" }), _jsx("button", { type: "button", className: "btn btn-secondary mr-4", onClick: goBack, "data-testid": "payment-cancel-button", children: "Cancel" })] }) })] })] }));
            } }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCustomers,
        fetchLedgers,
        createBulkPayments,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        customers: get(state, 'customers.customers', []),
        ledgers: state.consignments.ledgers,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBulkPaymentFormComponent);
