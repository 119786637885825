import { Component } from 'react';
import { get } from 'lodash';
import { withToken } from '../theme';

class FxIcon extends Component {
    render() {
        const { fill, width, height, color, icon, className, onClick, stepney, id, isNewFuel } = this.props;
        const { cx, cy, stroke, payload, value, active } = this.props;
        const svg = {
            tyre: () => {
                return stepney ? (
                    <div
                        id={id}
                        key={`stepney-${id}`}
                        onClick={this.props.onClick}
                        style={{ backgroundColor: fill ? fill : '' }}
                        className={className ? `${className} truck-tyre-stepney` : 'truck-tyre-stepney'}
                    />
                ) : (
                    <div
                        id={id}
                        key={`tyre-${id}`}
                        onClick={this.props.onClick}
                        style={{ backgroundColor: fill ? fill : '' }}
                        className={className ? `${className} truck-tyre` : 'truck-tyre'}
                    />
                );
            },
            alarm: () => {
                return get(payload, 'isAlarm', false) ? (
                    isNewFuel ? (
                        <svg
                            x={cx - 8}
                            y={cy - 8}
                            width={active ? 36 : 18}
                            height={active ? 36 : 18}
                            viewBox={active ? '0 0 36 36' : '0 0 18 18'}
                            fill={active ? '#F86C6B' : `rgb(255, 255, 255)`}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.5 14c1.922 0 3.569-.686 4.941-2.059C13.814 10.57 14.5 8.921 14.5 7c0-1.922-.686-3.569-2.059-4.941C11.07.686 9.421 0 7.5 0 5.578 0 3.931.686 2.559 2.059 1.186 3.43.5 5.079.5 7c0 1.922.686 3.569 2.059 4.941C3.93 13.314 5.579 14 7.5 14z"
                                fill={get(payload, 'subType') === 'fill' ? '#1BC44A' : '#F86C6B'}
                                fillRule="evenodd"
                            />
                        </svg>
                    ) : (
                        <svg
                            x={cx - 8}
                            y={cy - 8}
                            width={active ? 36 : 18}
                            height={active ? 36 : 18}
                            viewBox={active ? '0 0 36 36' : '0 0 18 18'}
                            fill={active ? '#F86C6B' : `rgb(255, 255, 255)`}
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>alarm icon</title>
                            <desc>Created with Sketch.</desc>
                            <g
                                id="alarm-icon"
                                stroke={active ? (get(payload, 'subType') === 'fill' ? '#4BDD74' : '#F86C6B') : 'none'}
                                strokeWidth="2"
                            >
                                <g
                                    id="Group"
                                    fill={get(payload, 'subType') === 'fill' ? '#4BDD74' : '#F86C6B'}
                                    fillRule="nonzero"
                                >
                                    <path
                                        d="M7.5,15 C11.6421,15 15,11.6421 15,7.5 C15,3.3579 11.6421,0 7.5,0 C3.3579,0 0,3.3579 0,7.5 C0,11.6421 3.3579,15 7.5,15 Z M7.5,2.25 C10.395,2.25 12.75,4.6053 12.75,7.5 C12.75,10.395 10.395,12.75 7.5,12.75 C4.605,12.75 2.25,10.395 2.25,7.5 C2.25,4.6053 4.605,2.25 7.5,2.25 Z"
                                        id="Shape"
                                    ></path>
                                    <path
                                        d="M7.5,10 C8.87866667,10 10,8.87866667 10,7.5 C10,6.12133333 8.87866667,5 7.5,5 C6.12133333,5 5,6.12133333 5,7.5 C5,8.87866667 6.12133333,10 7.5,10 Z M7.5,6.66666667 C7.95933333,6.66666667 8.33333333,7.04066667 8.33333333,7.5 C8.33333333,7.95933333 7.95933333,8.33333333 7.5,8.33333333 C7.04066667,8.33333333 6.66666667,7.95933333 6.66666667,7.5 C6.66666667,7.04066667 7.04066667,6.66666667 7.5,6.66666667 Z"
                                        id="Shape"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    )
                ) : (
                    <circle
                        cx={cx}
                        cy={cy}
                        r={active ? 6 : 3}
                        stroke={`rgb(52, 169, 255`}
                        strokeWidth={1}
                        fill={active ? this.props.token.colorPrimary : `rgb(255, 255, 255)`}
                        className="recharts-dot"
                    />
                );
            },
            default: () => {
                return (
                    <svg
                        fill={color ? color : '#cccccc'}
                        width={width ? width : 24}
                        height={height ? height : 24}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 7h-6v13h-2v-6h-2v6H9V9H3V7h18v2z" />
                    </svg>
                );
            },
        };
        return svg[icon || 'default']();
    }
}

export default withToken(FxIcon);
