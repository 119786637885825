import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, filter, find, includes } from 'lodash';
import { Field } from 'formik';
import { FxSkeleton } from '../../../../shared';
import { getMappedBookTypes, mappedBranches, mappedStationaries } from '../../../utils/mapping_utils';
import { fetchStationaries, getNextStationary, validateStationary, fetchStationary, } from 'actions/dashboard/stationary/actions';
import { fetchBranches } from 'actions/dashboard/branch/actions';
import { isViewBranchAllowedForUser, isViewStationaryAllowedForUser } from '../../roles/permission_utils';
import { FxCol, FxRow, FxSelectField, FxTextFieldStackedLabelV2 } from '../../../../sharedV2';
class AdjustmentBillComponent extends Component {
    initalCreditStationaryId = null;
    initalDebitStationaryId = null;
    initalCreditStationaryNumber = null;
    initalDebitStationaryNumber = null;
    branchSetRef = false;
    state = {
        allCreditStationaries: [],
        allDebitStationaries: [],
        stationaryNature: null,
        isBranchesLoading: true,
        allBranches: [],
        deletedStationaries: [],
        isDeletedStationariesModalOpen: false,
        selectedDeletedStationary: null,
        isBranchChangedManully: false,
    };
    componentDidMount = async () => {
        if (isViewBranchAllowedForUser(this.props.login.scope)) {
            const respBranches = await this.props.fetchBranches(this.props.accesstoken, true);
            const allBranches = get(respBranches, 'payload.data');
            this.setState({
                allBranches,
                isBranchesLoading: false,
            });
        }
        const { branch, creditBookType, debitBookType } = this.props.formikProps.values;
        if (branch && creditBookType && debitBookType) {
            this.fetchStationariesForBranch(branch, creditBookType, debitBookType, () => {
                this.onStationarySelect('credit', this.props.formikProps.values.creditStationaryId, branch, creditBookType, this.props.formikProps);
            });
        }
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.isReadyToFetchBillNumber) {
            if (!this.props.formikProps.values.branch &&
                get(this.props.formikProps.values, 'creditStationaryId') &&
                get(this.props.formikProps.values, 'debitStationaryId') &&
                !this.branchSetRef) {
                this.branchSetRef = true;
                this.initalCreditStationaryId = get(this.props.formikProps.values, 'creditStationaryId');
                this.initalCreditStationaryNumber = get(this.props.formikProps.values, 'creditStationaryNumber');
                this.initalDebitStationaryId = get(this.props.formikProps.values, 'debitStationaryId');
                this.initalDebitStationaryNumber = get(this.props.formikProps.values, 'debitStationaryNumber');
                if (isViewStationaryAllowedForUser(this.props.login.scope)) {
                    this.props
                        .fetchStationary(this.props.accesstoken, `${get(this.props.formikProps.values, 'creditStationaryId')}, ${get(this.props.formikProps.values, 'debitStationaryId')}`)
                        .then((res) => {
                        const stationary = get(res, 'payload.data');
                        if (stationary && stationary.branch.id) {
                            this.props.formikProps.setFieldValue('branch', stationary.branch.id);
                            this.fetchStationariesForBranch(stationary.branch.id, stationary.creditBookType, stationary.debitBookType, () => {
                                this.onStationarySelect('credit', this.props.formikProps.values.creditStationaryId, stationary.branch.id, stationary.creditBookType, this.props.formikProps);
                            });
                        }
                    });
                }
            }
        }
    };
    fetchStationariesForBranch = (branch, creditBookType, debitBookType, onStationariesSelect) => {
        if (isViewStationaryAllowedForUser(this.props.login.scope)) {
            // Combine credit and debit book types as a single comma-separated value
            const combinedBookTypes = [creditBookType, debitBookType].filter(Boolean).join(',');
            this.props.fetchStationaries(this.props.accesstoken, branch, combinedBookTypes).then((res) => {
                // Split the response payload based on types or assume the response provides sufficient metadata
                const allStationaries = get(res, 'payload.data', []);
                console.log(allStationaries, 'Dheeraj1');
                const allCreditStationaries = allStationaries.filter((stationary) => stationary.bookType === creditBookType);
                const allDebitStationaries = allStationaries.filter((stationary) => stationary.bookType === debitBookType);
                console.log(allCreditStationaries, allDebitStationaries, 'Dheeraj');
                this.setState({
                    allCreditStationaries,
                    allDebitStationaries,
                }, () => {
                    if (!onStationariesSelect) {
                        this.props.formikProps.setFieldValue('creditStationaryId', '');
                        this.props.formikProps.setFieldValue('creditStationaryNumber', '');
                        this.props.formikProps.setFieldValue('debitStationaryId', '');
                        this.props.formikProps.setFieldValue('debitStationaryNumber', '');
                    }
                    else {
                        onStationariesSelect();
                    }
                });
            });
        }
    };
    onStationarySelect = (type, val, branch, bookType, formikProps) => {
        if (val) {
            const stationaryKey = `${type}Stationary`; // Determines 'creditStationary' or 'debitStationary'
            const stationaryIdField = `${type}StationaryId`;
            const stationaryNumberField = `${type}StationaryNumber`;
            const initialStationaryId = this[`inital${type.charAt(0).toUpperCase() + type.slice(1)}StationaryId`];
            const initialStationaryNumber = this[`inital${type.charAt(0).toUpperCase() + type.slice(1)}StationaryNumber`];
            const allStationaries = this.state[`all${type.charAt(0).toUpperCase() + type.slice(1)}Stationaries`];
            formikProps.setFieldValue(stationaryIdField, val);
            const stationary = filter(allStationaries, (stationary) => stationary.id == val);
            const stationaryNature = get(stationary, '[0].nature');
            this.setState({ stationaryNature }, () => {
                if (stationaryNature === 'AUTO' && branch && bookType && !formikProps.values[stationaryNumberField]) {
                    if (initialStationaryId !== val) {
                        this.props
                            .getNextStationary(this.props.accesstoken, branch, bookType, get(stationary, '[0].bookName'))
                            .then((res) => {
                            const nextStationaryNumber = get(res, 'payload.data.[0].nextStationaryNumber', '');
                            if (!nextStationaryNumber) {
                                alert('Book name not valid or disabled !!');
                                formikProps.setFieldValue(stationaryIdField, '');
                            }
                            formikProps.setFieldValue(stationaryNumberField, nextStationaryNumber);
                            this.props[`${type}StationaryNumberPopulate`] &&
                                this.props[`${type}StationaryNumberPopulate`](nextStationaryNumber);
                        });
                    }
                    else {
                        formikProps.setFieldValue(stationaryNumberField, initialStationaryNumber);
                        this.props[`${type}StationaryNumberPopulate`] &&
                            this.props[`${type}StationaryNumberPopulate`](initialStationaryNumber);
                    }
                }
                else if (includes(['MANUAL', 'BOOK_SERIES'], stationaryNature) &&
                    !formikProps.values[stationaryNumberField]) {
                    if (initialStationaryId !== val) {
                        formikProps.setFieldValue(stationaryNumberField, `${get(stationary, '[0].code')}-`);
                    }
                    else {
                        formikProps.setFieldValue(stationaryNumberField, initialStationaryNumber);
                        this.props[`${type}StationaryNumberPopulate`] &&
                            this.props[`${type}StationaryNumberPopulate`](initialStationaryNumber);
                    }
                }
            });
        }
    };
    validateStationaryNumber = (type, stationaryNumber, stationaryId, setFieldError) => {
        if (stationaryNumber && stationaryId) {
            this.props
                .validateStationary(this.props.accesstoken, stationaryId, stationaryNumber)
                .then((res) => {
                if (!get(res, 'payload.data')) {
                    setFieldError(`${type}StationaryNumber`, 'Invalid Stationary Number');
                }
                else {
                    this.props[`${type}StationaryNumberPopulate`] &&
                        this.props[`${type}StationaryNumberPopulate`](stationaryNumber);
                }
            })
                .catch((e) => { });
        }
    };
    render() {
        const { branch, creditBookType, creditStationaryId, debitBookType, debitStationaryId, } = this.props.formikProps.values;
        const { colClass = 8 } = this.props;
        return (_jsxs(_Fragment, { children: [_jsx(FxRow, { gutter: 12, children: isViewBranchAllowedForUser(this.props.login.scope) && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: this.state.isBranchesLoading || !this.props.isReadyToFetchBillNumber ? (_jsx(FxSkeleton, { width: "100%", height: "40px", className: "mt-4", children: _jsx("div", {}) })) : (_jsx(Field, { size: this.props.size, name: "branch", options: mappedBranches(this.state.allBranches), component: FxSelectField, onChange: (val) => {
                                this.props.onBranchSelect &&
                                    this.props.onBranchSelect(val, find(this.state.allBranches, { id: val }));
                                this.fetchStationariesForBranch(val, creditBookType, debitBookType, null);
                            }, isRequired: this.props.branchRequired, disabled: get(this.props, 'fieldDisabled.branch', false), allowClear: true, label: get(this.props, 'fieldLabel.branch', 'Select Branch'), "data-testid": "branch-field" })) })) }), _jsxs(FxRow, { gutter: 12, children: [_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "creditBookType", options: getMappedBookTypes(), component: FxSelectField, disabled: true, label: 'Credit Book Type', "data-testid": "book-type-field" }) }), branch && creditBookType && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "creditStationaryId", isRequired: this.props.branchRequired, options: mappedStationaries(this.state.allCreditStationaries), component: FxSelectField, onChange: (val) => {
                                    if (this.props.onStationarySelectCb) {
                                        this.props.onStationarySelectCb(val);
                                    }
                                    this.onStationarySelect('credit', val, branch, creditBookType, this.props.formikProps);
                                }, label: 'Select Credit Book Name', "data-testid": "book-name-field" }) })), branch && creditBookType && creditStationaryId && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "creditStationaryNumber", type: "text", component: FxTextFieldStackedLabelV2, disabled: this.state.stationaryNature == 'AUTO' ||
                                    get(this.props, 'fieldDisabled.creditStationaryNumber', false), onBlur: includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature)
                                    ? (creditStationaryNumber) => this.validateStationaryNumber('credit', creditStationaryNumber, creditStationaryId, this.props.formikProps.setFieldError)
                                    : () => { }, isRequired: !!creditStationaryId, label: get(this.props, 'fieldLabel.creditStationaryNumber', 'Credit Stationary Number'), "data-testid": "stationary-number-field" }) }))] }), _jsxs(FxRow, { gutter: 12, children: [_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "debitBookType", options: getMappedBookTypes(), component: FxSelectField, disabled: true, label: 'Debit Book Type', "data-testid": "book-type-field" }) }), branch && debitBookType && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "debitStationaryId", isRequired: this.props.branchRequired, options: mappedStationaries(this.state.allDebitStationaries), component: FxSelectField, onChange: (val) => {
                                    if (this.props.onStationarySelectCb) {
                                        this.props.onStationarySelectCb(val);
                                    }
                                    this.onStationarySelect('debit', val, branch, debitBookType, this.props.formikProps);
                                }, label: 'Select Debit Book Name', "data-testid": "book-name-field" }) })), branch && debitBookType && debitStationaryId && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Field, { size: this.props.size, name: "debitStationaryNumber", type: "text", component: FxTextFieldStackedLabelV2, disabled: this.state.stationaryNature == 'AUTO' ||
                                    get(this.props, 'fieldDisabled.debitStationaryNumber', false), onBlur: includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature)
                                    ? (debitStationaryNumber) => this.validateStationaryNumber('debit', debitStationaryNumber, debitStationaryId, this.props.formikProps.setFieldError)
                                    : () => { }, isRequired: !!debitStationaryId, label: get(this.props, 'fieldLabel.debitStationaryNumber', 'Debit Stationary Number'), "data-testid": "stationary-number-field" }) }))] })] }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStationaries,
        getNextStationary,
        validateStationary,
        fetchBranches,
        fetchStationary,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentBillComponent);
