import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SapGreen = (props) => {
    return (_jsxs("svg", { id: "a", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", height: 24, width: 24, children: [_jsx("path", { fill: "green", d: "M12,21.5c-1.31,0-2.55-.25-3.71-.75-1.16-.5-2.16-1.18-3.02-2.03-.86-.85-1.53-1.86-2.03-3.02-.5-1.16-.75-2.39-.75-3.7s.25-2.55.75-3.71c.5-1.16,1.18-2.16,2.03-3.02s1.86-1.53,3.02-2.03c1.16-.5,2.39-.75,3.7-.75s2.55.25,3.71.75,2.16,1.18,3.02,2.03,1.53,1.86,2.03,3.02c.5,1.16.75,2.39.75,3.7s-.25,2.55-.75,3.71c-.5,1.16-1.18,2.16-2.03,3.02-.85.86-1.86,1.53-3.02,2.03-1.16.5-2.39.75-3.7.75Z" }), _jsxs("g", { children: [_jsx("path", { fill: "#fff", d: "M7.09,13.68c-.29-.22-.45-.53-.47-.93h1.16c.01.14.05.24.12.3.07.06.16.09.27.09.1,0,.18-.03.25-.08.06-.05.1-.12.1-.21,0-.11-.05-.2-.16-.27s-.28-.13-.52-.21c-.26-.08-.46-.17-.62-.25s-.29-.2-.41-.35c-.12-.15-.17-.35-.17-.6s.06-.47.19-.65c.13-.18.3-.31.52-.41s.47-.14.75-.14c.45,0,.82.11,1.09.32.27.21.42.51.44.9h-1.18c0-.12-.04-.21-.11-.27s-.16-.09-.26-.09c-.08,0-.15.02-.2.07-.05.05-.08.12-.08.21,0,.07.03.14.09.19.06.05.13.1.21.14.08.04.21.09.38.15.25.08.45.17.61.25.16.08.3.2.42.35.12.15.17.34.17.57s-.06.44-.17.63c-.12.18-.28.33-.5.44-.22.11-.48.16-.78.16-.45,0-.83-.11-1.12-.32Z" }), _jsx("path", { fill: "#fff", d: "M12.65,13.33h-1.39l-.21.64h-1.14l1.42-3.91h1.25l1.42,3.91h-1.15l-.21-.64ZM12.37,12.5l-.42-1.26-.42,1.26h.84Z" }), _jsx("path", { fill: "#fff", d: "M17.28,12.01c-.11.19-.27.35-.49.47-.22.12-.49.17-.81.17h-.54v1.32h-1.09v-3.91h1.62c.31,0,.58.06.8.17.22.11.39.26.5.46s.17.42.17.68c0,.24-.05.45-.16.65ZM16.34,11.36c0-.28-.15-.43-.46-.43h-.43v.85h.43c.31,0,.46-.14.46-.42Z" })] })] }));
};
const SapGreeIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SapGreen, ...props });
};
export default SapGreeIcon;
