import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { reportDownloadStatus } from 'utils/ui_report_utils';
import FxModal from 'sharedV2/FxModal';
const ReportSuccessModalComponent = (props) => {
    const { isOpen, toggle, message = {} } = props;
    const status = get(message, 'status');
    const text = get(message, 'text');
    const link = get(message, 'link', '');
    const { t } = useTranslation();
    return (_jsxs(FxModal, { open: isOpen, onCancel: toggle, title: _jsx("span", { style: { color: status == 'SUCCESS' ? `#20a8d8` : '#f86c6b' }, children: status == 'SUCCESS' ? 'Report Request Success' : 'Report Request Failed' }), footer: null, children: [status == reportDownloadStatus.SUCCESS && (_jsxs("div", { className: "text-center pt-3", children: [_jsx("p", { children: text }), _jsxs("p", { children: ["You can access the report", ' ', _jsx(Link, { target: '_blank', to: link, children: "here." })] })] })), status == reportDownloadStatus.FAILED && (_jsx("div", { className: "text-center", children: _jsx("p", { children: text }) }))] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportSuccessModalComponent);
