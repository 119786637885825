import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get } from 'lodash';
import { Field } from 'formik';
import { FxCol, FxRow, FxSelect, FxTypography, FxButton, FxTable, FxSelectSingleDateV2, FxToolTip, FxSelectField, FxTextFieldStackedLabelV2, } from '../../../../sharedV2';
import { FxDeleteOutlined } from '../../../../sharedV2/FxIcons';
import CalendarComponent from '../../../utils/calendar_component';
import { mappedCustomers } from '../../../../utils/form_utils';
import { mappedBillNumbers, paymentTypeOptions } from '../paymentUtils';
const BulkPaymentSectionComponent = ({ index, section, onChange, onBillNumberChange, removeSection, formikProps, getFilteredCustomers, }) => {
    const { allBillNumbers, entries } = section;
    const { setFieldValue } = formikProps;
    const columns = [
        {
            title: 'Bill Number',
            dataIndex: 'billNumber',
            key: 'billNumber',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.billNumber`, type: "text", component: FxTextFieldStackedLabelV2, placeholder: "Bill Number", disabled: true })),
        },
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            key: 'paymentType',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.paymentType`, component: FxSelectField, options: paymentTypeOptions, placeholder: "Payment Type" })),
        },
        {
            title: 'Amount',
            dataIndex: 'freightAmount',
            key: 'freightAmount',
            render: (text, record) => (_jsxs(_Fragment, { children: [_jsxs(FxTypography.Text, { type: "danger", style: {
                            fontSize: '10px',
                            paddingTop: '5px',
                            display: 'block', // Ensures the note appears below the input
                        }, children: ["Note: Amount cannot exceed the freight amount of ", record.freightAmount, "."] }), _jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.amount`, component: FxTextFieldStackedLabelV2, type: "number", placeholder: "Amount", onChange: (e) => {
                            const value = parseFloat(e);
                            const maxFreightAmount = record.freightAmount;
                            if (value <= maxFreightAmount || isNaN(value)) {
                                setFieldValue(`sections.${record.sectionIndex}.entries.${record.entryIndex}.amount`, e);
                            }
                            else {
                                alert(`Amount cannot exceed Freight Amount: ${maxFreightAmount}`);
                                setFieldValue(`sections.${record.sectionIndex}.entries.${record.entryIndex}.amount`, '');
                            }
                        }, control: false })] })),
        },
        {
            title: 'Raise Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.date`, type: "text", component: FxSelectSingleDateV2 })),
        },
        {
            title: 'Advice No.',
            dataIndex: 'adviceNo',
            key: 'adviceNo',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.adviceNo`, type: "text", component: FxTextFieldStackedLabelV2, placeholder: "Advice No." })),
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            render: (text, record) => (_jsx(Field, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.remarks`, type: "text", component: FxTextFieldStackedLabelV2, placeholder: "Remarks" })),
        },
    ];
    const entriesWithBillNumber = entries
        .map((entry, entryIndex) => ({
        ...entry,
        key: `${index}-${entryIndex}`,
        sectionIndex: index,
        entryIndex,
        freightAmount: get(entry, 'freightAmount'),
    }))
        .filter((entry) => entry.billNumber);
    const handleCustomerChange = (customerId, index, setFieldValue, values) => {
        // Update the selected customer in the current section
        setFieldValue(`sections[${index}].customerId`, customerId);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FxRow, { gutter: 16, style: {
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    borderBottom: '1px solid #f0f0f0',
                }, children: _jsx(FxTypography.Title, { level: 5, children: `Section ${index + 1}` }) }), _jsxs(FxRow, { gutter: 24, children: [_jsx(FxCol, { xs: 24, md: 6, children: _jsx(FxSelect, { placeholder: "Customer Name", options: mappedCustomers(getFilteredCustomers(formikProps.values, index)), onChange: (value) => handleCustomerChange(value, index, setFieldValue, formikProps.values) }) }), _jsx(FxCol, { xs: 24, md: 6, style: { marginLeft: '0.2rem', marginBottom: '0.4rem' }, children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                                onChange(index, 'startDate', startDate, setFieldValue);
                                onChange(index, 'endDate', endDate, setFieldValue);
                            }, initialStartDate: section.startDate, initialEndDate: section.endDate, containerStyles: { width: '100%' } }) }), _jsx(FxCol, { xs: 24, md: 8, children: _jsx(FxSelect, { options: mappedBillNumbers(allBillNumbers), placeholder: "Bill Number", onChange: (selected) => {
                                const selectedBills = selected.map((selectedId) => {
                                    const bill = allBillNumbers.find((bill) => bill.id === selectedId);
                                    if (bill) {
                                        return {
                                            billId: bill.id,
                                            billNumber: get(bill, 'basicDetails.billNumber'),
                                            freightAmount: get(bill, 'basicDetails.freightAmount'),
                                        };
                                    }
                                    return null;
                                });
                                onBillNumberChange(selectedBills, index);
                            }, mode: "multiple" }) }), _jsx(FxCol, { xs: 24, md: 2, children: index > 0 && (_jsx(FxToolTip, { title: "Remove Section", children: _jsx(FxButton, { type: "default", onClick: removeSection, icon: _jsx(FxDeleteOutlined, {}) }) })) })] }), _jsx(FxRow, { children: _jsx(FxCol, { xs: 24, md: 24, children: entriesWithBillNumber.length > 0 && (_jsx(FxTable, { columns: columns, dataSource: entriesWithBillNumber, pagination: false, style: { marginTop: '1rem' } })) }) })] }));
};
export default BulkPaymentSectionComponent;
