import axios, { CancelToken } from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';

export const CREATE_MULTIPLE_VEHICLE_RENEWAL_REMINDERS = 'create_multiple_vehicle_renewal_reminders';
export const CREATE_VEHICLE_RENEWAL_REMINDER = 'create_vehicle_renewal_reminder';
export const FETCH_VEHICLE_RENEWAL_REMINDERS = 'fetch_vehicle_renewal_reminders';
export const FETCH_VEHICLE_RENEWAL_REMINDER = 'fetch_vehicle_renewal_reminder';
export const UPDATE_VEHICLE_RENEWAL_REMINDER = 'update_vehicle_renewal_reminder';
export const DELETE_VEHICLE_RENEWAL_REMINDER = 'delete_vehicle_renewal_reminder';
export const FETCH_VEHICLE_RENEWAL_REMINDERS_COUNT = 'fetch_vehicle_renewal_reminders_count';
export const FETCH_VEHICLE_RENEWAL_REMINDERS_NOTIFICATIONS = 'fetch_vehicle_renewal_reminders_notifications';
export const SEND_VEHICLE_RENEWAL_REMINDER_NOTIFICATION = 'send_vehicle_renewal_reminders_notification';
export const DELETE_DOCUMENT_FROM_VEHICLE_RENEWAL_REMINDER = 'DELETE_DOCUMENT_FROM_VEHICLE_RENEWAL_REMINDER';
export const DELETE_DOCUMENT_FROM_VEHICLE_SERVICE_REMINDER_BULK = 'DELETE_DOCUMENT_FROM_VEHICLE_SERVICE_REMINDER_BULK';
export const DELETE_DOCUMENT_FROM_VEHICLE_RENEWAL_REMINDER_BULK = 'DELETE_DOCUMENT_FROM_VEHICLE_SERVICE_REMINDER_BULK';
export const FETCH_VEHICLE_RENEWAL_REMINDERS_CONFIG = 'fetch_vehicle_renewal_reminders_config';
export const CREATE_VEHICLE_RENEWAL_REMINDERS_CONFIG = 'create_vehicle_renewal_reminder_config';
export const UPDATE_VEHICLE_RENEWAL_REMINDERS_CONFIG = 'update_vehicle_renewal_reminder_config';
export const FETCH_VEHICLE_REMINDER_REPORT = 'fetch_vehicle_reminder_report';

export function createMultipleVehicleRenewalReminders(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(
        `${ROOT_API_URL}vehicle_renewal_reminders/createMultipleVehicleRenewalReminders`,
        values,
        config
    );
    return {
        type: CREATE_MULTIPLE_VEHICLE_RENEWAL_REMINDERS,
        promise: request,
    };
}

export function createVehicleRenewalReminder(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}vehicle_renewal_reminders/`, values, config);
    return {
        type: CREATE_VEHICLE_RENEWAL_REMINDER,
        promise: request,
    };
}

export function updateVehicleRenewalReminder(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}vehicle_renewal_reminders/update`, values, config);
    return {
        type: UPDATE_VEHICLE_RENEWAL_REMINDER,
        promise: request,
    };
}

export function fetchVehicleRenewalRemindersCount(accesstoken, groupId, vehicleId, vehicleRenewalType, vehicleTagId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            vehicleId,
            vehicleRenewalType,
            vehicleTagId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_reminders/count`, config);

    return {
        type: FETCH_VEHICLE_RENEWAL_REMINDERS_COUNT,
        promise: request,
    };
}

export function fetchVehicleRenewalReminders(
    accesstoken,
    page = 1,
    size = 2000,
    groupId,
    vehicleId,
    vehicleRenewalType,
    overdue,
    dueSoon,
    vehicleIds,
    vehicleTagId
) {
    const cancelCallbackKey =
        'CANCEL_CALLBACK_FUNCTION_FOR_vehicle_renewal_reminder_action_fetchVehicleRenewalReminders';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            page: page - 1,
            size,
            groupId,
            vehicleId,
            vehicleRenewalType,
            overdue,
            dueSoon,
            vehicleIds,
            vehicleTagId,
        }),
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_reminders/`, config);

    return {
        type: FETCH_VEHICLE_RENEWAL_REMINDERS,
        promise: request,
    };
}

export function fetchVehicleRenewalReminder(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_reminders/${id}`, config);

    return {
        type: FETCH_VEHICLE_RENEWAL_REMINDER,
        promise: request,
    };
}

export function fetchVehicleRenewalRemindersNotifications(accesstoken, ids) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_reminders/notifications?ids=${ids}`, config);
    return {
        type: FETCH_VEHICLE_RENEWAL_REMINDERS_NOTIFICATIONS,
        promise: request,
    };
}

export function sendVehicleRenewalReminderNotification(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_reminders/${id}/sendNotifications`, config);
    return {
        type: SEND_VEHICLE_RENEWAL_REMINDER_NOTIFICATION,
        promise: request,
    };
}

export function deleteVehicleRenewalReminder(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}vehicle_renewal_reminders/${id}`, config);
    return {
        type: DELETE_VEHICLE_RENEWAL_REMINDER,
        promise: request,
    };
}

export function deleteDocumentVehicleRenewalReminder(accesstoken, imageId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            imageId,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}vehicle_renewal_reminders/delete-document`, config);
    return {
        type: DELETE_DOCUMENT_FROM_VEHICLE_RENEWAL_REMINDER,
        promise: request,
    };
}

export function deleteVehicleServiceReminderBulk(accesstoken, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}service_reminders/bulk`, config);
    return {
        type: DELETE_DOCUMENT_FROM_VEHICLE_SERVICE_REMINDER_BULK,
        promise: request,
    };
}
export function deleteVehicleRenewalReminderBulk(accesstoken, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}vehicle_renewal_reminders/bulk`, config);
    return {
        type: DELETE_DOCUMENT_FROM_VEHICLE_RENEWAL_REMINDER_BULK,
        promise: request,
    };
}

export function fetchVehicleReminderConfig(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_reminders/config/`, config);
    return {
        type: FETCH_VEHICLE_RENEWAL_REMINDERS_CONFIG,
        promise: request,
    };
}

export function createVehicleReminderConfig(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}vehicle_renewal_reminders/config/`, data, config);
    return {
        type: CREATE_VEHICLE_RENEWAL_REMINDERS_CONFIG,
        promise: request,
    };
}

export function updateVehicleReminderConfig(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}vehicle_renewal_reminders/config/`, data, config);
    return {
        type: UPDATE_VEHICLE_RENEWAL_REMINDERS_CONFIG,
        promise: request,
    };
}

export function fetchVehicleReminderReport(params) {
    const { vehicleId } = params;
    const config = {
        responseType: 'blob',
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_renewal_reminders/report/all`, config);
    return {
        type: FETCH_VEHICLE_REMINDER_REPORT,
        promise: request,
    };
}
