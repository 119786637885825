import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SavedToCloud = (props) => {
    return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_383_419229", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "24", height: "24", children: _jsx("rect", { width: "24", height: "24", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_383_419229)", children: _jsx("path", { d: "M10.35 16.6538L15.6538 11.35L14.5693 10.2655L10.3345 14.5L8.2155 12.3808L7.14625 13.45L10.35 16.6538ZM6.5 19.5C5.118 19.5 3.93917 19.0198 2.9635 18.0595C1.98783 17.0993 1.5 15.9283 1.5 14.5463C1.5 13.3231 1.89167 12.2352 2.675 11.2828C3.45833 10.3301 4.45767 9.76658 5.673 9.59225C5.9935 8.09742 6.74517 6.875 7.928 5.925C9.11067 4.975 10.468 4.5 12 4.5C13.809 4.5 15.3446 5.13108 16.6068 6.39325C17.8689 7.65542 18.5 9.191 18.5 11V11.5H18.8078C19.8616 11.582 20.7404 12.0058 21.4443 12.7713C22.1481 13.5366 22.5 14.4462 22.5 15.5C22.5 16.6153 22.1122 17.5608 21.3365 18.3365C20.5608 19.1122 19.6153 19.5 18.5 19.5H6.5ZM6.5 18H18.5C19.2 18 19.7917 17.7583 20.275 17.275C20.7583 16.7917 21 16.2 21 15.5C21 14.8 20.7583 14.2083 20.275 13.725C19.7917 13.2417 19.2 13 18.5 13H17V11C17 9.61667 16.5125 8.4375 15.5375 7.4625C14.5625 6.4875 13.3833 6 12 6C10.6167 6 9.4375 6.4875 8.4625 7.4625C7.4875 8.4375 7 9.61667 7 11H6.5C5.53333 11 4.70833 11.3417 4.025 12.025C3.34167 12.7083 3 13.5333 3 14.5C3 15.4667 3.34167 16.2917 4.025 16.975C4.70833 17.6583 5.53333 18 6.5 18Z", fill: "#605E5C" }) })] }));
};
const SavedToCloudIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SavedToCloud, ...props });
};
export default SavedToCloudIcon;
