import { get } from 'lodash';
export function getMetabaseHeaderListsForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId');
    return [
        {
            dashboardId: 972,
            show: accountId === 13325,
            path: `/dashboard/admin/tools/operations/metabase/dashboard/972`,
            label: 'Balco Dashboard',
        },
        {
            dashboardId: 680,
            show: accountId === 1570,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/680',
            label: 'RMC TICKET DASHBOARD',
        },
        {
            dashboardId: 1004,
            show: accountId === 12007,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1004',
            label: 'ACC Dashboard',
        },
        {
            dashboardId: 1005,
            show: accountId === 13081,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1005',
            label: 'ACC Saraigram Dashboard',
        },
        {
            dashboardId: 1006,
            show: accountId === 5248,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1006',
            label: 'Grasim Vilayat Dashboard',
        },
        {
            dashboardId: 1014,
            show: accountId === 13237,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1014',
            label: 'MB Power- Dashboard',
        },
        {
            dashboardId: 1070,
            show: accountId === 12556,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1070',
            label: 'Electrosteel Dashboard',
        },
        {
            dashboardId: 1098,
            show: accountId === 2034,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1098',
            label: 'Bharathi Dashboard',
        },
        {
            dashboardId: 1099,
            show: accountId === 12987,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1099',
            label: 'Adani Renewables Dashboard',
        },
        {
            dashboardId: 1102,
            show: accountId === 5380,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1102',
            label: 'Grasim Kharach Dashboard',
        },
        {
            dashboardId: 1130,
            show: accountId === 12007,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1130',
            label: 'ACC Central Dashboard',
        },
        {
            dashboardId: 1142,
            show: accountId === 13806,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1142',
            label: 'Adani Power Dashboard',
        },
        {
            dashboardId: 1229,
            show: accountId === 12985,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1229',
            label: 'JPL Tamnar - dashboard ',
        },
        {
            dashboardId: 1486,
            show: accountId === 13395,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1486',
            label: 'Adani KCL Dashboard',
        },
        {
            dashboardId: 1146,
            show: accountId === 11999,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1146',
            label: 'Bisleri - dashboard',
        },
        {
            dashboardId: 1482,
            show: accountId === 13806,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1482',
            label: 'Issue Tracker',
        },
        {
            dashboardId: 1418,
            show: accountId === 5149,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1418',
            label: 'Synergy Steels Dashboard',
        },
        {
            dashboardId: 939,
            show: accountId === 113,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/939',
            label: 'Central Control Tower',
        },
        {
            dashboardId: 1497,
            show: accountId === 14673,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/1497',
            label: 'Adani Logistics Dashboard',
        },
    ];
}
export function getMetabaseHeaderListsForMasterDashboard() {
    return [
        {
            dashboardId: 1491,
            show: true,
            path: `/dashboard/admin/tools/operations/metabase/dashboard/1491`,
            label: 'Inventory Status',
        },
    ];
}
