import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_NODE_API_URL, ROOT_REPORTING_NODE_API_URL } from '../../constant';
import { shouldDownloadPrintFromRapp } from 'utils/account_utils';
export const CREATE_REPORT_TEMPLATE = 'CREATE_REPORT_TEMPLATE';
export const UPDATE_REPORT_TEMPLATE = 'UPDATE_REPORT_TEMPLATE';
export const DELETE_REPORT_TEMPLATE = 'DELETE_REPORT_TEMPLATE';
export const FETCH_REPORT_TEMPLATES = 'FETCH_REPORT_TEMPLATES';
export const FETCH_REPORT_TEMPLATE = 'FETCH_REPORT_TEMPLATE';
export const UPLOAD_REPORT_TEMPLATE = 'UPLOAD_REPORT_TEMPLATE';
export const FETCH_AVAILABLE_REPORT_TEMPLATE_FIELDS = 'FETCH_AVAILABLE_REPORT_TEMPLATE_FIELDS';
export const CREATE_REPORT_FROM_TEMPLATE = 'CREATE_REPORT_FROM_TEMPLATE';
export const CREATE_XML_REPORTS = 'CREATE_XML_REPORTS';
export function createReportTemplate(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}reports/template-config`, data, config);
    return {
        type: CREATE_REPORT_TEMPLATE,
        promise: request,
    };
}
export function updateReportTemplate(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}reports/template-config`, data, config);
    return {
        type: UPDATE_REPORT_TEMPLATE,
        promise: request,
    };
}
export function fetchReportTemplates(accesstoken, params, accountId) {
    const { type } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            type,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/template-config/list`, config);
    return {
        type: FETCH_REPORT_TEMPLATES,
        promise: request,
    };
}
export function fetchReportTemplate(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}reports/template-config/${id}`, config);
    return {
        type: FETCH_REPORT_TEMPLATE,
        promise: request,
    };
}
export function deleteReportTemplate(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}reports/template-config/${id}`, config);
    return {
        type: DELETE_REPORT_TEMPLATE,
        promise: request,
    };
}
export function uploadReportTemplate(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    let request;
    if (shouldDownloadPrintFromRapp()) {
        request = axios.post(`${ROOT_REPORTING_NODE_API_URL}template/uploadReportTemplate`, data, config);
    }
    else {
        request = axios.post(`${ROOT_NODE_API_URL}napp/template/uploadReportTemplate`, data, config);
    }
    return {
        type: UPLOAD_REPORT_TEMPLATE,
        promise: request,
    };
}
export function fetchAvailableReportTemplateFields(accesstoken, params) {
    const { type } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            type,
        }),
    };
    let request;
    if (shouldDownloadPrintFromRapp()) {
        request = axios.get(`${ROOT_REPORTING_NODE_API_URL}template/availableReportTemplateFields`, config);
    }
    else {
        request = axios.get(`${ROOT_NODE_API_URL}napp/template/availableReportTemplateFields`, config);
    }
    return {
        type: FETCH_AVAILABLE_REPORT_TEMPLATE_FIELDS,
        promise: request,
    };
}
export function createReportFromTemplate(accesstoken, params) {
    const accountId = params.accountId;
    const _params = {};
    if (accountId) {
        _params['accountId'] = accountId;
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(_params),
    };
    let request;
    if (shouldDownloadPrintFromRapp()) {
        request = axios.post(`${ROOT_REPORTING_NODE_API_URL}template/createReportFromTemplate`, params, config);
    }
    else {
        request = axios.post(`${ROOT_NODE_API_URL}napp/template/createReportFromTemplate`, params, config);
    }
    return {
        type: CREATE_REPORT_FROM_TEMPLATE,
        promise: request,
    };
}
export function createXMLReports(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        // params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}template/createXMLReports`, params, config);
    return {
        type: CREATE_XML_REPORTS,
        promise: request,
    };
}
