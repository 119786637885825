import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchLogin, logout, logoutState, fetchLoginByToken } from '../../actions/login/login_action';
import { DASHBOARD_LANDING_PATH, getQueryStringParams, getQueryStringSearch } from '../../constant';
import { get, map } from 'lodash';
import { isSafeRedirectUrl } from 'utils/http_utils';
import { isShellWhiteLabelUrl } from '../../../white_label_constants';
import { getValidatorFromReferrer } from '../../utils/account_utils';
import { sendAppAlertToSlack } from '../../utils/analytics_utils';
import { withRoute } from '../dashboard/customHocs/withRoute';
import Loader from 'components/utils/loader';
import { Navigate } from 'react-router-dom';

export const ALLOWED_REFERRAL = ['bvclogistics.com', 'salesforce.com', 'broztravels.com', 'shelltelematics.in'];

class AutoLoginComponent extends Component {
    state = { loading: false, navigateFromLogin: false };

    componentDidMount = () => {
        var referrer = document.referrer;
        sendAppAlertToSlack(
            `AUTOLOGIN:: referrer ${referrer} , accesstoken :: ${this.props.accesstoken}`,
            '#shell-autologin'
        );
        let allowed = false;
        const parsed = getQueryStringParams();
        const { userName, password } = parsed;
        const parsedSearch = getQueryStringSearch();
        const { state, logout } = parsedSearch;
        const accessToken = get(this.props, 'accesstoken', get(this.state, 'accesstoken', ''));
        const validator = getValidatorFromReferrer(referrer);
        console.log('this.props.accesstoken', accessToken);
        if (logout == 'true') {
            this.props.logoutState();
            console.log('this.props.accesstoken', get(this.props, 'accesstoken', get(this.state, 'accesstoken', '')));
            this.props.logout(get(this.props, 'accesstoken', get(this.state, 'accesstoken', '')));
            window.open('https://shelltelematics.in/', '_self');
            return;
        } else if (window.location.hostname == 'shelltelematics.in' && accessToken) {
            console.log('this.props.accesstoken', accessToken);
            this.props.navigate(DASHBOARD_LANDING_PATH, { state: { fromLogin: true } });
        }
        if (referrer) {
            map(ALLOWED_REFERRAL, (referral_url) => {
                allowed = referrer.indexOf(referral_url) > -1;
            });
        }
        // if (!allowed && !state) {
        //     console.log('referrer was', referrer, 'It was not present in allowed domains');
        //     return;
        // }
        let error;
        const {
            login,
            location: { search, pathname },
        } = this.props;

        console.log('this.props', this.props, this.props.accesstoken);
        console.log('login', login);
        if (get(login, 'data')) {
            this.setState({
                navigateFromLogin: true,
            });
            // this.props.navigate(DASHBOARD_LANDING_PATH, { state: { fromLogin: true } });
        } else {
            const parsed = getQueryStringSearch();
            const { userName, password, state, code } = parsed;
            const values = {
                email: userName,
                password,
                code,
                state,
                referrer,
            };

            if (validator === 'FXTOKEN' && !code && !values.email && !values.password) {
                error = 'Please check url';
                this.setState({ error, loading: false });
                return;
            } else if (!values.email && !values.password && !values.state && validator !== 'FXTOKEN') {
                error = 'Please check url';
                this.setState({ error, loading: false });
                return;
            } else if (!values.email && values.password && validator !== 'FXTOKEN') {
                error = 'Please check url';
                this.setState({ error, loading: false });
                return;
            } else if (values.code) {
                sendAppAlertToSlack(`AUTOLOGIN:: validator ${validator}`, '#shell-autologin');
                return this.props.fetchLoginByToken(code, validator).then((result) => {
                    if (!result.error) {
                        const parsed = getQueryStringParams();

                        const data = result.payload.data;
                        console.log('data', data);
                        const accesstoken = get(data, 'access_token', '');
                        this.setState({
                            accesstoken,
                        });
                        if (!data.modules || data.modules.length == 0) {
                            error = 'Please check your login details!';
                            this.setState({ error, loading: false });
                        } else if (parsed.redirect) {
                            window.location.href = parsed.redirect;
                        } else {
                            this.props.navigate(DASHBOARD_LANDING_PATH, { state: { fromLogin: true } });
                        }
                    }
                    if (result.error) {
                        const errorDescription = get(result, 'payload.response.data.error_description');
                        sendAppAlertToSlack(`AUTOLOGIN:: errorDescription ${errorDescription}`, '#shell-autologin');
                        this.setState({
                            error: errorDescription ? errorDescription : 'Please check your login details!',
                            loading: false,
                        });
                    }
                });
            } else {
                return this.props.fetchLogin(values).then((result) => {
                    if (!result.error) {
                        const parsed = getQueryStringParams();

                        const data = result.payload.data;
                        if (!data.modules || data.modules.length == 0) {
                            error = 'Please check your login details!';
                            this.setState({ error, loading: false });
                        } else if (isSafeRedirectUrl(parsed.redirect)) {
                            window.location.href = parsed.redirect;
                        } else {
                            this.props.navigate(DASHBOARD_LANDING_PATH, { state: { fromLogin: true } });
                        }
                    }
                    if (result.error) {
                        const errorDescription = get(result, 'payload.response.data.error_description');
                        this.setState({
                            error: errorDescription ? errorDescription : 'Please check your login details!',
                            loading: false,
                        });
                    }
                });
            }
        }
    };

    render() {
        if (this.state.navigateFromLogin) {
            return <Navigate to={DASHBOARD_LANDING_PATH} state={{ fromLogin: true }} />;
        }
        return (
            <div>
                {this.state.loading && <Loader />}
                {this.state.error && <div className="text-center text-danger mx-auto mt-5">{this.state.error}</div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        login: state.login,
        accesstoken: get(state, 'login.data.access_token', ''),
    };
}

export default withRoute(
    connect(mapStateToProps, { fetchLogin, logout, logoutState, fetchLoginByToken })(AutoLoginComponent)
);
