import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const CloudOff = (props) => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("g", { mask: "url(#mask0_310_259214)", children: _jsx("path", { d: "M19.8 22.6L17.15 20H6.5C4.96667 20 3.66667 19.4667 2.6 18.4001C1.53333 17.3334 1 16.0334 1 14.5C1 13.2167 1.39583 12.075 2.1875 11.075C2.97917 10.075 4 9.43338 5.25 9.15005C5.3 9.01672 5.35 8.88755 5.4 8.76255C5.45 8.63755 5.5 8.50005 5.55 8.35005L1.4 4.20005L2.8 2.80005L21.2 21.2001L19.8 22.6ZM6.5 18.0001H15.15L7.1 9.95005C7.06667 10.1334 7.04167 10.3084 7.025 10.475C7.00833 10.6417 7 10.8167 7 11H6.5C5.53333 11 4.70833 11.3417 4.025 12.025C3.34167 12.7084 3 13.5334 3 14.5C3 15.4667 3.34167 16.2917 4.025 16.975C4.70833 17.6584 5.53333 18.0001 6.5 18.0001ZM21.6 18.7501L20.15 17.35C20.4333 17.1167 20.6458 16.8459 20.7875 16.5375C20.9292 16.2292 21 15.8834 21 15.5C21 14.8 20.7583 14.2084 20.275 13.725C19.7917 13.2417 19.2 13 18.5 13H17V11C17 9.61672 16.5125 8.43755 15.5375 7.46255C14.5625 6.48755 13.3833 6.00005 12 6.00005C11.55 6.00005 11.1167 6.05422 10.7 6.16255C10.2833 6.27088 9.88333 6.44172 9.5 6.67505L8.05 5.22505C8.63333 4.82505 9.25417 4.52088 9.9125 4.31255C10.5708 4.10422 11.2667 4.00005 12 4.00005C13.95 4.00005 15.6042 4.67922 16.9625 6.03755C18.3208 7.39588 19 9.05005 19 11C20.15 11.1334 21.1042 11.6292 21.8625 12.4875C22.6208 13.3459 23 14.35 23 15.5C23 16.15 22.875 16.7542 22.625 17.3126C22.375 17.8709 22.0333 18.3501 21.6 18.7501Z", fill: "#D60000" }) }) }));
};
const CloudOffIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: CloudOff, ...props });
};
export default CloudOffIcon;
