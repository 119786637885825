import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const SaveToCloudWhite = (props) => {
    return (_jsx("svg", { width: "14", height: "11", viewBox: "0 0 14 11", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M3.33333 10.314C2.412 10.314 1.62611 9.99435 0.975667 9.35513C0.325222 8.71591 0 7.93469 0 7.01147C0 6.18324 0.266444 5.4548 0.799333 4.82613C1.33222 4.19747 1.99311 3.82502 2.782 3.7088C2.99567 2.71224 3.49678 1.8973 4.28533 1.26396C5.07378 0.630632 5.97867 0.313965 7 0.313965C8.20711 0.313965 9.23111 0.734409 10.072 1.5753C10.9129 2.41619 11.3333 3.44019 11.3333 4.6473V4.98063H11.5385C12.2411 5.0353 12.8269 5.3178 13.2962 5.82813C13.7654 6.33835 14 6.94474 14 7.6473C14 8.39085 13.7436 9.02119 13.2308 9.5383C12.7179 10.0554 12.0897 10.314 11.3462 10.314H7.70517C7.36839 10.314 7.08333 10.1973 6.85 9.96397C6.61667 9.73063 6.5 9.44558 6.5 9.1088V5.45746L5.26667 6.6703L4.56417 5.9743L7 3.5383L9.43583 5.9743L8.73333 6.6703L7.5 5.45746V9.1088C7.5 9.16013 7.52139 9.20713 7.56417 9.2498C7.60683 9.29258 7.65383 9.31396 7.70517 9.31396H11.3333C11.8 9.31396 12.1944 9.15285 12.5167 8.83063C12.8389 8.50841 13 8.11397 13 7.6473C13 7.18063 12.8389 6.78619 12.5167 6.46397C12.1944 6.14174 11.8 5.98063 11.3333 5.98063H10.3333V4.6473C10.3333 3.72508 10.0083 2.93897 9.35833 2.28896C8.70833 1.63896 7.92222 1.31396 7 1.31396C6.07778 1.31396 5.29167 1.63896 4.64167 2.28896C3.99167 2.93897 3.66667 3.72508 3.66667 4.6473H3.3205C2.68883 4.6473 2.14417 4.87508 1.6865 5.33063C1.22883 5.78619 1 6.33619 1 6.98063C1 7.62508 1.22778 8.17508 1.68333 8.63063C2.13889 9.08619 2.68889 9.31396 3.33333 9.31396H5V10.314H3.33333Z", fill: "white" }) }));
};
const SaveToCloudWhiteIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: SaveToCloudWhite, ...props });
};
export default SaveToCloudWhiteIcon;
