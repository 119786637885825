import axios, { CancelToken } from 'axios';
import { parseQueryParams, ROOT_API, ROOT_API_URL, ROOT_REPORTING_NODE_API_URL } from '../../../constant';
import { getMomentTime } from '../../../utils/date_utils';
import { get } from 'lodash';

export const ADD_TRANSACTION = 'add_transaction';
export const UPDATE_TRANSACTION = 'update_transaction';
export const DELETE_TRANSACTION = 'delete_transaction';
export const FETCH_TRANSACTIONS_BY_DRIVER = 'fetch_transactions_by_driver';
export const FETCH_TRANSACTIONS = 'fetch_transactions';
export const FETCH_TRANSACTIONS_SEARCH = 'FETCH_TRANSACTIONS_SEARCH';
export const FETCH_TRANSACTION_SUMMARY = 'fetch_transaction_summary';
export const APPROVE_MULTIPLE_TRANSACTIONS = 'approve_multiple_transactions';
export const REJECT_MULTIPLE_TRANSACTIONS = 'reject_multiple_transactions';
export const UPDATE_MULTIPLE_TRANSACTIONS = 'update_multiple_transactions';
export const DELETE_BULK_TRANSACTIONS = 'delete_bulk_transactions';
export const FETCH_BALANCE = 'fetch_balance';
export const FETCH_TRANSACTION = 'fetch_transaction';
export const UPDATE_TRANSACTION_STATUS = 'update_transaction_status';
export const LOAD_WALLET = 'load_wallet';
export const WITHDRAW_WALLET = 'withdraw_wallet';
export const FETCH_TRANSACTION_EXPENSES_FOR_JOBS = 'FETCH_TRANSACTION_EXPENSES_FOR_JOBS';
export const FASTAG_VEHICLE_BALANCE = 'FASTAG_VEHICLE_BALANCE';
export const FETCH_FASTAG_SUMMARY = 'FETCH_FASTAG_SUMMARY';
export const VALIDATE_PROFILE_PASSWORD = 'VALIDATE_PROFILE_PASSWORD';
export const FETCH_BPCL_TRANSACTION = 'FETCH_BPCL_TRANSACTION';
export const FETCH_JOBS_NOT_MAPPED_TO_TRIP_SHEET = 'FETCH_JOBS_NOT_MAPPED_TO_TRIP_SHEET';
export const FETCH_TRANSACTIONS_BY_JOB_IDS = 'FETCH_TRANSACTIONS_BY_JOB_IDS';

export const FETCH_TRANSACTION_SUMMARY_JOBS = 'FETCH_TRANSACTION_SUMMARY_JOBS';
export const FETCH_TRANSACTION_REPORT = 'FETCH_TRANSACTION_REPORT';
export const FETCH_MISSED_TRANSACTION_REPORT = 'FETCH_MISSED_TRANSACTION_REPORT';
export const ADD_BULK_TRANSACTION = 'ADD_BULK_TRANSACTION';

export function addTransaction(accesstoken, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}finance/`, values, config);
    return {
        type: ADD_TRANSACTION,
        promise: request,
    };
}

export function updateTransaction(accesstoken, id, values) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}finance/${id}`, values, config);
    return {
        type: UPDATE_TRANSACTION,
        promise: request,
    };
}

export function deleteTransaction(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}finance/${id}`, config);
    return {
        type: DELETE_TRANSACTION,
        promise: request,
    };
}

export function fetchTransactionsByDriver(accesstoken, from, to, driver, vehicle, otherParams = {}) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId: driver,
            vehicleId: vehicle,
            to,
            from,
            ...otherParams,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}finance/driver`, config);
    return {
        type: FETCH_TRANSACTIONS_BY_DRIVER,
        promise: request,
    };
}

export function fetchTransaction(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}finance/transactions/${id}`, config);
    return {
        type: FETCH_TRANSACTION,
        promise: request,
    };
}

export function fetchBpclTransaction(accesstoken, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            // from: getMomentTime(from).valueOf(),
            // to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/bpcl/card-Balance`, config);
    return {
        type: FETCH_BPCL_TRANSACTION,
        promise: request,
    };
}

export function fetchTransactions(
    accesstoken,
    from,
    to,
    driverId,
    vehicleId,
    groupId,
    vendorId,
    expenseType,
    channel,
    status,
    reverseTrip,
    userId
) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId,
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            groupId,
            vehicleId,
            vendorId,
            expenseTypeIds: expenseType,
            channel,
            status,
            reverseTrip,
            userId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/transactions`, config);
    return {
        type: FETCH_TRANSACTIONS,
        promise: request,
    };
}

export function fetchTransactionsSearch(
    accesstoken,
    {
        from,
        to,
        driverId,
        vehicleId,
        groupId,
        vendorId,
        expenseType,
        channel,
        status,
        reverseTrip,
        userId,
        page,
        size,
        ids,
    }
) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId,
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            groupIds: groupId,
            vehicleId,
            vendorId,
            expenseTypeIds: expenseType,
            channel,
            status,
            reverseTrip,
            userId,
            page,
            size,
            ids,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/transactions/search`, config);
    return {
        type: FETCH_TRANSACTIONS_SEARCH,
        promise: request,
    };
}

export function fetchTransactionSummary(
    accesstoken,
    from,
    to,
    driverId,
    vehicleId,
    groupId,
    vendorId,
    expenseType,
    channel,
    status,
    reverseTrip,
    userId
) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId,
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            groupId,
            vehicleId,
            vendorId,
            expenseTypeIds: expenseType,
            channel,
            status,
            reverseTrip,
            userId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/transactions/summary`, config);
    return {
        type: FETCH_TRANSACTION_SUMMARY,
        promise: request,
    };
}

export const updateMultipleTransactions = (accesstoken, ids, comments, status) => {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ids,
        }),
    };

    const body = {
        status,
        comments,
    };

    const request = axios.put(`${ROOT_API_URL}finance/transactions/status/bulk`, body, config);

    return {
        type: UPDATE_MULTIPLE_TRANSACTIONS,
        promise: request,
    };
};

export const deleteBulkTransactions = (accesstoken, ids) => {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ids,
        }),
    };

    const request = axios.delete(`${ROOT_API_URL}finance/bulk`, config);

    return {
        type: DELETE_BULK_TRANSACTIONS,
        promise: request,
    };
};

export function fetchHappayViewBalance(accesstoken, vehicleId, groupId, vendorId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/happay/wallet`, config);
    return {
        type: FETCH_BALANCE,
        promise: request,
    };
}

export function fetchFasTagViewBalance(accesstoken, vehicleNumber) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/balance/fastag/vehicle`, config);
    return {
        type: FETCH_BALANCE,
        promise: request,
    };
}

export function loadFasTagWalletBalance(accesstoken, from, to, merchantName) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            merchantName,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/balance/fastag`, config);
    return {
        type: LOAD_WALLET,
        promise: request,
    };
}

export function loadBalance(accesstoken, vehicleId, loadAmount, remark, userId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    let remarkString = `${userId} %7C ${remark}`;

    const request = axios.put(
        `${ROOT_API_URL}finance/happay/load-wallet?vehicleId=${vehicleId}&loadAmount=${loadAmount}&remarks=${remarkString}`,
        null,
        config
    );
    return {
        type: LOAD_WALLET,
        promise: request,
    };
}

export function withdrawBalance(accesstoken, vehicleId, withdrawAmount, remark, userId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    let remarkString = `${userId} %7C ${remark}`;

    const request = axios.put(
        `${ROOT_API_URL}finance/happay/withdraw-wallet?vehicleId=${vehicleId}&withdrawAmount=${withdrawAmount}&remarks=${remarkString}`,
        null,
        config
    );
    return {
        type: WITHDRAW_WALLET,
        promise: request,
    };
}

export function updateTransactionStatus(accesstoken, id, status, comments = '') {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const data = {
        status,
        comments,
    };

    const request = axios.put(`${ROOT_API_URL}/finance/transactions/${id}/status`, data, config);
    return {
        type: UPDATE_TRANSACTION_STATUS,
        promise: request,
    };
}

export function fetchTransactionExpensesForJobs(accesstoken, jobIds = []) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}finance/expense-detail`, jobIds, config);
    return {
        type: FETCH_TRANSACTION_EXPENSES_FOR_JOBS,
        promise: request,
    };
}

export function loadFastagBalanceForVehicle(accesstoken, vehicleNumber) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleNumber,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/balance/fastag/vehicle`, config);
    return {
        type: FASTAG_VEHICLE_BALANCE,
        promise: request,
    };
}

export function loadFastagSummary(accesstoken, from, to, merchantName) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            merchantName,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}finance/balance/fastag`, config);
    return {
        type: FETCH_FASTAG_SUMMARY,
        promise: request,
    };
}

export function validateProfilePassword(accesstoken, profilePasswordInput) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            password: profilePasswordInput,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}accounts/verify-password`, config);
    return {
        type: VALIDATE_PROFILE_PASSWORD,
        promise: request,
    };
}

export function fetchJobsNotMappedToTripSheet(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params), // params has from and to
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/not-mapped-to-tripsheet`, config);

    return {
        type: FETCH_JOBS_NOT_MAPPED_TO_TRIP_SHEET,
        promise: request,
    };
}

export function fetchTransactionsByJobIds(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params), // params has from and to
    };

    const request = axios.get(`${ROOT_API_URL}finance/transactions/search`, config);

    return {
        type: FETCH_TRANSACTIONS_BY_JOB_IDS,
        promise: request,
    };
}

export function fetchJobTransactionSummary(accesstoken, jobIds = []) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}finance/job/summary`, jobIds, config);
    return {
        type: FETCH_TRANSACTION_SUMMARY_JOBS,
        promise: request,
    };
}

export function triggerTransactionReport(params) {
    const config = {
        responseType: 'blob',
    };

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/transactionReport`, params, config);

    return {
        type: FETCH_TRANSACTION_REPORT,
        promise: request,
    };
}

export function fetchMissedTransactionReport(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };

    const request = axios.get(`${ROOT_API_URL}reports/missed-transaction`, config);
    return {
        type: FETCH_MISSED_TRANSACTION_REPORT,
        promise: request,
    };
}

export function addBulkTransactions(values) {
    const config = { headers: {} };
    const request = axios.post(`${ROOT_API_URL}finance/bulk-transactions`, values, config);
    return {
        type: ADD_BULK_TRANSACTION,
        promise: request,
    };
}
