import { find, get } from 'lodash';
import { FLEETX_ACCOUNTS, getFlagValueFromAccountConfig } from '../../../../utils/account_utils';
export function calculateFreightFromJob() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.SOUMYA_TOURS_TRAVELS:
        case FLEETX_ACCOUNTS.RSI:
        case FLEETX_ACCOUNTS.SCC_SOLUTION:
        case FLEETX_ACCOUNTS.KUSH:
        case FLEETX_ACCOUNTS.CHOUDHARY_TOURS_AND_TRAVELS:
            return true;
        default:
            return false;
    }
}

export function isClientContractChartBasedBilling() {
    return getFlagValueFromAccountConfig('enableClientContractChartBilling');
}

export function getDaysRangeInFreightBill() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.SAI_WASTE_MANAGEMENT:
        case FLEETX_ACCOUNTS.KAIZEN_LOGISTICS:
        case FLEETX_ACCOUNTS.MAGADH_LOGISTICS:
        case FLEETX_ACCOUNTS.RAKESH_LOGISTICS:
        case FLEETX_ACCOUNTS.WELL_X_TRANSPORT:
        case FLEETX_ACCOUNTS.JAIN_TRANSPORT_COMPANY:
        case FLEETX_ACCOUNTS.RAJLAXMI_BULK_LOGISTICS:
        case FLEETX_ACCOUNTS.SHREE_VISHNU_MOTOR:
        case FLEETX_ACCOUNTS.SRI_GOPAL:
        case FLEETX_ACCOUNTS.MAGADH_LOGISTICS:
            return 100;
        case FLEETX_ACCOUNTS.INDIAN_ROAD_CARRIER:
            return 366;
        case FLEETX_ACCOUNTS.GUARD_LOGISTICS:
            return 62;
        default:
            return 31;
    }
}
export function hideYearsInFreightBillCNListing() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.INDIAN_ROAD_CARRIER:
            return false;
        default:
            return true;
    }
}

export function getEInvoiceSubjectTo() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.JAIN_TRANSPORT_COMPANY:
            return 'Subject to Rudrapur (Udham Singh Nagar) court of jurisdiction.';
        default:
            return 'Subject to Delhi court jurisdiction.';
    }
}
export function getAuthorisedSignatory(freightBill, allBranches) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.JAIN_TRANSPORT_COMPANY:
            const branch = find(allBranches, { id: get(freightBill, 'basicDetails.billingOfficeId') });
            const cf = find(get(branch, 'customFields', []), { fieldId: 2689 });
            return get(cf, 'value', '-');
        default:
            return get(freightBill, 'sellerDtls.lglNm', '-');
    }
}
export const getBankDetailsForAccount = () => {
    let details = {
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        branch: '',
    };

    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.ASHLER:
            details = {
                bankName: 'HDFC',
                accountNumber: '50200055705872',
                ifscCode: 'HDFC0001604',
                branch: 'Nad KothaRoad',
            };
            break;
        default:
            break;
    }
    return details;
};
