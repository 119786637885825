import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
import { DATE_FORMAT_TIMESTAMP_DAY, getMomentTime } from '../../../utils/date_utils';

export const CREATE_FUEL_ENTRY = 'create_fuel_entry';
export const FETCH_FUEL_ENTRY = 'fetch_fuel_entry';
export const FETCH_FUEL_ENTRIES = 'fetch_fuel_entries';
export const FETCH_FUEL_ENTRIES_IN_BETWEEN = 'fetch_fuel_entries_in_between';
export const SEARCH_FUEL_ENTRIES = 'search_fuel_entries';
export const FETCH_FUEL_ENTRIES_IN_BETWEEN_SUMMARY = 'fetch_fuel_entries_in_between_summary';
export const FETCH_APPROVAL_MATRIX = 'fetch_approval_matrix';

export const UPDATE_FUEL_ENTRY = 'update_fuel_entry';
export const DELETE_FUEL_ENTRY = 'delete_fuel_entry';
export const RECALCULATE_FUEL_ENTRY = 'recalculate_fuel_entry';
export const VALIDATE_RECALCULATE_FUEL_ENTRY = 'validate_recalculate_fuel_entry';
export const FETCH_LAST_FUEL_ENTRIES = 'fetch_last_fuel_entries';
export const FETCH_FUEL_ENTRIES_FUEL_REPORT = 'FETCH_FUEL_ENTRIES_FUEL_REPORT';
export const GET_DAY_WISE_AGGREGATION = 'GET_DAY_WISE_AGGREGATION';
export const GET_VEHICLE_WISE_AGGREGATION = 'GET_VEHICLE_WISE_AGGREGATION';
export const POST_APROOVAL_MATRIX = 'POST_APROOVAL_MATRIX';
export const POST_APROOVAL_MATRIX_DATA = 'POST_APROOVAL_MATRIX_DATA';

export function createFuelEntry(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}fuel_entries/`, values, config);
    return {
        type: CREATE_FUEL_ENTRY,
        promise: request,
    };
}

export function updateFuelEntry(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}fuel_entries/${id}`, values, config);

    return {
        type: UPDATE_FUEL_ENTRY,
        promise: request,
    };
}

export function fetchFuelEntries(accesstoken, vehicle) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    let query = vehicle ? `?vehicleId=${vehicle}` : '';

    const request = axios.get(`${ROOT_API_URL}fuel_entries/${query}`, config);
    return {
        type: FETCH_FUEL_ENTRIES,
        promise: request,
    };
}

export function fetchFuelEntriesInBetween(
    accesstoken,
    page = 1,
    size = 1000,
    vehicle,
    groupId,
    from,
    to,
    sortByDateAsc
) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    let query = vehicle ? `&vehicleId=${vehicle}` : '';
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    let toQuery = to ? `&to=${to}` : '';
    let sortQuery = sortByDateAsc ? `&sort=date,asc` : '&sort=date,desc';
    const request = axios.get(
        `${ROOT_API_URL}fuel_entries/between?from=${from}${toQuery}${query}${groupQuery}&page=${
            page - 1
        }&size=${size}${sortQuery}`,
        config
    );
    return {
        type: FETCH_FUEL_ENTRIES_IN_BETWEEN,
        promise: request,
    };
}

export function lastFuelEntry(accesstoken, { vehicleId }, page = 1, size = 1000) {
    // const sort = sortByDateAsc ? `date,asc` : 'date,desc';
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            page: page - 1,
            size,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/last-fuel-entry`, config);
    return {
        type: FETCH_LAST_FUEL_ENTRIES,
        promise: request,
    };
}

export function searchFuelEntries(
    accesstoken,
    { vehicleId, groupId, vendorId, from, to, sortByDateAsc, fuelTypeId, vehicleDetails, fuelIds },
    page = 1,
    size = 1000
) {
    const sort = sortByDateAsc ? `date,asc` : 'date,desc';
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            groupId,
            vendorId,
            fuelTypeId,
            from,
            to,
            sort,
            page: page - 1,
            size,
            vehicleDetails,
            fuelIds,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/search`, config);
    return {
        type: SEARCH_FUEL_ENTRIES,
        promise: request,
    };
}

export function fetchFuelEntriesInBetweenSummary(accesstoken, vehicleId, groupId, from, to, vendorId, fuelTypeId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            from,
            to,
            vehicleId,
            vendorId,
            fuelTypeId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/between/summary`, config);
    return {
        type: FETCH_FUEL_ENTRIES_IN_BETWEEN_SUMMARY,
        promise: request,
    };
}

export function fetchApprovalMatrix(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}approval/data`, config);
    return {
        type: FETCH_APPROVAL_MATRIX,
        promise: request,
    };
}

export function postApprovalMatrix(accesstoken, values, params) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}approval/status`, values, config);
    return {
        type: POST_APROOVAL_MATRIX,
        promise: request,
    };
}
export function postApprovalMatrixData(accesstoken, values, params = {}) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}approval/data`, values, config);
    return {
        type: POST_APROOVAL_MATRIX_DATA,
        promise: request,
    };
}

export function fetchFuelEntry(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/${id}`, config);

    return {
        type: FETCH_FUEL_ENTRY,
        promise: request,
    };
}

export function deleteFuelEntry(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}fuel_entries/${id}`, config);
    return {
        type: DELETE_FUEL_ENTRY,
        promise: request,
    };
}

export function recalculateFuelEntry(accesstoken, vehicleId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/recalculate/${vehicleId}`, config);

    return {
        type: RECALCULATE_FUEL_ENTRY,
        promise: request,
    };
}

export function validateAndRecalculateFuelEntry(accesstoken, vehicleId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/validateAndRecalculate/${vehicleId}`, config);

    return {
        type: VALIDATE_RECALCULATE_FUEL_ENTRY,
        promise: request,
    };
}

export function fetchFuelEntriesFuelReportData(accesstoken, from, to, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).format(DATE_FORMAT_TIMESTAMP_DAY),
            to: getMomentTime(to).format(DATE_FORMAT_TIMESTAMP_DAY),
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/fuel-report`, config);
    return {
        type: FETCH_FUEL_ENTRIES_FUEL_REPORT,
        promise: request,
    };
}

export function fetchManualFuelEntriesFuelReportData(accesstoken, from, to, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/day-wise`, config);
    return {
        type: FETCH_FUEL_ENTRIES_FUEL_REPORT,
        promise: request,
    };
}

export function getDayWiseAggregation(accesstoken, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };

    const request = axios.get(`${ROOT_API_URL}analytics/day-wise-aggregation`, config);

    return {
        type: GET_DAY_WISE_AGGREGATION,
        promise: request,
    };
}

export const getVehicleWiseAggregation = (accesstoken, from, to) => {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleWiseAggregation: true,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}analytics/day-wise-aggregation`, config);

    return {
        type: GET_VEHICLE_WISE_AGGREGATION,
        promise: request,
    };
};
